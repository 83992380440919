<template>
  <div>
    <div class="wrapper">
      <div class="resolutions">
        <el-button type="primary" @click="resolutionWidth = 320"
          ><i class="fa fa-mobile"
        /></el-button>
        <el-button type="primary" @click="resolutionWidth = 768"
          ><i class="fa fa-tablet"
        /></el-button>
        <el-button type="primary" @click="resolutionWidth = 1440"
          ><i class="fa fa-desktop"
        /></el-button>
      </div>
      <div class="thumbnail-container" ref="thumbnailContainer">
        <div class="thumbnail" :style="`transform: scale(${scale})`">
          <iframe
            :src="previewIframeSrc"
            class="iframe"
            style="height: 80vh"
            frameborder="0"
            :style="`width: ${resolutionWidth}px`"
            ref="iframe"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WidgetsInput from '@/components/widgets/WidgetsInput'

export default {
  mixins: [WidgetsInput],
  props: {
    previewContent: Object,
    pageType: {
      type: String,
      default: 'Cycle'
    }
  },
  data() {
    return {
      resolutionWidth: 1440,
      scale: 1
    }
  },
  computed: {
    previewIframeSrc() {
      const FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL
      const pageTypePreviewUrl = {
        Cycle: '/podglad/cykle/',
        SuicideMythPage: '/podglad/mity-na-temat-samobojstw/',
        HomePage: '/podglad/strona-glowna/',
        Article: '/podglad/artykul/',
        BroughtBackToLifePage: '/podglad/przywroceni-zyciu/',
        SliderPage: '/podglad/suwak/',
        FlatPage: '/podglad/dynamiczna/',
        FreeAidStationsPage: '/podglad/bezplatne-miejsca-pomocowe/',
        TollFreeHelplinesPage: '/podglad/bezplatne-numery-pomocowe/'
      }
      return `${FRONTEND_URL}${pageTypePreviewUrl[this.pageType]}`
    }
  },
  created() {
    window.addEventListener('message', this.messageFromIframeHandler)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('message', this.messageFromIframeHandler)
    })
    window.addEventListener('resize', this.updatePreviewZoom)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.updatePreviewZoom)
    })
  },
  methods: {
    updatePreviewZoom(e) {
      setTimeout(() => {
        const container = document.querySelector('.el-tabs')
        if (!container) {
          return
        }
        const containerWidth = container.clientWidth - 18
        this.scale = Math.min(containerWidth / this.resolutionWidth, 1)
      }, 0)
    },
    postContentToIframe() {
      const iframe = this.$refs.iframe
      if (!iframe || !this.previewContent) {
        return
      }
      const payload = { preview: true, page: this.previewContent }
      iframe.contentWindow.postMessage(payload, '*')
    },
    messageFromIframeHandler(event) {
      const iframe = this.$refs.iframe
      const messageFromPreviewIframe =
        iframe && event.source === iframe.contentWindow

      if (!messageFromPreviewIframe) {
        return
      }

      const data = event.data
      if (
        [
          'edit-base',
          'edit-widget',
          'remove-widget',
          'move-up-widget',
          'move-down-widget',
          'add-widget'
        ].includes(data.type)
      ) {
        this.$emit(data.type, data.widgetIndex)
      }

      if (data.type === 'ping') {
        this.postContentToIframe()
      }
    }
  },
  watch: {
    previewContent: {
      immediate: true,
      handler(value) {
        this.postContentToIframe()
      }
    },
    resolutionWidth: {
      immediate: true,
      handler() {
        this.updatePreviewZoom()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  text-align: center;
}

.thumbnail {
  margin: 0 auto;
  transform-origin: 0 0;
}

.resolutions {
  margin: 0 0 15px 0;
}
</style>

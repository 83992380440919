<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              required: true,
              modelPath: [this, 'form', 'title']
            }),
            new fields.WYSIWYG({
              label: 'Lead',
              modelPath: [this, 'form', 'lead'],
              required: false,
              type: 'emptyMenuBarInit'
            })
          ]
        }
      ]
    }
  }
}
</script>

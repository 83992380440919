import { forEach, isObject, isUndefined, isNull, isArray } from 'lodash'

function isFlatArray(value) {
  return value.every(item => !isObject(item))
}

const isFile = value => {
  if (!value) return false
  const isBlob = value =>
    typeof value.size === 'number' &&
    typeof value.type === 'string' &&
    typeof value.slice === 'function'
  return (
    isBlob(value) &&
    (typeof value.lastModifiedDate === 'object' ||
      typeof value.lastModified === 'number') &&
    typeof value.name === 'string'
  )
}

const getFormDataFromJSON = (payload, formData, prefix) => {
  console.log(payload)
  formData = formData || new FormData()
  prefix = prefix || ''
  forEach(payload, (value, key) => {
    if (isUndefined(value) || isNull(value)) {
      formData.append(`${prefix}${key}`, null)
    } else if (isArray(value)) {
      if (isFlatArray(value)) {
        value.forEach(id => formData.append(`${prefix}${key}`, id))
      } else {
        value.forEach((item, i) =>
          getFormDataFromJSON(item, formData, `${prefix}${key}[${i}]`)
        )
      }
    } else if (isObject(value) && !isFile(value)) {
      getFormDataFromJSON(value, formData, `${prefix}${key}.`)
    } else {
      formData.append(`${prefix}${key}`, value)
    }
  })

  console.log(formData)
  return formData
}

export default getFormDataFromJSON

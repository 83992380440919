<script>
import { ImportHelpPlace } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { get } from 'lodash'
import ElementsToDeleteForm from '@/views/SimpleCastAudio/ElementsToDeleteForm'
import SimpleCastFeedForm from '@/views/SimpleCastAudio/SimpleCastFeedForm'

export default {
  extends: FormMixin,
  data() {
    return {
      form: {},
      redirectRoute: { name: 'HelpPlace List' },
      importData: null,
      legendText:
        '"SimpleCastAudio JUŻ ISTNIEJE" - oznacza, że audio zostało już wcześniej zaimportowane i jeżeli wystąpiły zmiany to zostało zaktualizowane',
      legendText2:
        '"ZAIMPORTOWANO" - oznacza, że podcast został pomyślnie zaimportowany'
    }
  },
  computed: {
    service() {
      return ImportHelpPlace
    },
    hasErrors() {
      return (
        this.importData &&
        this.importData.logs &&
        this.importData.logs.errors &&
        this.importData.logs.errors.length
      )
    }
  },
  methods: {
    getFields() {
      return [
        new fields.FileField({
          condition: !this.importData && !this.loading,
          label: 'Plik',
          modelPath: [this, 'form', 'file']
        }),
        this.getButtonComponent({
          label: 'Wróć do listy placówek',
          onClick: () => {
            this.$router.push({ name: 'HelpPlace List' })
          },
          condition: this.importData
        }),
        new fields.Header({
          text: 'Szczegóły importu',
          type: 'h2',
          condition: !!this.importData
        }),
        new fields.ReadOnly({
          label: 'Utworzonych obiektów:',
          condition: !!this.importData,
          modelPath: [this, 'importData', 'logs', 'created_objects']
        }),
        new fields.ReadOnly({
          label: 'Błędnych wierszy:',
          condition: !!this.importData,
          modelPath: [this, 'importData', 'logs', 'ignored_records']
        }),
        new fields.Header({
          text:
            'Nie usunięto poprzednich placówek oraz nie dodano nowych. Czy plik ma poprawną strukturę?',
          condition:
            this.hasErrors && this.importData.logs.created_objects === 0,
          type: 'h4'
        }),
        new fields.Header({
          text: 'Pobierz błędy:',
          type: 'h3',
          condition: this.hasErrors
        }),
        this.getButtonComponent({
          label: 'Pobierz błędy',
          icon: 'el-icon-upload',
          condition: this.hasErrors,
          onClick: () => {
            let csvContent =
              'data:text/csv;charset=utf-8,' +
              this.importData.logs.errors.join('\n')
            let encodedUri = encodeURI(csvContent)
            let link = document.createElement('a')
            link.setAttribute('href', encodedUri)
            link.setAttribute(
              'download',
              'bledy' + this.importData.original_file_name + '.csv'
            )
            document.body.appendChild(link)

            link.click()
          }
        })
      ]
    },
    onSubmit() {
      this.loading = true
      this.errors = {}
      const data = new FormData()
      const file = this.form.file
      data.append('file', file, file.name)
      const service = this.service.save(data)
      service
        .then(response => {
          this.successRedirect = true
          this.successHandler(response)
        })
        .catch(this.errorHandler)
    },
    successHandler(response) {
      this.hideNavbarButtons = true
      this.loading = false
      this.importData = response.data

      this.$set(this, 'errors', {})

      const message = 'Import zakończony sukcesem'

      this.$message({
        message: message,
        type: 'success'
      })
    }
  }
}
</script>

<script>
import { Attachment } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      verboseName: 'Załącznik',
      verboseNamePlural: 'Załączniki',
      listFields: [
        { prop: 'file', label: 'url' },
        { label: 'data', formatter: this.formatDate('created') }
      ]
    }
  },
  methods: {
    actions(scope) {
      return [
        this.getButtonComponent({
          icon: 'el-icon-delete',
          onClick: () => this.handleDelete(scope.$index, scope.row),
          type: 'danger',
          condition: this.removeButtonCondition(scope.row)
        })
      ]
    }
  },
  computed: {
    service() {
      return Attachment
    }
  }
}
</script>

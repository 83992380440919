<script>
import { HomePage } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Home' },
      selectedTab: 'general',
      form: {
        links: []
      },
      slugFieldDisabled: true,
      pageType: 'HomePage'
    }
  },
  methods: {
    getWidgetsTab() {
      return {}
    },
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title'],
          required: true
        }),
        new fields.WYSIWYG({
          label: 'Subtytuł',
          modelPath: [this, 'form', 'subtitle'],
          required: true,
          type: 'emptyMenuBarInit'
        }),
        new fields.WYSIWYG({
          label: 'Lead',
          modelPath: [this, 'form', 'lead'],
          required: true,
          type: 'emptyMenuBarInit'
        }),
        new fields.WYSIWYG({
          label: 'Wyróżniony lead',
          modelPath: [this, 'form', 'highlighted_lead'],
          required: true,
          type: 'emptyMenuBarInit'
        }),
        new fields.HomePageVideo({
          label: 'Video',
          modelPath: [this, 'form', 'video'],
          required: true
        }),
        new fields.Formset({
          modelPath: [this, 'form', 'links'],
          emptyComponentLabel: 'Brak linków do wyświetlenia',
          maxItems: 3,
          addButtonLabel: 'Dodaj link',
          items: this.form.links.map((item, i) => {
            return {
              headerLabel: get(item, ['link', 'label']) || 'Nowy link',
              fields: [
                new fields.Link({
                  label: 'Link',
                  modelPath: [this, 'form', 'links', i, 'link']
                })
              ]
            }
          })
        })
      ]
    }
  },
  computed: {
    service() {
      return HomePage
    }
  }
}
</script>

<script>
import { HelpPlace } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'

export default {
  extends: FormMixin,
  data() {
    return {
      form: {
        ages: [],
        forms_of_help: [],
        place_type: null
      },
      availablePlaceTypes: [],
      availableFormsOfHelp: [],
      availableAges: [],
      availableProvinces: [],
      redirectRoute: { name: 'HelpPlace List' },
    }
  },
  computed: {
    service() {
      return HelpPlace
    }
  },
  beforeMount() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      this.fetchAvailablePlaceTypes()
      this.fetchAvailableFormOfHelps()
      this.fetchAvailableAges()
      this.fetchAvailableProvinces()
    },
    fetchAvailablePlaceTypes() {
      this.service
        .availablePlaceTypes()
        .then(response => {
          this.availablePlaceTypes = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchAvailableFormOfHelps() {
      this.service
        .availableFormsOfHelp()
        .then(response => {
          this.availableFormsOfHelp = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchAvailableAges() {
      this.service
        .availableAges()
        .then(response => {
          this.availableAges = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchAvailableProvinces() {
      this.service
        .availableProvinces()
        .then(response => {
          this.availableProvinces = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    getFields() {
      return [
        new fields.Input({
          modelPath: [this, 'form', 'name'],
          label: 'Nazwa'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'place_type'],
          label: 'Typ placówki'
        }),
        // new fields.Select({
        //   modelPath: [this, 'form', 'place_type'],
        //   options: this.availablePlaceTypes,
        //   label: 'Typ placówki'
        // }),
        new fields.Select({

          modelPath: [this, 'form', 'forms_of_help'],
          options: this.availableFormsOfHelp,
          multiple: true,
          label: 'Formy pomocy'
        }),
        new fields.Select({

          modelPath: [this, 'form', 'ages'],
          options: this.availableAges,
          multiple: true,
          label: 'Wiek'
        }),
        new fields.Switch({

          label: 'Otwarte całą dobę',
          modelPath: [this, 'form', 'round_the_clock']
        }),
        new fields.Input({

          modelPath: [this, 'form', 'link'],
          label: 'Link'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'phone'],
          label: 'Telefon'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'phone2'],
          label: 'Telefon 2'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'email'],
          label: 'Email'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'latitude'],
          label: 'Szerokość geograficzna (latitude)'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'longitude'],
          label: 'Długość geograficzna (longitude)'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'address_1'],
          label: 'Ulica'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'address_2'],
          label: 'Nazwa lokalu itd.'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'postcode'],
          label: 'Kod pocztowy'
        }),
        new fields.Input({

          modelPath: [this, 'form', 'city'],
          label: 'Miasto'
        }),
        new fields.Select({

          modelPath: [this, 'form', 'province'],
          options: this.availableProvinces,
          label: 'Województwo'
        })
      ]
    }
  }
}
</script>
<style>
.el-input.is-disabled input.el-input__inner,
.el-tag.el-tag--info .el-select__tags-text {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #2a2b2d;
  cursor: not-allowed;
}
</style>

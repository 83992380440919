import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ help, disabled, ...args }) {
    super(args)
    this.children = [this.getComponentWithVModel('el-switch')]
    this.children[0].attrs.disabled = disabled
    if (help) {
      this.children.push({
        component: 'el-alert',
        attrs: {
          showIcon: true,
          closable: false,
          title: help
        }
      })
    }
  }
}

<script>
export default {
  data() {
    return {
      filters: {
        available: [
          {
            name: 'Ukryte',
            query_param: 'is_hidden',
            options: [
              {
                label: 'Tak',
                value: true
              },
              {
                label: 'Nie',
                value: false
              }
            ],
            multiple: false
          }
        ],
        selected: {}
      }
    }
  }
}
</script>

<script>
import { SimpleCastAudio } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import FiltersMixin from '@/views/SimpleCastAudio/FiltersMixin'

export default {
  mixins: [ListMixin, FiltersMixin],
  data() {
    return {
      editRouteName: 'Edit SimpleCastAudio',
      verboseName: 'Podcast',
      verboseNamePlural: 'Podcasty',
      listFields: [
        { prop: 'id', label: 'id', width: 60 },
        {
          prop: 'enclosure_url',
          source_type: 'enclosure_type',
          label: 'odsłuchaj',
          type: 'audio',
          width: 300
        },
        { prop: 'guid', label: 'zewnętrzne id', width: 150 },
        { prop: 'title', label: 'tytuł' },
        { prop: 'author', label: 'autor' },
        { prop: 'feed_url', label: 'RSS' }
      ],
      reinitializeAfterQueryParamChange: true
    }
  },
  methods: {
    headerActions() {
      return [
        this.getButtonComponent({
          label: 'Importuj audio (SimpleCast)',
          icon: 'el-icon-upload',
          onClick: () => {
            this.$router.push({ name: 'Import SimpleCastAudio' })
          }
        })
      ]
    },
    actions(scope) {
      return [
        {
          component: 'router-link',
          attrs: {
            to: this.getEditRoute(scope.row)
          },
          children: [
            this.getButtonComponent({ icon: 'fa fa-edit', title: 'Szczegóły' })
          ]
        },
        this.getButtonComponent({
          icon: 'el-icon-delete',
          onClick: () => this.handleDelete(scope.$index, scope.row),
          type: 'danger',
          condition: this.removeButtonCondition(scope.row)
        }),
        ...this.additionalActions(scope)
      ]
    }
  },

  computed: {
    service() {
      return SimpleCastAudio
    }
  }
}
</script>

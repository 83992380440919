<script>
import { ArticleTemplate } from '@/api'
import fields from '@/components/fields/'
import PageFormBase from '@/crudBases/form/PageFormBase'
import Mixin from '@/views/Articles/Mixin'
import ContentArticleForm from '@/views/ArticleTemplate/ContentArticleTemplateForm'

export default {
  mixins: [PageFormBase, Mixin],
  data() {
    return {
      form: {
        widgets: [],
        content_article: {}
      },
      livePreview: false,
      singleton: true,
      redirectRoute: null,
      revisionEnabled: false
    }
  },
  created() {
    this.fetchTags()
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          required: false,
          modelPath: [this, 'form', 'title']
        }),
        {
          component: ContentArticleForm,
          listeners: {
            input: value => this.$set(this.form, 'content_article', value)
          },
          attrs: {
            value: this.form.content_article,
            errors: (this.errors && this.errors.content_article) || []
          }
        },
        new fields.Select({
          label: 'Tagi',
          modelPath: [this, 'form', 'tags'],
          multiple: true,
          filterable: true,
          allowCreate: true,
          options: this.tagOptions
        }),
        new fields.Date({
          label: 'Data publikacji',
          type: 'date',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          modelPath: [this, 'form', 'publication_date'],
          required: false
        }),
        new fields.Switch({
          label: 'Ukryj',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    },
    dataHasBeenFetchedCallback(data) {
      if (data.content_article === null) {
        data.content_article = {}
      }
      return data
    },
    getSEOTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'SEO'
        },
        children: [
          new fields.Input({
            label: 'Tytuł dla facebooka',
            modelPath: [this, 'form', 'og_title']
          }),
          new fields.Input({
            label: 'Opis dla facebooka',
            modelPath: [this, 'form', 'og_description'],
            type: 'textarea'
          }),
          new fields.Image({
            label: 'Zdjęcie dla facebooka',
            modelPath: [this, 'form', 'og_image']
          }),
          new fields.Input({
            label: 'Tytuł strony dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_title']
          }),
          new fields.Input({
            label: 'Opis dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_description'],
            type: 'textarea'
          })
        ]
      }
    },
    async fetchAndLoadPendingRevision() {},
    getPreviewTab() {
      return {}
    }
  },
  computed: {
    service() {
      return ArticleTemplate
    },
    customLabel() {
      return `Edytuj wzór artykułu`
    }
  }
}
</script>

<template>
  <div>
    <div class="drop" @dragover.prevent @drop="onDrop">
      <label v-if="!value" class="btn display-inline">
        PRZECIĄGNIJ I UPUŚĆ LUB
        <input type="file" name="value" @change="onChange" :accept="accept" />
      </label>
      <div class="hidden" v-else v-bind:class="{ value: true }">
        <a target="_blank" :href="fileUrl">{{ fileUrl }}</a>
        <el-button
          @click.stop="removeFile"
          class="remove-button"
          v-if="value"
          size="mini"
          icon="el-icon-delete"
          type="danger"
          title="Usuń plik"
        >
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Attachment } from '@/api'

export default {
  props: ['value', 'accept'],
  data() {
    return {
      selectedAttachment: null
    }
  },
  methods: {
    sendFile(files) {
      const data = new FormData()
      const file = files[0]
      data.append('file', file, file.name)
      return Attachment.upload(data)
        .then(response => {
          this.uploadSuccess(response)
        })
        .catch(error => {
          this.uploadError(error)
        })
    },
    uploadSuccess(response) {
      this.$emit('input', response.data.id)
    },
    uploadError(error) {
      console.error(error, 'error while file uploading')
      this.$message({
        message: 'Wystąpił problem podczas wysyłania pliku.',
        type: 'error'
      })
    },
    removeFile() {
      this.$emit('input', null)
    },
    onDrop: function(e) {
      e.stopPropagation()
      e.preventDefault()
      let files = e.dataTransfer.files
      this.sendFile(files)
    },
    onChange(e) {
      let files = e.target.files
      this.sendFile(files)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(id) {
        Attachment.get({ id }).then(response => {
          this.selectedAttachment = response.data
        })
      }
    }
  },
  computed: {
    fileUrl() {
      return this.selectedAttachment && this.selectedAttachment.file
    }
  }
}
</script>
<style lang="scss" scoped>
.remove-button {
  position: absolute;
  right: 0;
  top: 0;
}

.drop {
  border: 2px dashed #ccc;
  padding: 40px;
  background-color: #fafafa;
  height: 100%;
  max-width: 600px;
  position: relative;
  width: 100%;
  text-align: center;
}

.drop img {
  max-width: 100%;
}
</style>

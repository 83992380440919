<template>
  <div class="container">
    <widgets-dialogs ref="widgetDialogs" :widgets="widgets" :errors="errors" />
    <div class="widgets-main">
      <div class="widgets" v-if="widgetsVisible">
        <div v-if="nonFieldErrors" class="non-field-error">
          {{ nonFieldErrors }}
        </div>
        <p class="widgets__list--empty" v-show="widgets.length === 0">
          Brak widgetów do wyświetlenia - dodaj nowy.
        </p>
        <ul class="widgets__list">
          <li
            class="widget"
            v-for="(widget, i) in widgets"
            :key="`${widget.id}  ${i}`"
            :class="{ 'widget--error': errors[i] && !isEmpty(errors[i]) }"
            @click="editWidget(i)"
          >
            <div class="title">
              <strong>{{ getWidgetTypeLabel(widget) }}</strong>
            </div>
            <CompatibilityInfo :widget="widget.resourcetype" />
            <div class="buttons">
              <el-button
                @click.stop="changeOrder(widgets, i, 1)"
                v-if="i !== widgets.length - 1"
                size="small"
                title="Przesuń w dół"
              >
                <i class="fa fa-arrow-down"></i>
              </el-button>
              <el-button
                @click.stop="changeOrder(widgets, i, -1)"
                :style="{ opacity: i !== 0 ? 1 : 0 }"
                size="small"
                title="Przesuń do góry"
              >
                <i class="fa fa-arrow-up"></i>
              </el-button>
              <el-button
                @click.stop="removeWidget(i)"
                type="danger"
                size="small"
              >
                <i class="fa fa-remove"></i>
              </el-button>
            </div>
          </li>
        </ul>
        <el-button @click="openAddWidgetDialog" type="warning">
          <i class="fa fa-plus-circle" /> Dodaj nowy widget
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import WidgetsDialogs from '@/components/widgets/WidgetsDialogs'
import CompatibilityInfo from "@/components/CompatibilityInfo.vue";
import NewPreview from "@/components/NewPreview.vue";

export default {
  props: {
    widgets: Array,
    errors: [Array, String]
  },
  data() {
    return {
      selectedWidget: {},
      previews: [],
      widgetsVisible: true
    }
  },
  beforeMount() {
    this.fetchData()
  },
  onMounted() {
  },
  components: {
    NewPreview,
    CompatibilityInfo,
    WidgetsDialogs
  },
  computed: {
    nonFieldErrors() {
      const isString = typeof this.errors === 'string'
      return isString && this.errors
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('fetchAvailableWidgets')
      this.$store.dispatch('fetchWidgetTypes')
    },
    editWidget(widgetIndex) {
      this.$refs.widgetDialogs.editWidget(widgetIndex)
    },
    openAddWidgetDialog() {
      const lastIndex = this.widgets.length
      this.$refs.widgetDialogs.openAddWidgetDialog(lastIndex)
    },
    removeWidget(widgetIndex) {
      const widget = this.widgets[widgetIndex]
      this.removeItemFromFormset(this.widgets, widget)
      this.removeItemFromFormset(this.errors, this.errors[widgetIndex])
    },
    getWidgetTypeLabel(widget) {
      const resourcetype = widget.resourcetype
      const findByResourcetype = type => type.resourcetype === resourcetype
      const widgetType = this.$store.state.widgetTypes.find(findByResourcetype)
      const label = widgetType && widgetType.label
      return label || resourcetype
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.widgets-main {
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
}
.non-field-error {
  color: #67c23a;
}
.title {
}
.buttons {
  display: flex;
  margin-left: 50px;
}
.widgets {
  &__list {
    margin-bottom: 28px;
    list-style-type: none;
    padding: 0;

    &--empty {
      font-size: 13px;
    }
  }
}
.widget {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f3f3f3;
  }

  &--error {
    border-left: 3px solid #67c23a;
    color: #67c23a;
  }

  &__icon {
    max-width: 100%;
    max-height: 43px;
    display: inline-block;
    text-align: right;
  }
}
.title {
  width: 200px;
}
.buttons {
  width: 150px;
  display: flex;
  justify-content: flex-end;
}
</style>

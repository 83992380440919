<template>
  <div>
    <div class="formset-wrapper">
      <h2 v-if="header" v-text="header" />
      <slot></slot>
      <el-button
        v-if="!hideAddNewButton"
        class="add-item-button"
        type="warning"
        @click="addItem"
      >
        {{ getAddButtonLabel }}
      </el-button>
      <div v-if="nonFieldErrors" class="el-form-item is-error">
        {{ nonFieldErrors }}
      </div>
    </div>
  </div>
</template>
<script>
import { clone } from 'lodash'

export default {
  props: {
    blankObject: Object,
    items: Array,
    header: String,
    errors: {
      type: [Array, String],
      default: () => []
    },
    maxItems: null,
    hideAddNewButton: Boolean,
    addButtonLabel: String
  },
  computed: {
    nonFieldErrors() {
      if (this.errors.hasOwnProperty('non_field_errors')) {
        return this.errors.non_field_errors[0]
      }
      if (typeof this.errors[0] === 'string') {
        return this.errors
      }
      return false
    },
    getAddButtonLabel() {
      return this.addButtonLabel ? this.addButtonLabel : 'Dodaj nowy'
    }
  },
  methods: {
    addItem() {
      if (this.maxItems && this.items.length >= this.maxItems) {
        this.$message({
          message: `Maksymalna liczba elementów wynosi: ${this.maxItems}`,
          type: 'error'
        })
        return
      }
      const newObject = clone(this.blankObject)
      if (!Array.isArray(this.items)) {
        this.$emit('input', [newObject])
      } else {
        this.items.push(newObject)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-item-button {
  margin-top: 15px;
}

.is-error {
  color: #67c23a;
}
</style>

<style>
.formset-wrapper .formset-wrapper {
  margin-left: 100px;
}
</style>

<script>
import { Menu } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {
        items: [],
        bottom_items: []
      },
      singleton: true,
      redirectRoute: null
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Link({
          label: 'Element wyróżniony',
          modelPath: [this, 'form', 'highlighted_item']
        }),
        new fields.Header({
          text: 'Odnośniki menu głównego'
        }),
        new fields.Formset({
          blankObject: { links: [] },
          modelPath: [this, 'form', 'items'],
          items: this.form.items.map((item, i) => {
            return {
              headerLabel: get(item, ['label']),
              fields: [
                new fields.Input({
                  label: 'Nagłówek elementu / rozwijanej listy',
                  modelPath: [this, 'form', 'items', i, 'label'],
                  required: true
                }),
                new fields.Link({
                  label: 'Odnośnik',
                  modelPath: [this, 'form', 'items', i, 'link'],
                  hideLinkLabel: true
                }),
                new fields.Header({
                  type: 'h3',
                  text: 'Widoczne po rozwinięciu'
                }),
                this.getLinksFormset(this.form.items[i].links, i)
              ]
            }
          })
        }),
        new fields.Header({
          text: 'Odnośniki menu głównego pod elementem wyróżnionym'
        }),
        new fields.Formset({
          blankObject: { links: [] },
          modelPath: [this, 'form', 'bottom_items'],
          items: this.form.bottom_items.map((item, i) => {
            return {
              headerLabel: get(item, ['label']),
              fields: [
                new fields.Input({
                  label: 'Nagłówek elementu',
                  modelPath: [this, 'form', 'bottom_items', i, 'label'],
                  required: true
                }),
                new fields.Link({
                  label: 'Odnośnik',
                  modelPath: [this, 'form', 'bottom_items', i, 'link'],
                  hideLinkLabel: true
                })
              ]
            }
          })
        })
      ]
    },
    getLinksFormset(links, itemIndex) {
      return new fields.Formset({
        modelPath: [this, 'form', 'items', itemIndex, 'links'],
        items: links.map((link, linkIndex) => {
          return {
            headerLabel: get(link, ['label']),
            fields: [
              new fields.Link({
                label: 'Link',
                modelPath: [
                  this,
                  'form',
                  'items',
                  itemIndex,
                  'links',
                  linkIndex
                ]
              })
            ]
          }
        })
      })
    }
  },
  computed: {
    service() {
      return Menu
    }
  }
}
</script>

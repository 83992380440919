<template>
  <el-row :gutter="20">
    <el-col :span="6">
      <el-card class="card">
        <i class="card__icon fa fa-desktop"></i>
        <h1 class="card__title">Liczba podstron</h1>
        {{ contentCounters.pages }}
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="card">
        <i class="card__icon el-icon-picture-outline"></i>
        <h1 class="card__title">Liczba zdjęć</h1>
        {{ contentCounters.images }}
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="card">
        <i class="card__icon el-icon-user"></i>
        <h1 class="card__title">Liczba użytkowników</h1>
        {{ contentCounters.users }}
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="card">
        <i class="card__icon el-icon-files"></i>
        <h1 class="card__title">Liczba załączników</h1>
        {{ contentCounters.attachments }}
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    contentCounters: Object
  }
}
</script>

<style lang="scss" scoped>
.card {
  &__title {
    font-size: 20px;
  }

  &__icon {
    font-size: 64px;
    color: #333333;
    display: block;
  }
}
</style>

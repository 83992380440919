<script>
import { SuicideMythPage } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Home' },
      selectedTab: 'general',
      form: {
        widgets: [],
        myths: []
      },
      slugFieldDisabled: true,
      pageType: 'SuicideMythPage'
    }
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Formset({
          modelPath: [this, 'form', 'myths'],
          emptyComponentLabel: 'Brak mitów do wyświetlenia',
          items: this.form.myths.map((item, i) => {
            return {
              headerLabel: get(item, ['title']) || 'Nowy mit',
              fields: [
                new fields.Input({
                  label: 'Tytuł',
                  modelPath: [this, 'form', 'myths', i, 'title']
                }),
                new fields.Input({
                  label: 'Lead',
                  modelPath: [this, 'form', 'myths', i, 'lead']
                }),
                new fields.Input({
                  label: 'Tytuł po odwróceniu',
                  modelPath: [this, 'form', 'myths', i, 'second_title']
                }),
                new fields.Input({
                  label: 'Opis',
                  modelPath: [this, 'form', 'myths', i, 'description'],
                  type: 'textarea'
                }),
                new fields.Switch({
                  label: 'Ukryta',
                  modelPath: [this, 'form', 'myths', i, 'is_hidden']
                })
              ]
            }
          })
        }),
        new fields.Switch({
          label: 'Ukryj',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    }
  },
  computed: {
    service() {
      return SuicideMythPage
    }
  }
}
</script>

<script>
export default {
  data() {
    return {
      types: [],
      usedTags: [],
      filters: {
        available: [
          {
            name: 'Typ artykułu',
            query_param: 'type',
            options: this.setTypesOptions,
            multiple: true
          },
          {
            name: 'Tagi',
            query_param: 'tags',
            options: this.setUsedTagsOptions,
            multiple: true
          },
          {
            name: 'Ukryte',
            query_param: 'is_hidden',
            options: [
              {
                label: 'Tak',
                value: true
              },
              {
                label: 'Nie',
                value: false
              }
            ],
            multiple: false
          }
        ],
        selected: {}
      }
    }
  },
  methods: {
    setTypesOptions(options) {
      this.service
        .availableTypes()
        .then(response => {
          this.types = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    setUsedTagsOptions(options) {
      this.service
        .usedTags()
        .then(response => {
          this.tags = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

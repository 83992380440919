<template>
  <el-form :inline="true" @submit.prevent.native="$emit('submit')">
    <el-form-item class="list__search-wrapper">
      <el-input
        class="list__search"
        :value="value"
        @input="submitSearch"
        :placeholder="$t('query')"
      />
    </el-form-item>
    <el-form-item class="list__search-wrapper">
      <el-button @click="$emit('submit')">{{ $t('search') }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: ['value'],
  methods: {
    submitSearch(value) {
      this.$emit('input', value)
      this.$emit('submit')
    }
  }
}
</script>

<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['people', 'people_full_width']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              required: true
            }),
            new fields.Header({
              text: 'Osoby wyróżnione'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'people_full_width'],
              addButtonLabel: 'Dodaj nowy element',
              items: this.form.people_full_width.map((item, i) => ({
                headerLabel: get(item, ['full_name']),
                fields: [
                  new fields.Input({
                    label: 'Imię i nazwisko',
                    modelPath: [
                      this,
                      'form',
                      'people_full_width',
                      i,
                      'full_name'
                    ],
                    required: true
                  }),
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'people_full_width', i, 'title'],
                    required: false
                  }),
                  new fields.WYSIWYG({
                    label: 'Opis',
                    modelPath: [
                      this,
                      'form',
                      'people_full_width',
                      i,
                      'description'
                    ],
                    required: false,
                    type: 'emptyMenuBarInit'
                  })
                ]
              }))
            }),
            new fields.Header({
              text: 'Osoby'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'people'],
              addButtonLabel: 'Dodaj nowy element',
              items: this.form.people.map((item, i) => ({
                headerLabel: get(item, ['full_name']),
                fields: [
                  new fields.Input({
                    label: 'Imię i nazwisko',
                    modelPath: [this, 'form', 'people', i, 'full_name'],
                    required: true
                  }),
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'people', i, 'title'],
                    required: false
                  }),
                  new fields.WYSIWYG({
                    label: 'Opis',
                    modelPath: [this, 'form', 'people', i, 'description'],
                    required: false,
                    type: 'emptyMenuBarInit'
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

<template>
  <div>
    <component-compiler
      v-for="(component, i) in getFields()"
      :content="component"
      :key="`form-${i}`"
    />
    <br />
  </div>
</template>
<script>
import FieldsHelper from '@/mixins/form/FieldsHelper'
import fields from '@/components/fields/'
import ComponentCompiler from '@/components/ComponentCompiler'

export default {
  mixins: [FieldsHelper],
  components: {
    ComponentCompiler
  },
  methods: {
    getFields() {
      return [
        new fields.WYSIWYG({
          label: 'Opis kafla',
          modelPath: [this, 'form', 'description'],
          required: false,
          type: 'emptyMenuBarInit'
        }),
        new fields.Input({
          label: 'Autor',
          required: false,
          modelPath: [this, 'form', 'author']
        }),
        new fields.Image({
          label: 'Zdjęcie',
          modelPath: [this, 'form', 'image'],
          required: false
        }),
        new fields.Select({
          label: 'Nazwa linku',
          required: false,
          modelPath: [this, 'form', 'button_text'],
          multiple: false,
          filterable: true,
          allowCreate: true,
          options: [
            { label: 'Czytaj więcej', value: 'Czytaj więcej' },
            { label: 'Oglądaj', value: 'Oglądaj' },
            { label: 'Słuchaj', value: 'Słuchaj' },
            { label: 'Chcę się zapisać', value: 'Chcę się zapisać' }
          ]
        }),
        new fields.Link({
          label: 'Link kafla',
          modelPath: [this, 'form', 'button'],
          nullable: true,
          hideLinkLabel: true,
          required: false,
          help:
            'Zostaw puste (nie uzupełniaj) jeżeli link ma odnosić do tego artykułu'
        })
      ]
    }
  }
}
</script>

<script>
import { Page } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Pages List' },
      pageType: 'FlatPage'
    }
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title']
        }),
        new fields.Switch({
          label: 'Ukryj',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    },
  },
  computed: {
    service() {
      return Page
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <el-alert
      v-if="inModal"
      title="UWAGA"
      show-icon
      :closable="false"
      description="Po zatwierdzeniu zmiany zostaną zapisane niezależnie od formularza, z którego otwarto modal."
      type="warning"
    >
    </el-alert>
    <div class="nav">
      <div v-if="!customLabel">
        <h1 class="nav--title" v-if="!inModal">{{ getRouteName($route) }}</h1>
        <h1 class="nav--title" v-else>{{ modalLabel }}</h1>
      </div>
      <div v-else>
        <h1 class="nav--title">{{ customLabel }}</h1>
      </div>
      <div>
        <language-switcher />
      </div>
      <div v-if="!hideNavbarButtons">
        <slot>
          <router-link
            :to="{ ...redirectRoute, query: this?.$route.query }"
            v-if="!inModal && redirectRoute"
          >
            <el-button>{{ $t('cancel') }}</el-button>
          </router-link>
          <el-button
            @click="$emit('submit')"
            type="primary"
            :disabled="submitDisabled"
            :loading="loading"
            :style="submitHidden ? 'display:none;' : ''"
          >
            {{ submitButtonLabel }}
          </el-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher'

export default {
  props: [
    'redirectRoute',
    'modalLabel',
    'loading',
    'inModal',
    'submitLabel',
    'submitDisabled',
    'submitHidden',
    'customLabel',
    'hideNavbarButtons'
  ],
  components: { LanguageSwitcher },
  computed: {
    isRevision() {
      return false
    },
    submitButtonLabel() {
      return 'submit'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: 0px 4px 3px -2px rgba(0, 0, 0, 0.1);
}

.nav {
  padding: 20px;
  align-items: center;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  & .el-button,
  .el-dropdown {
    margin-left: 10px;
  }

  &--title {
    font-size: 24px;
    margin: 0;
    font-weight: 300;
  }
}
</style>

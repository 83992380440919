<script>
import { get, keyBy } from 'lodash'
import moment from 'moment'

export default {
  data() {
    return {
      feedUrlOptions: [],
      filters: {
        available: [
          {
            name: 'RSS',
            query_param: 'feed_url',
            options: this.setFeedUrlOptions
          }
        ],
        selected: {}
      }
    }
  },
  methods: {
    setFeedUrlOptions(options) {
      this.service
        .availableFeedUrls()
        .then(response => {
          const parsed = response.data.map(item => {
            return {
              value: item.feed_url,
              label: `(${item.count}) ${item.feed_url}`
            }
          })
          this.feedUrlOptions = parsed
          options.push(...parsed)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

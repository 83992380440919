<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['items']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              required: true
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'items'],
              addButtonLabel: 'Dodaj nowy element',
              items: this.form.items.map((item, i) => ({
                headerLabel: get(item, ['title']),
                fields: [
                  new fields.Link({
                    label: 'Button',
                    labelPlaceholder: 'Tekst buttona',
                    required: true,
                    modelPath: [this, 'form', 'items', i, 'button']
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

export function downloadBlobResponse(response) {
  const successStatusCodes = [201, 200]
  if (successStatusCodes.includes(response.status)) {
    const disposition = response.headers['content-disposition']
    const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/
    const filename = disposition.match(filenameRegex)[1]
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }
}

import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import { Auth } from './index'

axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = window.localStorage.getItem('token')
    if (window.localStorage.getItem('lang')) {
      config.headers['Accept-Language'] = window.localStorage.getItem('lang')
    }
    config.paramsSerializer = params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    let errorResponse = error.response
    if (errorResponse && errorResponse.status === 401) {
      // split token by space and get last item
      let tokenSplitted = window.localStorage.token.split(' ')
      tokenSplitted = tokenSplitted[tokenSplitted.length - 1]
      return Auth.tokenRefresh({
        token: tokenSplitted
      })
        .then(response => {
          window.localStorage.setItem('token', 'Token ' + response.data.token)
          return axios(errorResponse.config)
        })
        .catch(error => {
          window.localStorage.removeItem('token')
          router.push({ name: 'Login' })
          return Promise.reject(error)
        })
    }
    return Promise.reject(error)
  }
)

export function prepareURL(url, urlParams) {
  urlParams = urlParams || {}
  if (typeof urlParams !== 'object') {
    urlParams = {
      id: urlParams
    }
  }

  const convertURLFragment = fragment =>
    fragment.startsWith(':') ? urlParams[fragment.slice(1)] : fragment
  return url.split('/').map(convertURLFragment).join('/').replace('//', '/')
}

export default function (url) {
  return {
    fetch: (urlParams, payload) => {
      const paramsUrl = qs.stringify(payload.params, { arrayFormat: 'repeat' })
      return axios.get(prepareURL(url, urlParams) + '?' + paramsUrl)
    },
    update: (urlParams, payload, headers = {}) => {
      return axios.put(prepareURL(url, urlParams), payload, { headers })
    },
    patch: (urlParams, payload, headers = {}) => {
      return axios.patch(prepareURL(url, urlParams), payload, { headers })
    },
    get: (urlParams, payload) => {
      return axios.get(prepareURL(url, urlParams), payload)
    },
    save: (payload, headers = {}) => {
      return axios.post(prepareURL(url), payload, headers)
    },
    delete: urlParams => {
      return axios.delete(prepareURL(url, urlParams))
    }
  }
}

<script>
import { User } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import UserFiltersMixin from './UserFiltersMixin'

export default {
  mixins: [ListMixin, UserFiltersMixin],
  data() {
    return {
      editRouteName: 'Edit User',
      addRoute: { name: 'Add User' },
      verboseName: 'Użytkownik',
      verboseNamePlural: 'Użytkownicy',
      listFields: [
        { prop: 'id', label: 'id', width: 80, sortable: true },
        { prop: 'first_name', label: 'imię' },
        { prop: 'email', label: 'email', sortable: true },
        { prop: 'birth_date', label: 'wiek'},
        {
          prop: 'last_login',
          label: 'data ostatniego logowania',
          formatter: this.formatDate('last_login')
        },
        { prop: 'account_type', label: 'Chce pomóc/Szuka pomocy', sortable: true },
        { prop: 'is_active', label: 'aktywny', type: 'boolean', sortable: true }
      ]
    }
  },
  computed: {
    service() {
      return User
    }
  }
}
</script>

<script>
import { TollFreeHelplinesPage } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Home' },
      selectedTab: 'general',
      form: {
        items: []
      },
      slugFieldDisabled: true,
      pageType: 'FreeAidStationsPage'
    }
  },
  methods: {
    getWidgetsTab() {
      return {}
    },
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title'],
          required: true
        }),
        new fields.WYSIWYG({
          label: 'Lead',
          modelPath: [this, 'form', 'lead'],
          required: false,
          type: 'emptyMenuBarInit'
        }),
        new fields.Header({
          text: 'Bezpłatne numery pomocowe:'
        }),
        new fields.Formset({
          modelPath: [this, 'form', 'items'],
          emptyComponentLabel: 'Brak elemetów do wyświetlenia',
          blankObject: {
            opening_hours: 'Czynny od pn.-pt. 10:00-15:00',
            phone: []
          },
          items: this.form.items.map((item, i) => {
            return {
              headerLabel: get(item, ['title']) || 'Nowy element',
              fields: [
                new fields.Input({
                  label: 'Tytuł',
                  modelPath: [this, 'form', 'items', i, 'title'],
                  required: true
                }),
                new fields.WYSIWYG({
                  label: 'Godziny otwarcia',
                  modelPath: [this, 'form', 'items', i, 'opening_hours'],
                  required: false,
                  type: 'emptyMenuBarInit'
                }),
                new fields.Header({
                  text: 'Numery telefonów:',
                  type: 'h4'
                }),
                new fields.Formset({
                  modelPath: [this, 'form', 'items', i, 'phone'],
                  emptyComponentLabel: 'Brak telefonów',
                  blankObject: '',
                  items: this.form.items[i].phone.map((phone, j) => {
                    return {
                      headerLabel: phone || 'Nowy numer telefonu',
                      fields: [
                        new fields.Input({
                          label: 'Numer telefonu',
                          modelPath: [this, 'form', 'items', i, 'phone', j],
                          required: true
                        })
                      ]
                    }
                  })
                }),
                new fields.Link({
                  labelPlaceholder: 'Link',
                  label: 'Strona WWW',
                  required: false,
                  modelPath: [this, 'form', 'items', i, 'link']
                }),
                new fields.Input({
                  label: 'Label',
                  modelPath: [this, 'form', 'items', i, 'label'],
                  help: 'np. Młodzież i dorośli',
                  required: false
                }),
                new fields.Switch({
                  label: 'Całodobowy',
                  modelPath: [this, 'form', 'items', i, 'round_the_clock']
                })
              ]
            }
          })
        })
      ]
    }
  },
  computed: {
    service() {
      return TollFreeHelplinesPage
    }
  }
}
</script>

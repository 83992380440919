<template>
  <div>
    <p class="list-filter-title">{{ param.name }}</p>
    <el-select
      v-model="selectedValue"
      :multiple="param.multiple"
      :placeholder="param.name"
      filterable
      clearable
    >
      <el-option
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: ['param', 'value'],
  data() {
    return {
      options: []
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.value
      },
      set(newValue) {
        const parsedValue = newValue === '' ? null : newValue
        this.$emit('input', parsedValue)
      }
    }
  },
  mounted() {
    if (typeof this.param.options === 'function') {
      // if this.param.options is a function it must accept (empty) array of input options and
      // fill it with data
      this.param.options(this.options)
    } else {
      this.options = this.param.options
    }
  }
}
</script>

<template>
  <el-table-column
      :key="field.prop"
      :sortable="field.sortable ? 'custom' : false"
      :prop="field.prop"
      :label="field.label"
      :width="field.width"
      :formatter="field.formatter"
  >
    <template slot-scope="scope">
      <el-input v-model="scope.row[field.prop]" />
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: ['field']
}
</script>

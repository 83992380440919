<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['main_partners', 'partners']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              required: false
            }),
            new fields.WYSIWYG({
              label: 'Opis',
              modelPath: [this, 'form', 'description'],
              required: false,
              type: 'emptyMenuBarInit'
            }),
            new fields.Header({
              text: 'Główni partnerzy'
            }),
            new fields.Input({
              label: 'Tytuł główni partnerzy',
              modelPath: [this, 'form', 'main_partners_title'],
              required: false
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'main_partners'],
              addButtonLabel: 'Dodaj głównego partnera',
              required: false,
              items: this.form.main_partners.map((item, i) => ({
                headerLabel: get(item, ['title']),
                fields: [
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'main_partners', i, 'title'],
                    required: false
                  }),
                  new fields.WYSIWYG({
                    label: 'Opis',
                    modelPath: [
                      this,
                      'form',
                      'main_partners',
                      i,
                      'description'
                    ],
                    required: false,
                    type: 'emptyMenuBarInit'
                  }),
                  new fields.Link({
                    label: 'Link',
                    hideLinkLabel: true,
                    required: false,
                    modelPath: [this, 'form', 'main_partners', i, 'link']
                  }),
                  new fields.Image({
                    label: 'Logo',
                    required: false,
                    extension: ['.svg', '.png'],
                    modelPath: [this, 'form', 'main_partners', i, 'image']
                  })
                ]
              }))
            }),
            new fields.Header({
              text: 'Pozostali partnerzy'
            }),
            new fields.Input({
              label: 'Tytuł pozostali partnerzy',
              modelPath: [this, 'form', 'partners_title'],
              required: false
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'partners'],
              addButtonLabel: 'Dodaj pozostałego partnera',
              items: this.form.partners.map((item, i) => ({
                headerLabel: get(item, ['title']),
                fields: [
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'partners', i, 'title'],
                    required: false
                  }),
                  new fields.WYSIWYG({
                    label: 'Opis',
                    modelPath: [this, 'form', 'partners', i, 'description'],
                    required: false,
                    type: 'emptyMenuBarInit'
                  }),
                  new fields.Link({
                    label: 'Link',
                    hideLinkLabel: true,
                    required: false,
                    modelPath: [this, 'form', 'partners', i, 'link']
                  }),
                  new fields.Image({
                    label: 'Logo',
                    required: false,
                    extension: ['.svg', '.png'],
                    modelPath: [this, 'form', 'partners', i, 'image']
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

<template>
  <div v-loading="loading">
    <el-row :gutter="24">
      <el-col :sm="24" :md="18" class="box">
        <h2>id: {{ form.id }}</h2>
        <div class="task_name">
          <h3>nazwa zadania: {{ getFirstStepLabel(form) }}</h3>
        </div>
        <div class="current_step">
          <h3>status zadania: {{ getStatusLabel(form) }}</h3>
        </div>
        <div class="current_step">
          <h3 v-if="form.current_step">
            aktualne podzadanie: {{ form.current_step_label }}
          </h3>
        </div>

        <div class="input_data">
          <h4>Dane początkowe:</h4>
          <create-ticket-task
            :form="form"
            v-if="form.first_step === 'CREATE_REQUEST'"
          />
          <delete-user-task
            :form="form"
            v-if="form.first_step === 'FETCH_EXTERNAL_USER_ID'"
          />
        </div>

        <div class="logs">
          <h4>Logi (od najnowszych do najstarszych)</h4>
          <p v-if="!form.logs || !form.logs.length">brak logów</p>
          <div v-else>
            <el-collapse v-model="activeLog">
              <el-collapse-item
                style="border: 2px groove"
                :title="log | truncate(30)"
                :name="index"
                v-for="(log, index) of form.logs"
                :key="index"
              >
                <hr />
                <div style="padding-left: 10px">
                  {{ log }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </el-col>
      <el-col :sm="24" :md="6">
        <div class="box">
          <router-link
            :to="{ ...redirectRoute, query: this.$route.query }"
            v-if="!inModal && redirectRoute"
          >
            <el-button class="fluid form__button">
              <i class="fa fa-arrow-left" />
              Wróć do listy
            </el-button>
          </router-link>
          <el-button class="fluid" type="primary" @click="retryTask">
            Uruchom ponownie
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ZendeskTask } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import FiltersMixin from '@/views/ZendeskTask/FiltersMixin'
import Vue from 'vue'
import DeleteUserTask from '@/views/ZendeskTask/tasks/DeleteUserTask'
import CreateTicketTask from '@/views/ZendeskTask/tasks/CreateTicketTask'
import { cloneDeep } from 'lodash'

export default {
  extends: FormMixin,
  mixins: [FiltersMixin],
  components: {
    DeleteUserTask,
    CreateTicketTask
  },
  data() {
    return {
      activeLog: [],
      form: {},
      redirectRoute: { name: 'ZendeskTask List' }
    }
  },
  computed: {
    service() {
      return ZendeskTask
    }
  },
  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    }
  },
  beforeMount() {
    this.fetchData()
    this.prepareFormCopyToPreventChangesLost()
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      this.setStatusOptions([])
      this.setInitialTaskOptions([])
    },
    retryTask() {
      this.service
        .retryTask(this.lookupParams)
        .then(response => {
          this.$message({
            message: 'Zadanie zostało uruchomione ponownie.',
            type: 'success'
          })
          this.$router.push({ ...this.redirectRoute })
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.showErrorsMessage(error.response)
            this.errors = this.parseErrorsDict(error.response.data)
            this.showErrorsOnTabsHeaders()
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          }
        })
    }
  }
}
</script>

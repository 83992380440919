<template>
  <el-table-column
    :width="field.width"
    :sortable="field.sortable ? 'custom' : false"
    :label="field.label"
    :prop="field.prop"
    :key="field.prop"
  >
    <template slot-scope="scope" class="pull-right">
      <component-compiler
        v-for="(component, i) in fields(scope.row)"
        :content="component"
        :key="`articles-column-${i}`"
      />
    </template>
  </el-table-column>
</template>

<script>
import { get } from 'lodash'
import ComponentCompiler from '@/components/ComponentCompiler'

export default {
  props: ['field'],
  components: {
    ComponentCompiler
  },
  computed: {
    articles() {
      return scope => get(scope.row, this.field.prop)
    },
    lookupKwarg() {
      let lookup = this.field.lookupKwarg
      if (!lookup) {
        lookup = 'id'
      }
      return lookup
    }
  },
  methods: {
    getDetailsRouteParams(instance) {
      const params = {}
      params[this.lookupKwarg] = instance[this.lookupKwarg]
      return params
    },
    fields(row) {
      return row.articles.map(article => {
        return {
          component: 'router-link',
          attrs: {
            to: {
              name: 'Edit Article',
              params: this.getDetailsRouteParams(article)
              //  query: this.$route.query
            }
          },
          children: [
            this.getButtonComponent({
              title: article.title,
              label: article.slug + ' ',
              icon: 'fa fa-edit'
            })
          ]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 120px;
}
</style>

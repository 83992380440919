import FieldBase from './FieldBase'
import WidgetsInput from '@/components/widgets/WidgetsInput'

export default class extends FieldBase {
  constructor({ ...args }) {
    super(args)
    const { form, fieldName, fieldValue } = this.formData
    const errors = this.getErrorsForPath(this.modelPath)
    this.component = WidgetsInput
    this.listeners = {
      update: value => this.$set(form, fieldName, value)
    }
    this.props = {
      widgets: fieldValue,
      errors: errors || []
    }
  }
}

<script>
import { FooterMenu } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {
        items: []
      },
      singleton: true,
      redirectRoute: null
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Numer alarmowy',
          modelPath: [this, 'form', 'emergency_number'],
          required: true
        }),
        new fields.WYSIWYG({
          label: 'Opis numeru alarmowego',
          modelPath: [this, 'form', 'emergency_number_label'],
          required: true,
          type: 'emptyMenuBarInit'
        }),
        new fields.Link({
          label: 'Button',
          modelPath: [this, 'form', 'button'],
          nullable: false,
          required: true,
          labelPlaceholder: 'Tekst buttona'
        }),
        new fields.Formset({
          modelPath: [this, 'form', 'items'],
          items: this.form.items.map((item, i) => {
            return {
              headerLabel: get(item, ['label']),
              fields: [
                new fields.Link({
                  label: 'Link',
                  modelPath: [this, 'form', 'items', i]
                })
              ]
            }
          })
        })
      ]
    }
  },
  computed: {
    service() {
      return FooterMenu
    }
  }
}
</script>

<script>
import { HomePageVideo } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      verboseName: 'Film',
      verboseNamePlural: 'Filmy',
      listFields: [
        { prop: 'file', label: 'url' },
        { label: 'data', formatter: this.formatDate('created') }
      ]
    }
  },
  methods: {
    actions(scope) {
      return []
    }
  },
  computed: {
    service() {
      return HomePageVideo
    }
  }
}
</script>

<script>
import { Article } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import Mixin from '@/views/Articles/Mixin'
import FiltersMixin from '@/views/Articles/FiltersMixin'

export default {
  mixins: [ListMixin, FiltersMixin, Mixin],
  data() {
    return {
      editRouteName: 'Edit Article',
      addRoute: { name: 'Add Article' },
      verboseName: 'Artykuł',
      verboseNamePlural: 'Artykuły',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'title', label: 'tytuł' },
        { prop: 'slug', label: 'slug' },
        { prop: 'type', label: 'typ artykułu' },
        { prop: 'is_hidden', label: 'ukryty', type: 'boolean' },
        {
          prop: 'id',
          label: 'kolejność',
          type: 'order',
          service: Article,
          fetchDataCallback: this.fetchData,
          isListLoading: this.isListLoading,
          setListAsLoading: this.setListAsLoading
        }
      ]
    }
  },
  methods: {},
  computed: {
    service() {
      return Article
    }
  }
}
</script>

<template>
  <el-form
    ref="form"
    @submit.prevent.native="$emit('submit')"
    :disabled="disabled"
    :label-width="labelWidth"
  >
    <slot name="navbar"></slot>
    <el-row :gutter="30">
      <el-col :span="24">
        <div class="box">
          <slot name="box"></slot>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    labelWidth: {
      type: String,
      default: '100px'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  border: 0;
}
</style>

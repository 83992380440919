<template>
  <blockquote>
    <p>
      wiadomość:
      {{ form.tasks_data['CREATE_REQUEST']['input']['contact_message_id'] }}
    </p>
  </blockquote>
</template>
<script>
export default {
  props: ['form']
}
</script>

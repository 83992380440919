import { Auth } from '@/api/'

const state = {}
const mutations = {}
const actions = {
  async register({ commit }, user) {
    const data = await Auth.register(user)
    return data
  },
  async login({ commit }, user) {
    return await Auth.login(user)
  },
  async twoFactor(ctx, token) {
    const response = await Auth.twoFactor(token)
    if (response.status === 200) {
      window.localStorage.setItem('token', 'JWT ' + response.data.token)
    }

    return response
  },
  async logout({ commit }) {
    await Auth.logout()
    window.localStorage.removeItem('token')
  }
}

export default {
  namespaced: true,
  mutations,
  state,
  actions
}

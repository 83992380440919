<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['images']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Header({
              text: 'Zdjęcia'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'images'],
              items: this.form.images.map((item, i) => ({
                headerLabel: `Zdjęcie nr ${i + 1}`,
                fields: [
                  new fields.Image({
                    label: 'Zdjęcie',
                    required: true,
                    modelPath: [this, 'form', 'images', i, 'image']
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

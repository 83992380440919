<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  methods: {},
  data() {
    return {
      arrayFields: ['bulleted_list']
    }
  },
  computed: {
    isVideo() {
      return this.form.type === 'video'
    },
    isAudio() {
      return this.form.type === 'audio'
    },
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Select({
              label: 'Typ media',
              modelPath: [this, 'form', 'type'],
              required: true,
              options: [
                {
                  label: 'Zdjęcie',
                  value: 'image',
                  selected: true
                },
                {
                  label: 'Audio',
                  value: 'audio'
                },
                {
                  label: 'Video',
                  value: 'video'
                }
              ]
            }),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              required: true
            }),
            new fields.Image({
              label: 'Zdjęcie',
              modelPath: [this, 'form', 'image'],
              required: get(this.form, 'type') === 'image'
            }),
            new fields.AudioLink({
              hideFormItem: !this.isAudio,
              label: 'Audio',
              modelPath: [this, 'form', 'audio'],
              nullable: false,
              required: true
            }),
            new fields.VideoLink({
              hideFormItem: !this.isVideo,
              label: 'Wideo',
              modelPath: [this, 'form', 'video'],
              nullable: false,
              required: true
            }),
            new fields.Switch({
              label: 'Zamień miejscami',
              modelPath: [this, 'form', 'swap_places'],
              help: 'Jeżeli zaznaczone to media po prawej stronie'
            }),
            new fields.Link({
              label: 'Link',
              modelPath: [this, 'form', 'button_link'],
              nullable: true,
              required: false,
              labelPlaceholder: 'Tekst linku'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'bulleted_list'],
              items: this.form.bulleted_list.map((item, i) => ({
                headerLabel: item.title || `Element nr ${i + 1}`,
                fields: [
                  new fields.WYSIWYG({
                    label: 'Opis',
                    modelPath: [
                      this,
                      'form',
                      'bulleted_list',
                      i,
                      'description'
                    ],
                    required: true,
                    type: 'emptyMenuBarInit'
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  },
  watch: {
    'form.type': {
      handler(value) {
        if (value === 'audio') {
          this.$nextTick(() => {
            this.$set(this.form, 'video', null)
            if (!this.form.audio) {
              this.$set(this.form, 'audio', {})
            }
          })
        } else if (value === 'video') {
          this.$nextTick(() => {
            this.$set(this.form, 'audio', null)
            if (!this.form.video) {
              this.$set(this.form, 'video', {})
            }
          })
        } else {
          this.$nextTick(() => {
            this.$set(this.form, 'audio', null)
            this.$set(this.form, 'video', null)
          })
        }
      }
    }
  }
}
</script>

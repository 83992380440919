<script>
import { FreeAidStationsPage } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Home' },
      selectedTab: 'general',
      form: {},
      slugFieldDisabled: true,
      pageType: 'FreeAidStationsPage'
    }
  },
  methods: {
    getWidgetsTab() {
      return {}
    },
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title'],
          required: true
        }),
        new fields.WYSIWYG({
          label: 'Lead',
          modelPath: [this, 'form', 'lead'],
          required: false,
          type: 'emptyMenuBarInit'
        })
      ]
    }
  },
  computed: {
    service() {
      return FreeAidStationsPage
    }
  }
}
</script>

<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { ArticleTag, Article } from '@/api'
import Order from '@/views/Articles/Widget/Order'
import { debounce } from 'lodash'
export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['tags', 'articles'],
      availableArticles: [],
      articlesLoading: false
    }
  },
  methods: {
    fetchAvailableArticles: debounce(async function (params) {
      if (!this.form.tags.length) {
        console.log('no tags...')
        return
      }

      this.articlesLoading = true
      if (!params) {
        params = {
          tags: this.form.tags,
          page_size: 'max'
        }
      }
      await Article.fetch({}, { params })
        .then(response => {
          this.availableArticles = response.data.results
          // --s
          const articles = this.form.articles
          const newIds = []
          for (let availableArticle of this.availableArticles) {
            if (!articles.includes(availableArticle.id)) {
              newIds.push(availableArticle.id)
            }
          }

          const removeIds = []
          for (let selectedId of articles) {
            if (!this.availableArticles.find(item => item.id === selectedId)) {
              removeIds.push(selectedId)
            }
          }

          this.$nextTick(() => {
            this.form.articles = [...articles, ...newIds].filter(
              id => !removeIds.includes(id)
            )
          })
          // --   e
        })
        .finally(() => {
          this.articlesLoading = false
        })
    }, 300)
  },
  watch: {
    'form.tags': {
      immediate: true,
      async handler(tags) {
        const params = {
          tags: tags,
          page_size: 'max'
        }
        if (tags && tags.length > 0) {
          this.fetchAvailableArticles(params)
        } else {
          this.$nextTick(() => {
            this.form.articles = []
            this.availableArticles = []
          })
          return
        }
      }
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.ForeignKey({
              service: ArticleTag,
              label: 'Tagi artykułów',
              modelPath: [this, 'form', 'tags'],
              multiple: true,
              labelField: 'name',
              required: true,
              sortable: true,
              allowRefresh: true,
              disabled: this.articlesLoading,
              allowRefreshCallback: this.fetchAvailableArticles
            }),
            // new fields.ForeignKey({
            //   service: {
            //     fetch: () => null
            //   },
            //   label: 'Artykuły',
            //   modelPath: [this, 'form', 'articles'],
            //   multiple: true,
            //   labelField: 'slug',
            //   required: true,
            //   sortable: true,
            //   clearable: false,
            //   disabled: true,
            //   placeholder: 'Najpierw wybierz tagi',
            //   staticOptions: this.availableArticles
            // }),
            {
              component: Order,
              listeners: {
                input: value => this.$set(this.form, 'articles', value)
              },
              attrs: {
                value: this.form.articles,
                tags: this.form.tags,
                availableArticles: this.availableArticles,
                errors: (this.errors && this.errors.articles) || []
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

<template>
  <div>
    <input class="input" type="file" @change="submitFile" />
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, String, File],
    image: { type: Boolean, default: false }
  },
  methods: {
    submitFile(event) {
      const file = event.target.files[0]
      this.$emit('input', file)
      if (this.image) this.emitPreview(file)
    },
    emitPreview(file) {
      const fileType = typeof file
      if (fileType === 'object') {
        const reader = new FileReader()
        reader.onload = e => {
          this.$emit('update:preview', e.target.result)
        }
        reader.readAsDataURL(file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  margin-top: 15px;
  display: block;
}
</style>

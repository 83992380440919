<script>
import fields from '@/components/fields/'
import BaseWidgetForm from "./BaseWidgetForm";

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['inputs', 'outputs']
    }
  },
  methods: {
    showChoices(i) {
      return this.form.inputs[i].type === 'radio' || this.form.inputs[i].type === 'select'
    },
    showPlaceholder(i) {
      return ['text', 'number', 'email', 'phone', 'textarea'].includes(this.form.inputs[i].type)
    },
    getChoices(i) {
      if (!this.form.inputs[i].choices) {
        this.$set(this.form.inputs[i], 'choices', [])
      }
      if (!this.showChoices(i)) {
          return []
      }
      return this.form.inputs[i].choices
    },
    showCheckboxValue(i) {
      return this.form.inputs[i].type === 'checkbox'
    },
    isStaticText(i) {
      return this.form.inputs[i].type === 'static_text'
    },
    showConfirmationEmailFields() {
      return this.form.confirmation_required
    },
  },
  computed: {
    tabsFieldsSchema() {
      return [
        ...this.getCommonFields(),
        {
          label: 'Ogólne',
          fields: [
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'name'],
              required: true,
            }),
            new fields.WYSIWYG({
              label: 'Tekst',
              required: false,
              modelPath: [this, 'form', 'description'],
              type: 'emptyMenuBarInit'
            }),
            new fields.Image({
              label: 'Zdjęcie',
              modelPath: [this, 'form', 'image'],
              required: false,
            }),
            new fields.Input({
              label: 'Submit button text',
              modelPath: [this, 'form', 'submit_button_text'],
              required: true,
            }),
            new fields.Select({
              label: 'Dodać akceptacje regulaminu?',
              modelPath: [this, 'form', 'show_accept_terms'],
              required: true,
              options: [
                {
                  label: 'Tak',
                  value: true,
                  selected: true
                },
                {
                  label: 'Nie',
                  value: false
                },
              ]
            }),
          ],
      },
      {
        label: 'Confirmation email',
        fields: [
            new fields.Select({
                label: 'Czy wysłać maila potwierdzającego?',
                modelPath: [this, 'form', 'confirmation_required'],
                required: true,
                options: [
                {
                    label: 'Tak',
                    value: true,
                    selected: true
                },
                {
                    label: 'Nie',
                    value: false
                },
                ]
            }),
            new fields.Input({
                label: 'Tytuł strony potwierdzenia',
                modelPath: [this, 'form', 'confirmation_title'],
                required: true,
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.WYSIWYG({
                label: 'Tekst strony potwierdzenia',
                required: true,
                modelPath: [this, 'form', 'confirmation_text'],
                type: 'emptyMenuBarInit',
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.Image({
                label: 'Zdjęcie strony potwierdzenia',
                modelPath: [this, 'form', 'confirmation_image'],
                required: true,
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.Link({
                label: 'Link strony potwierdzenia',
                modelPath: [this, 'form', 'confirmation_link'],
                required: true,
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.Input({
                label: 'Temat maila',
                modelPath: [this, 'form', 'confirmation_mail_subject'],
                required: true,
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.Input({
                label: 'Tytuł maila',
                modelPath: [this, 'form', 'confirmation_mail_title'],
                required: true,
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.Input({
                label: 'Tekst maila',
                required: true,
                modelPath: [this, 'form', 'confirmation_mail_text'],
                type: 'textarea',
                hideFormItem: !this.showConfirmationEmailFields()
            }),
            new fields.Input({
                label: 'Tekst przycisku maila',
                modelPath: [this, 'form', 'confirmation_mail_button_text'],
                required: true,
                hideFormItem: !this.showConfirmationEmailFields()
            }),
        ]
      },
      {
        label: 'Success screen',
        fields: [
          new fields.Input({
            label: 'Tytuł',
            modelPath: [this, 'form', 'success_title'],
            required: true,
          }),
          new fields.WYSIWYG({
            label: 'Tekst',
            required: true,
            modelPath: [this, 'form', 'success_description'],
            type: 'emptyMenuBarInit'
          }),
          new fields.Link({
            label: 'Link przekierowania',
            modelPath: [this, 'form', 'redirect_to'],
            required: false,
          }),
        ]
      },
      {
        label: 'Outputs',
        fields: [
          new fields.Formset({
            modelPath: [this, 'form', 'outputs'],
            addButtonLabel: 'Dodaj nowy output',
            items: this.form.outputs.map((output, i) => ({
              headerLabel: `Output ${i + 1}`,
              fields: [
                new fields.Select({
                  label: 'Typ',
                  modelPath: [this, 'form', 'outputs', i, 'type'],
                  required: true,
                  options: [
                    {
                      label: 'Google sheet',
                      value: 'gsheet',
                    },
                    {
                      label: 'Email',
                      value: 'mail',
                    },
                  ]
                }),
                new fields.Input({
                  label: 'Destination',
                  modelPath: [this, 'form', 'outputs', i, 'destination'],
                  required: true,
                }),
              ]
              }))
            })
        ]
      },
      {
        label: 'Inputs',
        fields: [
          new fields.Formset({
            modelPath: [this, 'form', 'inputs'],
            addButtonLabel: 'Dodaj nowy input',
            items: this.form.inputs.map((input, i) => ({
              headerLabel: `Input ${i + 1}`,
              fields: [
                this.isStaticText(i) ?
                new fields.WYSIWYG({
                  label: 'Tekst',
                  required: false,
                  modelPath: [this, 'form', 'inputs', i, 'label'],
                  type: 'emptyMenuBarInit'
                }) :
                new fields.Input({
                  label: 'Label',
                  modelPath: [this, 'form', 'inputs', i, 'label'],
                  required: true,
                }),
                new fields.Select({
                  label: 'Typ',
                  modelPath: [this, 'form', 'inputs', i, 'type'],
                  required: true,
                  options: [
                    {
                      label: 'Text',
                      value: 'text',
                      selected: true
                    },
                    {
                      label: 'Number',
                      value: 'number'
                    },
                    {
                      label: 'Email',
                      value: 'email'
                    },
                    {
                      label: 'Phone',
                      value: 'phone'
                    },
                    {
                      label: 'Checkbox',
                      value: 'checkbox'
                    },
                    {
                      label: 'Radio',
                      value: 'radio'
                    },
                    {
                      label: 'Select',
                      value: 'select'
                    },
                    {
                      label: 'Textarea',
                      value: 'textarea'
                    },
                    {
                      label: 'Static text',
                      value: 'static_text'
                    },
                  ]
                }),
                new fields.Select({
                  label: 'Wymagane',
                  modelPath: [this, 'form', 'inputs', i, 'required'],
                  required: true,
                  options: [
                    {
                      label: 'Tak',
                      value: true,
                      selected: true
                    },
                    {
                      label: 'Nie',
                      value: false
                    },
                  ]
                }),
                new fields.Input({
                  label: 'Placeholder',
                  modelPath: [this, 'form', 'inputs', i, 'placeholder'],
                  required: false,
                  hideFormItem: !this.showPlaceholder(i)
                }),
                new fields.Input({
                  label: 'Checkbox value',
                  modelPath: [this, 'form', 'inputs', i, 'checkbox_value'],
                  required: false,
                  hideFormItem: !this.showCheckboxValue(i)
                }),
                new fields.Formset({
                    modelPath: [this, 'form', 'inputs', i, 'choices'],
                    addButtonLabel: 'Dodaj nowy wybór',
                    hideAddNewButton: !this.showChoices(i),
                    emptyComponentLabel: '',
                    items: this.getChoices(i).map((choice, j) => ({
                      headerLabel: `Wybór ${j + 1}`,
                      fields: [
                        new fields.Input({
                          label: 'wartość',
                          modelPath: [this, 'form', 'inputs', i, 'choices', j, 'value'],
                          required: true,
                        }),
                      ]
                    }))
                })
              ]
            }))
          })
        ]
      }
    ]
    }
  }
}
</script>

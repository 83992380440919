import Vue from 'vue'
import App from './App'
import ElementUI from 'element-ui'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale/lang/pl'
import store from './store/'
import Vuex from 'vuex'
import router from './router'
import messages from '@/i18n'
import WYSIWYGInput from '@/components/inputs/WYSIWYGInput'
import ImageInput from '@/components/inputs/ForeignKeys/ImageInput'
import AttachmentInput from '@/components/inputs/ForeignKeys/AttachmentInput'
import LinkInput from '@/components/inputs/ForeignKeys/LinkInput'
import '@/mixins'
import Editor from '@tinymce/tinymce-vue'
import SearchListForm from '@/components/SearchListForm'

import 'element-ui/lib/theme-chalk/index.css'

import 'font-awesome/css/font-awesome.min.css'
import HomePageVideoInput from '@/components/inputs/ForeignKeys/HomePageVideoInput'
import AudioReadOnly from '@/components/inputs/AudioReadOnly'
import AudioLinkInput from '@/components/inputs/ForeignKeys/AudioLinkInput'
import VideoLinkInput from '@/components/inputs/ForeignKeys/VideoLinkInput'
import FileInput from '@/components/inputs/FileInput'

Vue.use(ElementUI, { locale })
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'pl', // set locale
  fallbackLocale: 'pl',
  messages // set locale messages
})

// global components
Vue.component('editor', Editor)
Vue.component('wysiwyg-input', WYSIWYGInput)
Vue.component('audio-read-only', AudioReadOnly)
Vue.component('image-input', ImageInput)
Vue.component('attachment-input', AttachmentInput)
Vue.component('home-page-video-input', HomePageVideoInput)
Vue.component('link-input', LinkInput)
Vue.component('audio-link-input', AudioLinkInput)
Vue.component('video-link-input', VideoLinkInput)
Vue.component('search-list-form', SearchListForm)
Vue.component('file-input', FileInput)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

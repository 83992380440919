<script>
import { Example, User } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: FormMixin,
  data() {
    return {
      form: {
        widgets: [],
        items: []
      },
      redirectRoute: { name: 'Examples List' }
    }
  },
  computed: {
    service() {
      return Example
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Image({
          modelPath: [this, 'form', 'image'],
          label: 'Label!'
        }),
        new fields.Select({
          modelPath: [this, 'form', 'select'],
          options: [
            { label: 'A', value: 1 },
            { label: 'B', value: 2 }
          ]
        }),
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title']
        }),
        new fields.Input({
          label: 'Slug',
          modelPath: [this, 'form', 'slug']
        }),
        new fields.ForeignKey({
          label: 'Sample FK',
          modelPath: [this, 'form', 'users'],
          multiple: true,
          sortable: true,
          labelField: 'email',
          required: true,
          service: User
        }),
        new fields.Attachment({
          label: 'Hello world',
          modelPath: [this, 'form', 'attachment']
        }),
        new fields.Widgets({
          modelPath: [this, 'form', 'widgets']
        }),
        new fields.Formset({
          blankObject: {}, // optional
          modelPath: [this, 'form', 'items'],
          items: this.form.items.map((item, i) => {
            return {
              headerLabel: item.title,
              fields: [
                new fields.Input({
                  label: 'Tytuł',
                  modelPath: [this, 'form', 'items', i, 'title']
                }),
                new fields.Image({
                  label: 'Zdjęcie',
                  modelPath: [this, 'form', 'items', i, 'image']
                }),
                new fields.Link({
                  label: 'Link',
                  modelPath: [this, 'form', 'items', i, 'link']
                })
              ]
            }
          })
        })
      ]
    }
  }
}
</script>

<template>
  <el-table-column
    :width="field.width"
    :sortable="field.sortable ? 'custom' : false"
    :label="field.label"
    :prop="field.prop"
    :key="field.prop"
  >
    <template slot-scope="scope" class="pull-right" v-if="src(scope)">
      <audio controls>
        <source :src="src(scope)" :type="type(scope)" />
      </audio>
    </template>
  </el-table-column>
</template>

<script>
import { get } from 'lodash'

export default {
  props: ['field'],
  computed: {
    src() {
      return scope => get(scope.row, this.field.prop)
    },
    type() {
      return scope => get(scope.row, this.field.source_type)
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 120px;
}
</style>

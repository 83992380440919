import FieldBase from './FieldBase'
import RemoteSelectInputBase from '@/components/inputs/RemoteSelectInputBase'

export default class extends FieldBase {
  constructor({
    service,
    multiple,
    sortable,
    allowRefresh,
    allowRefreshCallback,
    allowAddEdit,
    disabled,
    clearable,
    paginated,
    labelField,
    valueField,
    multipleLimit,
    staticOptions,
    placeholder,
    ...args
  }) {
    super(args)
    this.children = [
      {
        ...this.getComponentWithVModel(RemoteSelectInputBase),
        props: {
          service,
          multiple,
          sortable,
          allowRefresh,
          allowRefreshCallback,
          allowAddEdit,
          disabled,
          clearable,
          paginated,
          labelField,
          valueField,
          multipleLimit,
          staticOptions,
          placeholder
        }
      }
    ]
  }
}

<script>
import { Example } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Example',
      addRoute: { name: 'Add Example' },
      verboseName: 'Przykład',
      verboseNamePlural: 'Przykłady',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'title_pl', label: 'tytuł (pl)' },
        { prop: 'title_en', label: 'tytuł (en)' },
        { prop: 'slug_pl', label: 'slug_pl' },
        { prop: 'slug_en', label: 'slug_en' }
      ]
    }
  },
  computed: {
    service() {
      return Example
    }
  }
}
</script>

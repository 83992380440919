<template>
  <div>
    <component-compiler
      v-for="(component, i) in getFields()"
      :content="component"
      :key="`form-${i}`"
    />
    <br />
  </div>
</template>
<script>
import FieldsHelper from '@/mixins/form/FieldsHelper'
import fields from '@/components/fields/'
import ComponentCompiler from '@/components/ComponentCompiler'
import { get } from 'lodash'

export default {
  mixins: [FieldsHelper],
  components: {
    ComponentCompiler
  },
  methods: {
    getFields() {
      return [
        new fields.Header({
          text:
            'Podcasty, które powinny zostać usunięte, ponieważ nie zostały znalezione pod podanym adresem URL:',
          type: 'h4'
        }),
        new fields.Formset({
          modelPath: [this, 'form'],
          hideItemButtons: true,
          hideAddNewButton: true,
          emptyComponentLabel: 'brak podcastów do usunięcia',
          items: this.form.map((item, i) => ({
            headerLabel: get(item, ['title']),
            fields: [
              new fields.ReadOnly({
                label: 'Zewnętrzne ID',
                modelPath: [this, 'form', i, 'guid']
              }),
              new fields.ReadOnly({
                label: 'Tytuł',
                modelPath: [this, 'form', i, 'title']
              }),
              {
                component: 'router-link',
                attrs: {
                  to: {
                    name: 'SimpleCastAudio List',
                    query: {
                      search: this.form[i].guid
                    }
                  },
                  target: '_blank'
                },
                children: [
                  this.getButtonComponent({
                    icon: 'fa fa-edit',
                    title: 'Szczegóły',
                    label: 'Szczegóły '
                  })
                ]
              }

              // {
              //   component: 'el-button',
              //   attrs: {
              //     size: 'small',
              //     title: 'przejdź',
              //     type: 'basic',
              //   },
              //   listeners: {
              //     click: () => {
              //       console.log(this.form[i].guid);
              //       this.$router.push({ ...this.redirectRoute })
              //
              //     }
              //   },
              //   innerText: 'przejdź',
              // }
            ]
          }))
        })
      ]
    }
  }
}
</script>

<template>
  <div v-if="value && type">
    <audio controls>
      <source :src="value" :type="type" />
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    value: [String],
    type: [String]
  }
}
</script>

export default {
  logout: 'Wyloguj',
  search: 'Szukaj',
  query: 'Fraza do wyszukania',
  cancel: 'Anuluj',
  submit: 'Zatwierdź',
  manage: 'Zarządzaj',
  add: 'Dodaj',
  edit: 'Edytuj',
  delete: 'Usuń',
  all: 'Wszystkie',
  published: 'Opublikowane',
  unpublished: 'Nieopublikowane',
  status: 'Status',
  year: 'Rok',
  areYouSure: 'Na pewno?',
  deleteConfirmation: 'Element zostanie bezpowrotnie usunięty.'
}

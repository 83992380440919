<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['items']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Formset({
              modelPath: [this, 'form', 'items'],
              addButtonLabel: 'Dodaj nowy element',
              blankObject: { steps: [] },
              items: this.form.items.map((item, i) => ({
                headerLabel: get(item, ['title']),
                fields: [
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'items', i, 'title'],
                    required: true
                  }),
                  new fields.Image({
                    label: 'Zdjęcie',
                    modelPath: [this, 'form', 'items', i, 'image']
                  }),
                  new fields.Formset({
                    modelPath: [this, 'form', 'items', i, 'steps'],
                    addButtonLabel: 'Dodaj nowy krok',
                    items: this.form.items[i].steps.map((stepItem, j) => ({
                      headerLabel: `Krok ${j + 1}`,
                      fields: [
                        new fields.WYSIWYG({
                          label: 'Opis',
                          modelPath: [
                            this,
                            'form',
                            'items',
                            i,
                            'steps',
                            j,
                            'description'
                          ],
                          required: false,
                          type: 'emptyMenuBarInit'
                        })
                      ]
                    }))
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

<script>
import { debounce } from 'lodash'
import LivePreview from '@/components/preview/LivePreview'

export default {
  data() {
    return {
      livePreview: true,
      previewContent: null,
      previewContentLoading: false
    }
  },
  computed: {
    previewAvailable() {
      return !!this.previewContent
    }
  },
  methods: {
    fetchPreview: debounce(function () {
      if (!this.livePreview) {
        return
      }
      this.previewContentLoading = true
      this.service
        .preview(this.lookupParams, this.form)
        .then(res => {
          this.previewContent = res.data
        })
        .catch(() => {
          this.previewContent = null
        })
        .finally(() => {
          this.previewContentLoading = false
        })
    }, 500),
    getPreviewTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          name: 'preview',
          label: this.previewContentLoading ? 'Podgląd ↻' : 'Podgląd'
        },
        props: {
          disabled: !this.previewAvailable
        },
        children: [
          {
            component: LivePreview,
            props: {
              previewContent: this.previewContent,
              pageType: this.pageType
            },
            directives: [
              {
                name: 'loading',
                value: this.previewContentLoading
              }
            ],
            listeners: {
              'edit-base': () => {
                this.selectedTab = 'general'
              },
              'add-widget': widgetIndex =>
                this.$refs.widgetDialogs.openAddWidgetDialog(widgetIndex),
              'edit-widget': widgetIndex =>
                this.$refs.widgetDialogs.editWidget(widgetIndex),
              'remove-widget': widgetIndex =>
                this.form.widgets.splice(widgetIndex, 1),
              'move-up-widget': widgetIndex =>
                this.changeOrder(this.form.widgets, widgetIndex, -1),
              'move-down-widget': widgetIndex =>
                this.changeOrder(this.form.widgets, widgetIndex, 1)
            }
          }
        ]
      }
    }
  }
}
</script>

<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['elements', 'files']
    }
  },
  methods: {
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              nullable: false,
              required: true,
            }),
            new fields.Input({
              label: 'Podtytuł',
              modelPath: [this, 'form', 'subtitle'],
              nullable: false,
              required: true,
            }),
          ]
        },
        {
          label: 'Elementy',
          fields: [
            ...this.getCommonFields(),
            new fields.Formset({
              modelPath: [this, 'form', 'elements'],
              items: this.form.elements.map((item, i) => ({
                headerLabel: `Element nr ${i + 1}`,
                fields: [
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'elements', i, 'title'],
                    required: true
                  }),
                  new fields.Input({
                    label: 'Tekst',
                    modelPath: [this, 'form', 'elements', i, 'text'],
                    required: true
                  }),
                  new fields.Input({
                    label: 'Kolor',
                    modelPath: [this, 'form', 'elements', i, 'color'],
                    required: true,
                    type: "color"
                  }),
                  new fields.Input({
                    label: 'Wartość',
                    modelPath: [this, 'form', 'elements', i, 'value'],
                    required: true,
                    type: 'number'
                  }),
                ]
              }))
            })
          ]
        },
        {
          label: 'Pliki',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tekst',
              modelPath: [this, 'form', 'files_text'],
              nullable: false,
              required: true,
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'files'],
              items: this.form.files.map((item, i) => ({
                headerLabel: `Plik nr ${i + 1}`,
                fields: [
                  new fields.Link({
                    label: 'Plik',
                    modelPath: [this, 'form', 'files', i, 'file'],
                    required: true
                  }),
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

/**
 * The file enables `@/components/widgets/forms/index.js` to import all widgets
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.vue$/)

const widgets = {}
const ignoredFiles = ['./index.js', './BaseWidgetForm.vue']
files.keys().forEach(key => {
  if (ignoredFiles.includes(key)) return
  widgets[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default
})

export default widgets

<template>
  <div>
    <h1 class="welcome-text">Hello {{ user.email }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  }
}
</script>

<style lang="scss" scoped>
.welcome-text {
  font-size: 20px;
}
</style>

<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['partners']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'name'],
              required: false
            }),
            new fields.Header({
              text: 'Partnerzy'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'partners'],
              addButtonLabel: 'Dodaj partnera',
              required: false,
              items: this.form.partners.map((item, i) => ({
                headerLabel: 'hello',
                fields: [
                  new fields.Link({
                    label: 'Link',
                    hideLinkLabel: true,
                    required: false,
                    modelPath: [this, 'form', 'partners', i, 'link']
                  }),
                  new fields.Image({
                    label: 'Logo',
                    required: false,
                    extension: ['.svg', '.png'],
                    modelPath: [this, 'form', 'partners', i, 'image']
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

<template>
  <div style="margin-left: 20px">
    <h3>Sortuj artykuły należące do wybranych tagów:</h3>
    <p v-if="form && form.length === 0">brak artykułów</p>
    <div
      class="el-table list__table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
    >
      <table
        class="table table-striped"
        v-if="availableArticles && availableArticles.length"
        style="width: 100%"
      >
        <thead class="thead-dark">
          <tr>
            <th scope="col">numer</th>
            <th scope="col">tytuł</th>
            <th scope="col">przypisane tagi</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <draggable v-model="form" tag="tbody">
          <tr v-for="(item, index) in formAsDict" :key="item.id || item">
            <td>{{ index + 1 }}</td>
            <td>{{ item.title }}</td>
            <td>
              {{
                item.tags_data
                  .filter(tag => tags.includes(tag.id))
                  .map(tag => tag.name)
                  .join(', ')
              }}
            </td>
            <td>
              <img src="/widgets/content-widget/drag_and_drop_icon.svg" />
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'table-example',
  display: 'Table',
  order: 8,
  components: {
    draggable
  },
  props: {
    value: { type: [Array], required: true },
    errors: { type: [Object, Array, String], required: true },
    availableArticles: { type: Array, required: true },
    tags: { type: Array, required: true }
  },
  methods: {},
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    formAsDict() {
      return this.form.map(item =>
        this.availableArticles.find(instance => instance.id === item)
      )
    }
  },
  data() {
    return {
      dragging: false
    }
  }
}
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>

import axios from 'axios'
import Service, { prepareURL } from '@/api/service'
import getFormDataFromJSON from '@/common/js/formdata'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

/*
    using '...Service('/url/:id/')' in object provides methods:
        fetch
        get
        delete
        put
        save
 */

export const SiteSettings = {
  update: payload => {
    return axios.put('/admin/core/settings/', payload)
  },
  get: payload => {
    return axios.get('/admin/core/settings/', payload)
  },
  fetchBannerWidgetAvailableColourVersions: payload => {
    return axios.get(
      '/admin/core/widgets-options/banner-widget-available-colour-versions/',
      payload
    )
  }
}

export const Attachment = {
  ...Service('/admin/attachments/:id/'),
  upload: payload => {
    return axios.post('/admin/attachments/', payload, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }
}

export const Page = {
  ...Service('/admin/pages/:id/'),
  revision(params, payload) {
    const url = prepareURL(`/admin/pages/:id/revision/`, params)
    return axios.post(url, payload)
  },
  preview(urlParams, payload) {
    const url = '/admin/pages/:id/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  createCopy: (urlParams, payload) => {
    return axios.post(
      prepareURL('/admin/pages/:id/create-copy/', urlParams),
      {}
    )
  }
}

export const Revision = Service('/admin/revisions/:id/')

export const Payments = {
  ...Service('/admin/payments/'),
  exportData() {
    return axios.get('/admin/payments/export/')
  }
}

export const Article = {
  ...Service('/admin/articles/:id/'),
  preview(urlParams, payload) {
    const url = '/admin/articles/:id/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(`/admin/articles/:id/revision/`, params)
    return axios.post(url, payload)
  },
  availableTypes(params) {
    return axios.get('/admin/articles/types/', { params })
  },
  usedTags(params) {
    return axios.get('/admin/articles/used-tags/', { params })
  },
  moveUp: (urlParams, payload) => {
    return axios.post(prepareURL('/admin/articles/:id/move-up/', urlParams), {})
  },
  moveDown: (urlParams, payload) => {
    return axios.post(
      prepareURL('/admin/articles/:id/move-down/', urlParams),
      {}
    )
  },
  createCopy: (urlParams, payload) => {
    return axios.post(
      prepareURL('/admin/articles/:id/create-copy/', urlParams),
      {}
    )
  }
}

export const ArticleTag = {
  ...Service('/admin/articles/tags/:id/')
}

export const ArticleTemplate = {
  ...Service('/admin/articles/template/')
}

export const SimpleCastAudio = {
  ...Service('/admin/audio/simple-cast-audio/:id/'),
  availableFeedUrls(params) {
    return axios.get('/admin/audio/simple-cast-audio/feed-urls/', { params })
  }
}

export const ImportSimpleCastAudio = {
  save: (payload, headers = {}) => {
    return axios.post(
      prepareURL('/admin/audio/simple-cast-audio/import-podcasts/'),
      payload,
      headers
    )
  }
}

export const VimeoVideo = {
  ...Service('/admin/video/vimeo-video/:id/')
}

export const HelpPlace = {
  ...Service('/admin/help-places/:id/'),
  availablePlaceTypes(params) {
    return axios.get('/admin/help-places/place_types/', { params })
  },
  availableFormsOfHelp(params) {
    return axios.get('/admin/help-places/forms_of_help/', { params })
  },
  availableAges(params) {
    return axios.get('/admin/help-places/ages/', { params })
  },
  availableProvinces(params) {
    return axios.get('/admin/help-places/provinces/', { params })
  }
}

export const ImportHelpPlace = {
  save: (payload, headers = {}) => {
    return axios.post(
      prepareURL('/admin/help-places/import-help-places/'),
      payload,
      {
        timeout: 90000,
        headers: { ...headers, 'content-type': 'multipart/form-data' }
      }
    )
  }
}

export const BroughtBackToLifePage = {
  ...Service('/admin/static-pages/brought-back-to-life/:slug/'),
  preview(urlParams, payload) {
    const url = '/admin/static-pages/brought-back-to-life/:slug/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(
      `/admin/static-pages/brought-back-to-life/:slug/revision/`,
      params
    )
    return axios.post(url, payload)
  }
}

export const SuicideMythPage = {
  ...Service('/admin/static-pages/suicide-myths/:slug/'),
  preview(urlParams, payload) {
    const url = '/admin/static-pages/suicide-myths/:slug/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(
      `/admin/static-pages/suicide-myths/:slug/revision/`,
      params
    )
    return axios.post(url, payload)
  }
}

export const HomePageVideo = {
  ...Service('/admin/static-pages/home/video/:id/'),
  upload: (payload, uploadProgressCallback) => {
    return axios.post('/admin/static-pages/home/video/', payload, {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        uploadProgressCallback(progress.toFixed(2))
      }
    })
  }
}

export const HomePage = {
  ...Service('/admin/static-pages/home/:slug/'),
  preview(urlParams, payload) {
    const url = '/admin/static-pages/home/:slug/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(`/admin/static-pages/home/:slug/revision/`, params)
    return axios.post(url, payload)
  }
}

export const SliderPage = {
  ...Service('/admin/static-pages/slider/:slug/'),
  preview(urlParams, payload) {
    const url = '/admin/static-pages/slider/:slug/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(`/admin/static-pages/slider/:slug/revision/`, params)
    return axios.post(url, payload)
  }
}

export const FreeAidStationsPage = {
  ...Service('/admin/static-pages/free-aid-stations/:slug/'),
  preview(urlParams, payload) {
    const url = '/admin/static-pages/free-aid-stations/:slug/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(
      `/admin/static-pages/free-aid-stations/:slug/revision/`,
      params
    )
    return axios.post(url, payload)
  }
}

export const TollFreeHelplinesPage = {
  ...Service('/admin/static-pages/toll-free-helplines/:slug/'),
  preview(urlParams, payload) {
    const url = '/admin/static-pages/toll-free-helplines/:slug/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(
      `/admin/static-pages/toll-free-helplines/:slug/revision/`,
      params
    )
    return axios.post(url, payload)
  }
}

export const Example = Service('/admin/examples/:id/')

export const Widget = {
  ...Service('/widgets/admin/:id/'),
  types(params) {
    return axios.get('/widgets/admin/types/', { params })
  },
  validate(payload) {
    return axios.post('/widgets/admin/validate/', payload)
  },
  available(params) {
    return axios.get('/widgets/admin/available/', { params })
  },
  preview(payload) {
    return axios.post('/widgets/admin/preview/', payload)
  }
}

export const Menu = {
  ...Service('/admin/menu/menu/')
}

export const FooterMenu = {
  ...Service('/admin/menu/footer/')
}

export const Link = {
  availableLinkOptions() {
    return axios.get('/admin/links/available-options/')
  },
  availableWidgets(params) {
    return axios.get('/admin/links/available-widgets/', { params })
  }
}

export const Auth = {
  login(payload) {
    return axios.post('/public/users/auth/token/login/', payload)
  },
  twoFactor(token) {
    return axios.post('/admin/users/api-email-token-verify/', { token })
  },
  tokenRefresh(payload) {
    return axios.post('/public/user/api-token-refresh/', payload)
  }
}

export const Image = {
  ...Service('/admin/images/:id/'),
  save: (payload, onUploadProgress) => {
    const formData = getFormDataFromJSON(payload)
    return axios.post('/admin/images/', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress
    })
  },
  update: (urlParams, payload) => {
    const formData = getFormDataFromJSON(payload)
    const defaultUpdate = Service('/admin/images/:id/')['update']
    return defaultUpdate(urlParams, formData, {
      'content-type': 'multipart/form-data'
    })
  }
}

export const Catalog = {
  ...Service('/admin/images/catalogs/:id/')
}

export const User = {
  ...Service('/admin/users/:id/')
}

export const Dashboard = {
  getContentCounters() {
    return axios.get('/admin/dashboard/content-counters/')
  },
  getRecentPages() {
    return axios.get('/admin/dashboard/recent-pages/')
  },
  getUser() {
    return axios.get('/admin/dashboard/user/')
  }
}

export const ZendeskTask = {
  ...Service('/admin/zendesk/:id/'),
  availableStatuses(params) {
    return axios.get('/admin/zendesk/available_statuses/', { params })
  },
  availableTasks(params) {
    return axios.get('/admin/zendesk/available_tasks/', { params })
  },
  retryTask: (urlParams, payload) => {
    return axios.post(
      prepareURL('/admin/zendesk/:id/retry/', urlParams),
      payload
    )
  },
  exportReplyMessages: () => {
    return axios.get('/admin/zendesk/replies/export/')
  }
}

export const AudioLink = {
  availableAudioLinkOptions() {
    return axios.get('/admin/audio/available-audio-options/')
  }
}

export const VideoLink = {
  availableVideoLinkOptions() {
    return axios.get('/admin/video/available-video-options/')
  }
}

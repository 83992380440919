<template>
  <el-table-column
    :width="field.width"
    :sortable="field.sortable ? 'custom' : false"
    :label="field.label"
    :prop="field.prop"
    :key="field.prop"
  >
    <template slot-scope="scope" class="pull-right">
      <component-compiler
        v-for="(component, i) in fields(scope.row)"
        :content="component"
        :key="`order-column-${i}`"
      />
    </template>
  </el-table-column>
</template>

<script>
import { get } from 'lodash'
import ComponentCompiler from '@/components/ComponentCompiler'

export default {
  props: ['field'],
  components: {
    ComponentCompiler
  },
  computed: {
    articles() {
      return scope => get(scope.row, this.field.prop)
    },
    lookupKwarg() {
      let lookup = this.field.lookupKwarg
      if (!lookup) {
        lookup = 'id'
      }
      return lookup
    }
  },
  data() {
    return {
      buttonsDisabled: false
    }
  },
  methods: {
    getDetailsRouteParams(instance) {
      const params = {}
      params[this.lookupKwarg] = instance[this.lookupKwarg]
      return params
    },
    fields(row) {
      return [
        this.getButtonComponent({
          icon: 'fa fa-arrow-down',
          onClick: () => this.moveDown(row),
          type: 'basic',
          style: 'margin-left: 0',
          disabled: this.buttonsDisabled
        }),
        this.getButtonComponent({
          icon: 'fa fa-arrow-up',
          onClick: () => this.moveUp(row),
          type: 'basic',
          style: 'margin-left: 0',
          disabled: this.buttonsDisabled
        }),
        this.getButtonComponent({
          icon: 'fa fa-copy',
          onClick: () => this.createCopy(row),
          type: 'basic',
          style: 'margin-left: 0',
          disabled: this.buttonsDisabled
        })
      ]
    },
    runServiceMethod(row, serviceMethod, successMessage, errorMessage) {
      const lookupParams = { id: row.id }
      this.buttonsDisabled = true
      this.field.setListAsLoading()
      serviceMethod(lookupParams)
        .then(response => {
          this.$message({
            message: successMessage,
            type: 'success'
          })
          this.field.fetchDataCallback()
        })
        .catch(error => {
          this.$message({
            message: errorMessage,
            type: 'error'
          })
        })
        .finally(() => {
          this.buttonsDisabled = false
        })
    },
    moveUp(row) {
      this.runServiceMethod(row, this.field.service.moveUp, 'Zmieniono kolejność artykułu', 'Wystąpił błąd podczas zmieniania kolejności artykułu')
    },
    moveDown(row) {
      this.runServiceMethod(row, this.field.service.moveDown, 'Zmieniono kolejność artykułu', 'Wystąpił błąd podczas zmieniania kolejności artykułu')
    },
    createCopy(row) {
      this.runServiceMethod(row, this.field.service.createCopy, 'Utworzono kopię artykułu', 'Wystąpił błąd podczas tworzenia kopii artykułu')
    },
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 120px;
}
</style>

<template>
  <div>
    <user :user="user" v-loading="loadingUser" />
    <content-counters
      v-loading="loadingContentCounters"
      :contentCounters="contentCounters"
    />
    <recent-pages v-loading="loadingRecentPages" :recentPages="recentPages" />
  </div>
</template>
<script>
import { Dashboard } from '@/api/'
import ContentCounters from './ContentCounters'
import RecentPages from './RecentPages'
import User from './User'

export default {
  data() {
    return {
      contentCounters: {},
      user: {},
      recentPages: [],
      loadingContentCounters: false,
      loadingRecentPages: false,
      loadingUser: false
    }
  },
  components: {
    ContentCounters,
    RecentPages,
    User
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadingContentCounters = true
      Dashboard.getContentCounters()
        .then(res => {
          this.contentCounters = res.data
        })
        .finally(() => {
          this.loadingContentCounters = false
        })

      this.loadingRecentPages = true
      Dashboard.getRecentPages()
        .then(res => {
          // let recentPages = res.data
          // recentPages = recentPages.filter(
          //   item => item.model_name !== 'SliderPage'
          // )
          this.recentPages = res.data
        })
        .finally(() => {
          this.loadingRecentPages = false
        })

      this.loadingUser = true
      Dashboard.getUser()
        .then(res => {
          this.user = res.data
        })
        .finally(() => {
          this.loadingUser = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-text {
  font-size: 36px;
}
</style>

<script>
export default {
  data() {
    return {
      filters: {
        available: [
          {
            name: 'Należy do zespołu',
            query_param: 'is_staff',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Jest aktywny',
            query_param: 'is_active',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Wiek',
            query_param: 'birth_date',
            options: [
              { label: 'Poniżej 18 lat', value: 'poniżej 18 lat' },
              { label: 'Powyżej 18 lat', value: 'powyżej 18 lat' }
            ],
            multiple: true
          },
          {
            name: 'Chce pomóc/Szuka pomocy',
            query_param: 'account_type',
            options: [
              { label: 'Chce pomóc', value: 'want_to_help' },
              { label: 'Szuka pomocy', value: 'need_help' }
            ],
            multiple: true
          }
        ],
        selected: {}
      }
    }
  },
  methods: {
    parseOption(option) {
      return { label: option.name, value: option.id }
    }
  }
}
</script>

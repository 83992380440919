<script>
import { get, keyBy } from 'lodash'
import moment from 'moment'

export default {
  data() {
    return {
      statuses: [],
      firstSteps: [],
      filters: {
        available: [
          {
            name: 'Status zadania',
            query_param: 'status',
            options: this.setStatusOptions
          },
          {
            name: 'Typ zadania',
            query_param: 'first_step',
            options: this.setInitialTaskOptions
          }
        ],
        selected: {}
      }
    }
  },
  computed: {
    statusesMap() {
      return keyBy(this.statuses, 'value')
    },
    firstStepsMap() {
      return keyBy(this.firstSteps, 'value')
    }
  },
  methods: {
    setStatusOptions(options) {
      this.service
        .availableStatuses()
        .then(response => {
          this.statuses = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    setInitialTaskOptions(options) {
      this.service
        .availableTasks()
        .then(response => {
          this.firstSteps = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    formatRunAtDate(field) {
      return value => {
        const fieldValue = get(value, field)
        const validStatus = get(value, 'status') === 'queued'
        if (validStatus && fieldValue) {
          return moment(String(fieldValue)).format('MM/DD/YYYY HH:mm:ss')
        } else {
          return '-'
        }
      }
    },
    getLabel(map, value) {
      const labelFound = map.hasOwnProperty(value)
      return (labelFound && map[value].label) || value
    },
    getStatusLabel(instance) {
      return this.getLabel(this.statusesMap, instance.status)
    },
    getFirstStepLabel(instance) {
      return this.getLabel(this.firstStepsMap, instance.first_step)
    }
  }
}
</script>

<script>
import { BroughtBackToLifePage } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Home' },
      selectedTab: 'general',
      form: {
        widgets: []
      },
      slugFieldDisabled: true,
      pageType: 'BroughtBackToLifePage'
    }
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          required: true,
          modelPath: [this, 'form', 'title']
        }),
        new fields.WYSIWYG({
          label: 'Lead',
          modelPath: [this, 'form', 'lead'],
          required: false,
          type: 'emptyMenuBarInit'
        }),
        new fields.AudioLink({
          label: 'Podcast',
          modelPath: [this, 'form', 'podcast'],
          nullable: false,
          required: true
        }),
        new fields.Image({
          label: 'Zdjęcie podcastu',
          modelPath: [this, 'form', 'podcast_image'],
          required: false
        }),
        new fields.Input({
          label: 'Tytuł podcastu',
          modelPath: [this, 'form', 'podcast_title'],
          required: true
        }),
        new fields.Input({
          label: 'Autor',
          modelPath: [this, 'form', 'author'],
          required: false
        }),
        new fields.Date({
          label: 'Data publikacji',
          type: 'date',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          modelPath: [this, 'form', 'date'],
          required: false,
          clearable: true
        }),
        new fields.ReadOnly({
          label: 'Data przed zmianą',
          modelPath: [this, 'form', 'deprecated_date'],
          required: false,
          hideFormItem: !get(this.form, 'deprecated_date')
        }),
        new fields.Switch({
          label: 'Ukryj',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    }
  },
  computed: {
    service() {
      return BroughtBackToLifePage
    }
  }
}
</script>

<script>
import { HelpPlace } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import FiltersMixin from '@/views/HelpPlace/FiltersMixin'
import { keyBy } from 'lodash'

export default {
  mixins: [ListMixin, FiltersMixin],
  data() {
    return {
      editRouteName: 'Edit HelpPlace',
      // addRoute: { name: 'Add HelpPlace' },
      verboseName: 'Miejsce pomocy',
      verboseNamePlural: 'Miejsca pomocy',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name', label: 'nazwa' },
        // {
        //   prop: 'place_type',
        //   label: 'typ placówki',
        //   formatter: this.getPlaceTypesLabels
        // },
        {
          prop: 'forms_of_help',
          label: 'formy pomocy',
          formatter: this.getFormsOfHelpLabels
        },
        { prop: 'ages', label: 'wiek', formatter: this.getAgesLabels },
        {
          prop: 'round_the_clock',
          label: 'otwarte całą dobę',
          type: 'boolean'
        },
        { prop: 'email', label: 'email' },
        { prop: 'phone', label: 'telefon' }
      ]
    }
  },
  computed: {
    service() {
      return HelpPlace
    },
    placeTypesMap() {
      return keyBy(this.placeTypes, 'value')
    },
    agesMap() {
      return keyBy(this.ages, 'value')
    },
    formsOfHelpMap() {
      return keyBy(this.formsOfHelp, 'value')
    }
  },
  methods: {
    editIcon() {
      return 'fa fa-eye'
    },
    removeButtonCondition(instance) {
      return false
    },
    headerActions() {
      return [
        this.getButtonComponent({
          label: 'Importuj placówki',
          icon: 'el-icon-upload',
          onClick: () => {
            this.$router.push({ name: 'Import HelpPlace' })
          }
        })
      ]
    },
    getLabel(map, value) {
      const labelFound = map.hasOwnProperty(value)
      return (labelFound && map[value].label) || value
    },
    getPlaceTypesLabels(instance) {
      return this.getLabel(this.placeTypesMap, instance.place_type)
    },
    getAgesLabels(instance) {
      const labels = instance.ages.map(value =>
        this.getLabel(this.agesMap, value)
      )
      return labels.join(', ')
    },
    getFormsOfHelpLabels(instance) {
      const labels = instance.forms_of_help.map(value =>
        this.getLabel(this.formsOfHelpMap, value)
      )
      return labels.join(', ')
    }
  }
}
</script>

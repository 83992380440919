<template>
  <div>
    <el-tabs>
      <el-tab-pane
        v-for="(tab, i) in tabsFieldsSchema"
        :label="tab.label"
        :key="i"
      >
        <component-compiler
          v-for="(component, x) in tab.fields"
          :content="component"
          :key="x"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ComponentCompiler from '@/components/ComponentCompiler'

export default {
  components: {
    ComponentCompiler
  },
  props: ['form', 'errors'],
  created() {
    this.fixArrayFields()
  },
  data() {
    return {
      arrayFields: []
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: this.getCommonFields()
        }
      ]
    }
  },
  methods: {
    getCommonFields() {
      return []
    },
    fixArrayFields() {
      this.arrayFields.forEach(field => {
        if (!Array.isArray(this.form[field])) {
          this.$set(this.form, field, [])
        }
      })
    }
  }
}
</script>

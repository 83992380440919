<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
@import '@/styles/all';

* {
  box-sizing: border-box;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
}

.el-submenu [class^='fa'] {
  vertical-align: baseline;
  margin-right: 10px;
}

.el-menu-item [class^='fa'] {
  vertical-align: baseline;
  margin-right: 10px;
}

.toolbar {
  padding: 10px;
  border: 1px solid #dfe6ec;
  margin: 10px 0px;

  .el-form-item {
    margin-bottom: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.el-message__img {
  margin-right: 17px;
}

.box {
  flex: 1;
  border: 1px solid #e7eaec;
  padding: 20px;
  background: #ffffff;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 12px;
    border-bottom: 1px solid #e7eaec;
    width: 100%;
    margin-bottom: 20px;

    &--text {
      font-weight: 300;
      font-size: 28px;
    }
  }
}

.fluid {
  width: 100%;
}

.pull-right {
  text-align: right;
}

.el-collapse-item__arrow {
  order: 1;
}

.el-collapse-item__header {
  display: flex;
}

.el-submenu {
  border-left: 4px solid transparent;
}

.el-submenu__title {
  padding-left: 16px !important;
}

.inline {
  display: inline-block;
}

.margin-right-15 {
  margin-right: 15px !important;
}
</style>

<template>
  <div class="new-preview">
    <div class="resolutions">
      <el-button type="primary" @click="width = 376">
        <div class="resolution"><p>376px</p><i class="fa fa-mobile"/></div>
      </el-button>
      <el-button class="resolution" type="primary" @click="width = 768">
        <div class="resolution"><p>768px</p><i class="fa fa-tablet"/></div>

      </el-button>
      <el-button class="resolution" type="primary" @click="width = 1280">
        <div class="resolution"><p>1280px</p><i class="fa fa-desktop"/></div>
      </el-button>
    </div>
    <div v-loading="loading">
      <iframe
          :src="`${fe_url}/preview/`"
          class="iframe"
          :style="{
            width: `${width}px`
        }"
          ref="iframe"
      />
    </div>

  </div>
</template>

<script>
export default {
  props: ['widgets', 'slug', 'title', 'lead', 'loading'],
  methods: {
    messageData() {
      this.$refs.iframe?.contentWindow.postMessage(
        {
          widgets: this.widgets,
          slug: this.slug,
          title: this.title,
          lead: this.lead
        },
        '*'
      )
    }
  },
  data() {
    return {
      interval: null,
      width: 1280,
      fe_url: process.env.VUE_APP_FRONTEND_URL
    }
  },
  watch: {
    loading() {
      this.messageData()
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.messageData()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.iframe {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  height: 68vh;
}

.new-preview {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.resolutions {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.resolution {
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  gap: 10px;
  p {
    margin: 0;
  }
}
</style>
/**
 * The file enables `@/components/fields/forms/index.js` to import all fields
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.js$/)

const fields = {}
const ignoredFiles = ['./index.js', './FieldBase.js']
files.keys().forEach(key => {
  if (ignoredFiles.includes(key)) return
  fields[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default fields

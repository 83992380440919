import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import { VideoLink, AudioLink, Link, Widget } from '@/api'
import { debounce } from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    availableWidgets: {},
    availableLinkOptions: [],
    availableAudioLinkOptions: [],
    availableVideoLinkOptions: [],
    widgetTypes: [],
    fieldTypes: {},
    selectedLanguage: 'pl',
    languages: [{ code: 'pl', label: 'Polski', flag: '/flags/pl.svg' }]
  },
  mutations: {
    setAvailableWidgets(state, payload) {
      state.availableWidgets = payload
    },
    setAvailableLinkOptions(state, payload) {
      state.availableLinkOptions = payload
    },
    setAvailableAudioLinkOptions(state, payload) {
      state.availableAudioLinkOptions = payload
    },
    setAvailableVideoLinkOptions(state, payload) {
      state.availableVideoLinkOptions = payload
    },
    setWidgetTypes(state, payload) {
      // const data = payload.filter(
      //   item => item.resourcetype !== 'FullVideoWidget'
      // )
      state.widgetTypes = payload
    },
    setAvailableFields(state, payload) {
      state.availableFields = payload
    },
    setFieldTypes(state, payload) {
      state.fieldTypes = payload
    }
  },
  actions: {
    fetchAvailableWidgets({ commit }) {
      return Widget.available()
        .then(response => {
          commit('setAvailableWidgets', response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchAvailableLinkOptions: debounce(function ({ dispatch }) {
      dispatch('_fetchAvailableLinkOptions')
    }, 500),
    _fetchAvailableLinkOptions({ commit }) {
      Link.availableLinkOptions()
        .then(response => {
          commit('setAvailableLinkOptions', response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    // audio link
    fetchAvailableAudioLinkOptions: debounce(function ({ dispatch }) {
      dispatch('_fetchAvailableAudioLinkOptions')
    }, 500),
    _fetchAvailableAudioLinkOptions({ commit }) {
      AudioLink.availableAudioLinkOptions()
        .then(response => {
          commit('setAvailableAudioLinkOptions', response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    // end of audio link
    // video link
    fetchAvailableVideoLinkOptions: debounce(function ({ dispatch }) {
      dispatch('_fetchAvailableVideoLinkOptions')
    }, 500),
    _fetchAvailableVideoLinkOptions({ commit }) {
      VideoLink.availableVideoLinkOptions()
        .then(response => {
          commit('setAvailableVideoLinkOptions', response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    // end of video link
    fetchWidgetTypes({ commit }) {
      return Widget.types().then(response => {
        commit('setWidgetTypes', response.data)
      })
    }
  },
  getters: {},
  setters: {},
  modules: {
    auth
  }
})

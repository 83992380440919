<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  methods: {},
  computed: {
    isVideo() {
      return this.form.type === 'video'
    },
    isAudio() {
      return this.form.type === 'audio'
    },
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Select({
              label: 'Typ widgetu',
              modelPath: [this, 'form', 'type'],
              options: [
                {
                  label: 'Zdjęcie',
                  value: 'image'
                },
                {
                  label: 'Audio',
                  value: 'audio'
                },
                {
                  label: 'Video',
                  value: 'video'
                }
              ]
            }),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              required: true
            }),
            new fields.WYSIWYG({
              label: 'Tekst',
              modelPath: [this, 'form', 'text'],
              required: false,
              type: 'emptyMenuBarInit'
            }),
            new fields.Switch({
              label: 'Zamień miejscami',
              modelPath: [this, 'form', 'swap_places'],
              help: 'Jeżeli zaznaczone to media po prawej stronie'
            }),
            new fields.Image({
              label: 'Zdjęcie',
              modelPath: [this, 'form', 'image'],
              required: get(this.form, 'type') === 'image'
            }),
            new fields.AudioLink({
              hideFormItem: !this.isAudio,
              label: 'Audio',
              modelPath: [this, 'form', 'audio'],
              nullable: false,
              required: true
            }),
            new fields.VideoLink({
              hideFormItem: !this.isVideo,
              label: 'Wideo',
              modelPath: [this, 'form', 'video'],
              nullable: false,
              required: true
            }),
            new fields.Header({
              text: 'BELKA'
            }),
            new fields.Input({
              label: 'Autor',
              modelPath: [this, 'form', 'author'],
              required: false
            }),
            new fields.Date({
              label: 'Data publikacji',
              type: 'date',
              format: 'yyyy-MM-dd',
              valueFormat: 'yyyy-MM-dd',
              modelPath: [this, 'form', 'date'],
              required: false,
              clearable: true
            }),
            new fields.ReadOnly({
              label: 'Data przed zmianą',
              modelPath: [this, 'form', 'deprecated_date'],
              required: false,
              hideFormItem: !get(this.form, 'deprecated_date')
            })
          ]
        }
      ]
    }
  },
  watch: {
    'form.type': {
      handler(value) {
        if (value === 'audio') {
          this.$nextTick(() => {
            this.$set(this.form, 'video', null)
            if (!this.form.audio) {
              this.$set(this.form, 'audio', {})
            }
          })
        } else if (value === 'video') {
          this.$nextTick(() => {
            this.$set(this.form, 'audio', null)
            if (!this.form.video) {
              this.$set(this.form, 'video', {})
            }
          })
        } else {
          this.$nextTick(() => {
            this.$set(this.form, 'audio', null)
            this.$set(this.form, 'video', null)
          })
        }
      }
    }
  }
}
</script>

<script>
import { Article, ArticleTag } from '@/api'

export default {
  data() {
    return {
      tagOptions: [],
      availableTypes: []
    }
  },
  methods: {
    fetchTags() {
      const params = { page_size: 'max' }
      ArticleTag.fetch({}, { params }).then(res => {
        this.tagOptions = res.data.results.map(item => ({
          label: item.name,
          value: item.name
        }))
      })
    },
    fetchAvailableArticleTypes() {
      Article.availableTypes().then(res => {
        this.availableTypes = res.data
      })
    }
  }
}
</script>

export default {
  logout: 'Logout',
  search: 'Search',
  query: 'Query',
  cancel: 'Cancel',
  submit: 'Submit',
  manage: 'Manage',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  all: 'All',
  published: 'Published',
  unpublished: 'Unpublished',
  status: 'Status',
  year: 'Year',
  areYouSure: 'Are you sure?',
  deleteConfirmation: 'Are you sure to delete item?'
}

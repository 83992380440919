<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  created() {
    if (!this.form.video) {
      this.$set(this.form, 'video', {})
    }
    this.fixArrayFields()
  },
  methods: {},
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Image({
              label: 'Zdjęcie',
              modelPath: [this, 'form', 'image'],
              required: true
            }),
            new fields.VideoLink({
              label: 'Wideo',
              modelPath: [this, 'form', 'video'],
              nullable: false,
              required: true
            }),
            new fields.Link({
              label: 'Link',
              help: 'link do przycisku “przejdź dalej”',
              required: false,
              nullable: true,
              hideLinkLabel: true,
              modelPath: [this, 'form', 'x_link']
            })
          ]
        }
      ]
    }
  }
}
</script>

import FormsetItem from '@/components/inputs/Formset/FormsetItem'
import Formset from '@/components/inputs/Formset/Formset'
import { get, isEmpty } from 'lodash'

class Item {
  constructor({ fields, headerLabel, items, item, hasErrors, hideButtons, hideRemoveItemButton }) {
    this.component = FormsetItem
    this.children = fields

    this.props = {
      headerLabel,
      items,
      item,
      hasErrors,
      hideButtons,
      hideRemoveItemButton
    }
  }
}

export default class {
  constructor({
    header,
    modelPath,
    items,
    blankObject,
    emptyComponentLabel,
    maxItems,
    hideItemButtons,
    hideRemoveItemButton,
    hideAddNewButton,
    addButtonLabel,
    condition
  }) {
    if (typeof modelPath[0] === 'string') {
      throw Error('First element in modelPath must be "this"')
    }

    this.vue = modelPath.shift()

    const errors = this.getErrorsForPath(modelPath)

    this.props = {
      errors,
      header,
      items: get(this.vue, modelPath),
      blankObject: blankObject !== undefined ? blankObject : {},
      maxItems,
      hideAddNewButton,
      addButtonLabel
    }
    this.component = Formset
    this.condition = condition
    const formsetItems = items.map((formset_item, i) => {
      const hasErrors = !isEmpty(get(errors, i))
      return new Item({
        ...formset_item,
        item: get(this.vue, modelPath)[i],
        items: get(this.vue, modelPath),
        hasErrors,
        hideButtons: hideItemButtons,
        hideRemoveItemButton: hideRemoveItemButton
      })
    })
    const emptyComponents = [
      {
        component: 'p',
        innerText: emptyComponentLabel
          ? emptyComponentLabel
          : 'Brak elementów do wyświetlenia'
      }
    ]

    this.children = formsetItems.length ? formsetItems : emptyComponents
  }

  getErrorsForPath(path) {
    const errorsPath = path[0] === 'form' ? path.slice(1) : path
    return get(this.vue.errors, errorsPath)
  }
}

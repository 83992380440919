<script>
import { Payments } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import FiltersMixin from "@/views/Payments/FiltersMixin.vue";
import {downloadBlobResponse} from "@/common/js/functions";

export default {
  mixins: [ListMixin, FiltersMixin],
  data() {
    return {
      verboseName: 'Płatność',
      verboseNamePlural: 'Płatności',
      exportButton: () => {
        this.exportData()
      },
      listFields: [
        { prop: 'id', label: 'id', width: 80, sortable: true },
        { prop: 'created', label: 'utworzono', sortable: true },
        { prop: 'modified', label: 'zmodyfikowano', sortable: true },
        { prop: 'order_id', label: 'pay-u id', sortable: true },
        { prop: 'type', label: 'typ', sortable: true },
        { prop: 'status', label: 'status', sortable: true },
        { prop: 'amount', label: 'kwota', sortable: true },
        { prop: 'issuer_name', label: 'nazwa', sortable: true },
        { prop: 'issuer_email', label: 'email', sortable: true },
        { prop: 'tos_consent', label: 'zgoda regulamin', type: 'boolean', sortable: true },
        { prop: 'marketing_consent', label: 'zgoda marketing', type: 'boolean', sortable: true },
      ]
    }
  },
  methods: {
    actions(scope) {},
    exportData() {
      this.service.exportData(this.filters).then(response => {
        downloadBlobResponse(response)
      }).catch(this.errorHandler)
    }
  },
  computed: {
    service() {
      return Payments
    }
  }
}
</script>

import { get } from 'lodash'

export default class FieldBase {
  constructor({
    label,
    modelPath,
    required,
    multilingual,
    condition,
    hideFormItem,
    ...args
  }) {
    if (typeof modelPath[0] === 'string') {
      throw Error('First element in modelPath must be "this"')
    }
    this.condition = condition
    this.modelPath = modelPath
    this.vue = modelPath.shift()

    this.addSuffixToPathIfMultilingual(multilingual)
    let error = this.getErrorsForPath(modelPath)
    if (Array.isArray(error) && error.length) {
      error = error[0]
    }
    this.formData = this.getModelPathDeconstructed()

    this.component = 'el-form-item'
    this.attrs = {
      label: multilingual ? `${label} (${this.currentLanguage})` : label,
      error,
      required,
      style: hideFormItem ? 'display:none;' : ''
    }
  }

  get currentLanguage() {
    return this.vue.$store.state.selectedLanguage
  }

  getComponentWithVModel(component) {
    const { form, fieldName, fieldValue } = this.formData
    return {
      component: component,
      listeners: {
        input: value => {
          return this.vue.$set(form, fieldName, value)
        }
      },
      attrs: {
        value: fieldValue
      }
    }
  }

  addSuffixToPathIfMultilingual(multilingual) {
    if (!multilingual) {
      return this.modelPath
    }
    const fieldName = `${this.modelPath[this.modelPath.length - 1]}_${
      this.currentLanguage
    }`
    this.modelPath[this.modelPath.length - 1] = fieldName
    return this.modelPath
  }

  getErrorsForPath(path) {
    const errorsPath = path[0] === 'form' ? path.slice(1) : path
    return get(this.vue.errors, errorsPath)
  }

  getModelPathDeconstructed() {
    const path = this.modelPath
    return {
      fieldValue: get(this.vue, path),
      fieldName: path[path.length - 1],
      form: get(this.vue, path.slice(0, path.length - 1))
    }
  }
}

<template>
  <div>
    <el-dialog
      title="Wybierz zdjęcie"
      append-to-body
      class="images-dialog"
      width="80vw"
      :visible.sync="dialogFormVisible"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="Wybierz" name="select">
          <select-tab
            ref="selectTab"
            @submit="submitImage"
            :extension="extension"
          />
        </el-tab-pane>
        <el-tab-pane label="Prześlij" name="upload">
          <upload-tab
            ref="uploadTab"
            @submit="uploadImage"
            :extension="extension"
          />
          <el-progress v-show="uploadProgress" :percentage="uploadProgress" />
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelForm()">Anuluj</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import UploadTab from './UploadTab'
import SelectTab from './SelectTab'
import { Image } from '@/api'

export default {
  components: {
    SelectTab,
    UploadTab
  },
  props: ['extension'],
  data() {
    return {
      activeName: 'select',
      dialogFormVisible: false,
      uploadProgress: 0
    }
  },
  methods: {
    uploadImage(image) {
      try {
        Image.save({ file: image }, this.onUploadProgress)
          .then(response => {
            this.submitImage(response.data.id)
            this.$refs.selectTab.fetchData()
          })
          .catch(error => {
            this.uploadError(error)
          })
      } catch (e) {
        console.log('catch')
        console.log(e)
      }
    },
    onUploadProgress(progressEvent) {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      this.uploadProgress = parseInt(progress)
    },
    submitImage(image) {
      this.$emit('submit', image)
      this.dialogFormVisible = false
    },
    openDialog() {
      this.dialogFormVisible = true
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.$emit('cancel')
    },

    uploadError(error) {
      let message = 'Wystąpił problem podczas wysyłania pliku.'

      if (error.response.status == 413) {
        message = 'Wgrywane zdjęcie ma za duży rozmiar.'
      }

      this.$refs.uploadTab.clear()

      this.$message({
        message: message,
        type: 'error'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.images-dialog {
  z-index: 775666 !important;
}
</style>

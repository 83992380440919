import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ disabled, type = 'text', help, ...args }) {
    super(args)
    const child = this.getComponentWithVModel('el-input')
    child.attrs.type = type
    child.attrs.disabled = disabled
    this.children = [child]
    if (help) {
      this.children.push({
        component: 'el-alert',
        attrs: {
          showIcon: true,
          closable: false,
          title: help
        }
      })
    }
  }
}

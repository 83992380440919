<template>
  <div>
    <el-select v-model="value.tag" clearable placeholder="Wybierz tag">
      <el-option
        v-for="option in availableTagOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { ArticleTag, Widget } from '@/api'

export default {
  data() {
    return {
      tags: [],
      tagOptions: [],
      tagsInWidget: []
    }
  },
  props: {
    value: { type: Object, required: false },
    widgets: { type: Array, required: false },
    errors: {
      type: [Object, String, Function],
      default: () => {}
    }
  },
  mounted() {
    this.fetchTags()
  },
  methods: {
    fetchTags() {
      const params = { page_size: 'max' }
      ArticleTag.fetch({}, { params }).then(res => {
        this.tagOptions = res.data.results
      })
    },
    fetchWidgetDetails(widgetId) {
      const lookupParams = {
        id: widgetId
      }
      Widget.get(lookupParams)
        .then(response => {
          this.tagsInWidget = response.data.tags
        })
        .finally(() => {})
    }
  },
  computed: {
    getContentWidget() {
      return this.widgets.find(item => item.model === 'contentwidget')
    },
    availableTagOptions() {
      let data = this.tagOptions.filter(item =>
        this.tagsInWidget.includes(item.id)
      )

      return data.map(item => ({
        label: item.slug,
        value: item.slug
      }))
    }
  },
  watch: {
    widgets: {
      immediate: true,
      handler: function (value) {
        const contentWidget = this.getContentWidget
        if (contentWidget) {
          this.fetchWidgetDetails(contentWidget.id)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>

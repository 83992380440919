<script>
import { cloneDeep, forOwn } from 'lodash'

export default {
  data() {
    return {
      settingInitialParams: true
    }
  },
  created() {
    this.setInitialFiltersFromQueryParams()
  },
  methods: {
    setInitialFiltersFromQueryParams() {
      const parseIntIfInt = val => (/^\d+$/.test(val) ? parseInt(val) : val)
      const params = cloneDeep(this.$route.query)
      forOwn(params, function (value, key) {
        params[key] = Array.isArray(value)
          ? value.map(parseIntIfInt)
          : parseIntIfInt(value)
      })
      const page = params['page'] || 1
      delete params['page']
      this.page = page
      this.filters.selected = params
      this.$nextTick(() => {
        this.settingInitialParams = false
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="selected-box" v-if="selectedRows.length">
      <el-card class="selected-box__card">
        <strong>Zaznaczone ID:<br /></strong>
        <span :key="index" v-for="(selectedRow, index) in selectedRows"
          >{{ selectedRow.id }},
        </span>
      </el-card>
    </div>
    <el-row :gutter="30">
      <el-col :span="6" class="sidebar">
        <router-link :to="addRoute" v-if="addRoute">
          <el-button type="primary" class="fluid">
            Dodaj {{ verboseName }} <i class="el-icon-plus"></i>
          </el-button>
        </router-link>
        <el-button v-if="exportButton" @click="exportButton">
          Eksportuj do CSV
        </el-button>
        <ListFilterComponent
          @filtersChanged="filtersChanged"
          v-loading="filtersLoading"
          :selectedFilters.sync="filters.selected"
          :listFilters="filters.available"
        >
        </ListFilterComponent>
        <div>Wyświetlone rekordy: {{ count }}</div>
      </el-col>
      <el-col class="box" :span="18">
        <div class="list__header">
          <p class="list__title">
            {{ getRouteName($route) }}
          </p>
          <search-list-form
            v-model="filters.selected.search"
            @submit="debouncedFetchData"
            v-if="includeSearchFilter"
          />
        </div>
        <el-row class="header__actions">
          <el-col>
            <component-compiler
              v-for="(component, i) in headerActions()"
              class="margin-right-15"
              :content="component"
              :key="`header-action-${i}`"
            />
          </el-col>
        </el-row>
        <el-table
          :data="items"
          ref="table"
          @sort-change="sortTable"
          highlight-current-row
          v-loading="listLoading"
          class="list__table"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column v-if="allowRowSelection" type="selection" width="55">
          </el-table-column>
          <component
            v-for="(column, i) in columns"
            :is="column.component"
            :field="column"
            :key="i"
          />
          <el-table-column
            label="akcje"
            width="150"
            class="pull-right"
            class-name="actions"
          >
            <template slot-scope="scope" class="pull-right">
              <component-compiler
                v-for="(component, i) in actions(scope)"
                :content="component"
                :key="i"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="24" class="list__pagination">
            <pagination
              layout="prev, pager, next"
              @page-change="handlePageChange"
              :current-page="page"
              :total-pages="Math.ceil(count / pageSize)"
            >
            </pagination>
          </el-col>
        </el-row>
        <el-row>
          <el-row>
            <el-col>
              <component-compiler
                v-for="(component, i) in footerActions()"
                class="margin-right-15"
                :content="component"
                :key="`list-action-${i}`"
              />
            </el-col>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { clone, debounce, flatten, get } from 'lodash'
import moment from 'moment'
import ListFilterComponent from '@/crudBases/list/ListFilterComponent'
import ImageColumn from './Columns/ImageColumn'
import BooleanColumn from './Columns/BooleanColumn'
import PropColumn from './Columns/PropColumn'
import ComponentCompiler from '@/components/ComponentCompiler'
import Pagination from '@/components/Pagination'
import FiltersInURLMixin from './FiltersInURLMixin'
import AudioColumn from '@/crudBases/list/Columns/AudioColumn'
import ArticlesColumn from '@/crudBases/list/Columns/ArticlesColumn'
import OrderColumn from '@/crudBases/list/Columns/OrderColumn'
import InputColumn from "@/crudBases/list/Columns/InputColumn";

export default {
  mixins: [FiltersInURLMixin],
  data() {
    return {
      items: [],
      count: 0,
      page: 1,
      selectedRowsPerPage: {},
      pageSize: 100,
      // fields to override below
      lookupKwarg: 'id',
      allowRowSelection: false,
      filters: {
        available: [],
        selected: {
          search: ''
        }
      },
      includeSearchFilter: true,
      listLoading: false,
      filtersLoading: false,
      editRouteName: null,
      addRoute: null,
      exportButton: null,
      verboseName: null,
      verboseNamePlural: null,
      listFields: [],
      reinitializeAfterQueryParamChange: false
    }
  },
  methods: {
    handlePageChange(page) {
      if (this.reinitializeAfterQueryParamChange) {
        this.items = []
      }
      this.page = page
      this.updateQueryParam()
      this.fetchData()
    },
    filtersChanged() {
      if (this.settingInitialParams) {
        return
      }
      if (this.reinitializeAfterQueryParamChange) {
        this.items = []
        if (!this.listLoading) {
          this.listLoading = true
        }
      }

      this.page = 1
      this.debouncedFetchData()
    },
    debouncedFetchData: debounce(function () {
      this.updateQueryParam()
      this.fetchData()
    }, 300),
    fetchData() {
      let params = {
        page: this.page,
        ...this.filters.selected
      }

      console.log('params', params)
      this.listLoading = true
      const rowsToSelect = clone(this.selectedRowsPerPage[this.page])
      this.service
        .fetch({}, { params })
        .then(response => {
          this.count = response.data.count
          this.pageSize = response.data.page_size
          this.items = response.data.results
          if (rowsToSelect) {
            this.$nextTick(() => {
              this.selectRows(rowsToSelect)
            })
          }
          // this.$refs.table.toggleRowSelection(this.items[0])
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$message({
              message: 'Nie masz uprawnien do danych zasobów',
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    async handleDelete(index, row) {
      await this.$confirm(
        this.$i18n.t('deleteConfirmation'),
        this.$i18n.t('delete'),
        {
          type: 'warning'
        }
      )

      this.listLoading = true
      try {
        await this.service.delete(this.getDetailsRouteParams(row))
        this.$message({
          message: 'Element został usunięty',
          type: 'success'
        })
      } catch (error) {
        let message = 'Wystąpił bład podczas usuwania elementu.'
        if (error.response.data.non_field_errors) {
          message = `${error.response.data.non_field_errors}`
        }
        this.$message({
          message: message,
          type: 'error'
        })
      }

      this.listLoading = false
      if (this.reinitializeAfterQueryParamChange) {
        this.items = []
      }
      this.fetchData()
    },
    async handleUpdate(index, row) {
      this.listLoading = true
      try {
        await this.service.patch(this.getDetailsRouteParams(row), row)
        this.$message({
          message: 'Element został zaktualizowany',
          type: 'success'
        })
      } catch (error) {
        let message = 'Wystąpił bład podczas aktualizacji elementu.'
        if (error.response.data.non_field_errors) {
          message = `${error.response.data.non_field_errors}`
        }
        this.$message({
          message: message,
          type: 'error'
        })
      } finally {
        this.listLoading = false
        if (this.reinitializeAfterQueryParamChange) {
          this.items = []
        }
        this.fetchData()
      }
    },
    handleSelectionChange(val) {
      this.$set(this.selectedRowsPerPage, this.page, val)
    },
    editIcon() {
      return 'fa fa-edit'
    },
    actions(scope) {
      return [
        {
          component: 'router-link',
          condition: this.editRouteName,
          attrs: {
            to: this.getEditRoute(scope.row)
          },
          children: [this.getButtonComponent({ icon: this.editIcon() })]
        },
        this.getButtonComponent({
          icon: 'el-icon-delete',
          onClick: () => this.handleDelete(scope.$index, scope.row),
          type: 'danger',
          condition: this.removeButtonCondition(scope.row)
        }),
        ...this.additionalActions(scope)
      ]
    },
    additionalActions(scope) {
      return []
    },
    getEditRoute(row) {
      return {
        name: this.editRouteName,
        params: this.getDetailsRouteParams(row),
        query: this.$route.query
      }
    },
    getDetailsRouteParams(instance) {
      const params = {}
      params[this.lookupKwarg] = instance[this.lookupKwarg]
      return params
    },
    sortTable(params) {
      if (params.prop == null) {
        delete this.filters.selected.ordering
      } else {
        const sign = params.order === 'descending' ? '-' : ''
        this.filters.selected.ordering = `${sign}${params.prop}`
      }
      this.fetchData()
    },
    headerActions() {},
    footerActions() {},
    updateQueryParam() {
      const queryParamsToPush = { ...this.filters.selected, page: this.page }
      if (!queryParamsToPush.search) {
        queryParamsToPush.search = undefined
      }
      this.$router.push({ query: queryParamsToPush })
    },
    selectRows(rows) {
      rows.forEach(row => {
        const rowFromData = this.items.find(item => item.id === row.id)
        this.$refs.table.toggleRowSelection(rowFromData, true)
      })
    },
    removeButtonCondition(instance) {
      return true
    },
    setListAsLoading() {
      this.listLoading = true
    }
  },
  components: {
    Pagination,
    ListFilterComponent,
    ImageColumn,
    BooleanColumn,
    PropColumn,
    AudioColumn,
    ArticlesColumn,
    OrderColumn,
    ComponentCompiler,
    InputColumn,
  },
  mounted() {
    this.fetchData()
    if (this.service.logListViewed !== undefined) {
      this.service.logListViewed()
    }
  },
  watch: {
    'filters.selected': {
      deep: true,
      handler() {
        this.filtersChanged()
      }
    }
  },
  computed: {
    service() {
      return null
    },
    isListLoading() {
      return this.listLoading
    },
    columns() {
      const components = {
        image: 'ImageColumn',
        boolean: 'BooleanColumn',
        audio: 'AudioColumn',
        articles: 'ArticlesColumn',
        order: 'OrderColumn',
        input: 'InputColumn',
      }
      return this.listFields.map(field => ({
        ...field,
        component: components[field.type] || 'PropColumn'
      }))
    },
    selectedRows() {
      return flatten(Object.values(this.selectedRowsPerPage))
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .footer-action {
  margin-right: 15px;
}

::v-deep .header-action {
  margin-right: 15px;
}

.header__actions {
  margin-top: -30px;
  margin-bottom: 30px;
}

::v-deep .actions .cell {
  display: flex;
  flex-wrap: wrap;
}

.selected-box {
  position: fixed;
  z-index: 1;
  bottom: 20px;
  left: 246px;

  &__card {
    max-width: 400px;
    border-left: 7px solid #2f4050;
  }
}
</style>

<script>
import { Article, ArticleTemplate } from '@/api'
import fields from '@/components/fields/'
import moment from 'moment'
import PageFormBase from '@/crudBases/form/PageFormBase'
import Mixin from '@/views/Articles/Mixin'
import ContentArticleForm from '@/views/Articles/ContentArticleForm'
import { cloneDeep, get } from 'lodash'

export default {
  mixins: [PageFormBase, Mixin],
  data() {
    return {
      form: {
        widgets: [],
        content_article: {
          author_label: 'autor'
        }
      },
      redirectRoute: { name: 'Article List' },
      livePreview: true,
      pageType: 'Article'
    }
  },
  created() {
    this.fetchTags()
    this.fetchAvailableArticleTypes()
    if (this.form.publication_date === undefined) {
      this.$set(this.form, 'publication_date', moment().format('YYYY-MM-DD'))
    }
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Select({
          label: 'Typ artykułu',
          modelPath: [this, 'form', 'type'],
          options: this.availableTypes
        }),
        new fields.Input({
          label: 'Tytuł',
          required: true,
          modelPath: [this, 'form', 'title']
        }),
        {
          component: ContentArticleForm,
          listeners: {
            input: value => this.$set(this.form, 'content_article', value)
          },
          attrs: {
            value: this.form.content_article,
            errors: (this.errors && this.errors.content_article) || []
          }
        },
        new fields.Select({
          label: 'Tagi',
          modelPath: [this, 'form', 'tags'],
          multiple: true,
          filterable: true,
          allowCreate: true,
          options: this.tagOptions
        }),
        new fields.Date({
          label: 'Data publikacji',
          type: 'date',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          modelPath: [this, 'form', 'publication_date'],
          required: true
        }),
        new fields.ReadOnly({
          label: 'Data przed zmianą',
          modelPath: [this, 'form', 'deprecated_publication_date'],
          required: false,
          hideFormItem: !get(this.form, 'deprecated_publication_date')
        }),
        new fields.Switch({
          label: 'Ukryj',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    },
    dataHasBeenFetchedCallback(data) {
      if (data.content_article === null) {
        data.content_article = {}
      }
      return data
    },
    fetchDataCallback() {
      if (!(this.lookupParams || this.singleton)) {
        this.loading = true
        this.templateService
          .get()
          .then(response => {
            const templateData = { ...response.data }
            delete templateData.id
            this.form = { ...this.form, ...templateData }
            this.formCopy = cloneDeep(this.form)
            this.$message({
              message: 'Załadowano wzór',
              type: 'success'
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  computed: {
    service() {
      return Article
    },
    templateService() {
      return ArticleTemplate
    }
    // customLabel() {
    //   if (this.form.type) {
    //     const label = this.availableTypes.find(v => v.value === this.form.type)
    //     if (label) {
    //       return `Edytuj ${label.label}`
    //     }
    //   }
    //   return null
    // }
  }
}
</script>

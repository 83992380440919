<script>
import { Article } from '@/api'
import fields from '@/components/fields/'
import PageFormBase from '@/crudBases/form/PageFormBase'

export default {
  extends: PageFormBase,
  data() {
    return {
      form: {
        name: 'sample',
        image: 12,
        widgets: []
      },
      redirectRoute: { name: 'Pages List' }
    }
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'name']
        }),
        new fields.WYSIWYG({
          label: 'Opis',
          modelPath: [this, 'form', 'lead'],
          required: true,
          type: 'emptyMenuBarInit'
        }),
        new fields.Image({
          label: 'Zdjęcie',
          modelPath: [this, 'form', 'image']
        })
      ]
    }
  },
  computed: {
    service() {
      return Article
    }
  }
}
</script>

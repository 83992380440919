<script>
import { Catalog } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Catalog',
      addRoute: { name: 'Add Catalog' },
      verboseName: 'Katalog',
      verboseNamePlural: 'Katalogi',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name', label: 'Nazwa' }
      ]
    }
  },
  computed: {
    service() {
      return Catalog
    }
  }
}
</script>

<script>
import { VimeoVideo } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'

export default {
  extends: FormMixin,
  data() {
    return {
      form: {},
      redirectRoute: { name: 'VimeoVideo List' }
    }
  },
  computed: {
    service() {
      return VimeoVideo
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Tytuł filmu',
          modelPath: [this, 'form', 'title']
        }),
        new fields.Input({
          label: 'Adres URL filmu',
          help: 'przykład: https://vimeo.com/524286227',
          modelPath: [this, 'form', 'url']
        }),
        new fields.Input({
          disabled: true,
          label: 'ID filmu',
          modelPath: [this, 'form', 'video_id'],
          help:
            'to pole uzupełnia się automatycznie na podstawie adresu URL filmu'
        })
      ]
    }
  },
  watch: {
    'form.url': {
      handler(value) {
        let val = ''

        const parsed = value.match(/([0-9]+)/gm)

        if (parsed && parsed.length) {
          val = parsed[0]
        }

        this.form.video_id = val
      }
    }
  }
}
</script>

<template>
  <section class="pagination">
    <div class="pagination__inner">
      <div
        :class="{ 'arrow--hidden': currentPage <= 1 }"
        class="arrow"
        @click="goToPage(currentPage - 1)"
      >
        <i class="el-icon el-icon-arrow-left" />
      </div>
      <div
        v-for="page in pages"
        :key="page"
        :class="{ 'page--current': page === currentPage, 'page--more': !page }"
        class="page"
        @click="goToPage(page)"
      >
        {{ page ? page : '...' }}
      </div>
      <div
        :class="{ 'arrow--hidden': currentPage >= totalPages }"
        class="arrow"
        @click="goToPage(currentPage + 1)"
      >
        <i class="el-icon el-icon-arrow-right" />
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages() {
      return this.getPaginationRange(this.currentPage, this.totalPages)
    }
  },
  methods: {
    goToPage(page) {
      if (!page) return
      this.$emit('page-change', page)
    },
    getPaginationRange(current, final) {
      if (final <= 5) {
        return _.range(1, final + 1)
      }

      // We always include the first two pages, last two pages, and
      // two pages either side of the current page.
      let included = new Set([1, current - 1, current, current + 1, final])

      // If the break would only exclude a single page number then we
      // may as well include the page number instead of the break.
      if (current <= 4) {
        included.add(2)
        included.add(3)
      }
      if (current >= final - 3) {
        included.add(final - 1)
        included.add(final - 2)
      }

      // Now sort the page numbers and drop anything outside the limits.
      included = _.sortBy(Array.from(included)).filter(
        idx => idx > 0 && idx <= final
      )

      // Finally insert any `...` breaks
      if (current > 4) {
        included.splice(1, 0, null)
      }
      if (current < final - 3) {
        included.splice(included.length - 1, 0, null)
      }
      return included
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__inner {
    display: flex;
    align-items: center;

    .page {
      font-size: 14px;
      margin: 0 10px;
      cursor: pointer;
      font-weight: 700;
      transition: color 0.4s;

      &--current {
        color: #409eff;
      }

      &:hover {
        color: #409eff;
      }

      &--more {
        cursor: default;
      }
    }

    .arrow {
      font-size: 14px;
      margin: 0 4px;
      cursor: pointer;

      &:hover {
        color: #409eff;
      }

      &--hidden {
        opacity: 0;
        visibility: hidden;
        cursor: default;
      }
    }
  }
}
</style>

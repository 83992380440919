<template>
  <div>
    <div v-if="is_legacy_only(widget)" class="info warning">
      <p class="icon">⚠️</p>
      Vue2 compatible only
    </div>
    <div v-if="is_modern_only(widget)" class="info modern">
      <p class="icon">🚀</p>
      Vue3 compatible only
    </div>
    <div v-if="fully_compatible(widget)" class="info"><p class="icon">👨🏾‍🤝‍👨🏻</p> Vue3 and Vue2 compatible</div>
  </div>
</template>

<script setup>
import {onMounted} from "vue";

const props = defineProps({
  widget: String
});

const fully_compatible = (widget) => {
  return modern.includes(widget) && legacy.includes(widget);
};

const is_modern_only = (widget) => {
  return modern.includes(widget) && !legacy.includes(widget);
};

const is_legacy_only = (widget) => {
  return legacy.includes(widget) && !modern.includes(widget);
};

const modern = [
  'BannerContactWidget',
  'BannerWidget',
  'BudgetWidget',
  'ContactWidget',
  'ContentWidget',
  'CTAWidget',
  'DonateWidget',
  'FilesWidget',
  'PeopleListWidget',
  'SimilarArticlesWidget',
  'SimpleButtonWidget',
  'StepWidget',
  'TextLeadWidget',
  'TitleListWidget',
  'TitleLeadWidget',
  'WsPartnerWidget'
];

const legacy = [
  'BannerContactWidget',
  'BannerWidget',
  'BulletedListWithMediaWidget',
  'ContactWidget',
  'ContentWidget',
  'CTAWidget',
  'DynamicFormWidget',
  'FilesWidget',
  'FullVideoWidget',
  'GalleryWidget',
  'HelpInStepsWidget',
  'PartnerWidget',
  'PeopleListWidget',
  'SimilarArticlesWidget',
  'SimpleButtonWidget',
  'StepWidget',
  'TextLeadWidget',
  'TextMediaWidget',
  'TitleLeadWidget',
  'WsPartnerWidget'
];
</script>

<style scoped>
.info {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
  padding: 0;
}
.icon {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  font-size: 2em;
}
.warning {
  color: indianred;
}
.modern {
  color: darkgreen;
}
</style>
<script>
import { SliderPage } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: PageFormBase,
  data() {
    return {
      livePreview: true,
      redirectRoute: { name: 'Home' },
      selectedTab: 'general',
      form: {
        widgets: [],
        sliders: []
      },
      slugFieldDisabled: true,
      pageType: 'SliderPage'
    }
  },
  created() {},
  methods: {
    getWidgetsTab() {
      return {}
    },
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title'],
          required: true
        }),
        new fields.WYSIWYG({
          label: 'Opis suwaka',
          modelPath: [this, 'form', 'slider_description'],
          required: true,
          type: 'emptyMenuBarInit'
        }),
        new fields.Input({
          label: 'Button "Dalej"',
          modelPath: [this, 'form', 'next_button_label'],
          help: 'Tekst dla niebieskiego buttona "Dalej"'
        }),
        new fields.Input({
          label: 'Button "Pomiń"',
          modelPath: [this, 'form', 'skip_button_label'],
          help: 'Tekst dla buttona "Pomiń"'
        }),
        new fields.Link({
          label: 'Link pomiń',
          required: true,
          nullable: false,
          hideLinkLabel: true,
          modelPath: [this, 'form', 'skip_link']
        }),
        new fields.Header({
          text: 'Suwak'
        }),
        new fields.Formset({
          modelPath: [this, 'form', 'sliders'],
          emptyComponentLabel: 'Brak elemetów do wyświetlenia',
          maxItems: 3,
          hideAddNewButton: true,
          hideRemoveItemButton: true,
          items: this.form.sliders.map((item, i) => {
            return {
              headerLabel: get(item, ['title']) || 'Nowy element',
              fields: [
                new fields.Input({
                  label: 'Tytuł',
                  modelPath: [this, 'form', 'sliders', i, 'title']
                }),
                new fields.Link({
                  label: 'Strona buforowa',
                  required: true,
                  nullable: false,
                  hideLinkLabel: true,
                  modelPath: [this, 'form', 'sliders', i, 'link']
                })
              ]
            }
          })
        }),
        new fields.Switch({
          label: 'Ukryj',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    }
  },
  computed: {
    service() {
      return SliderPage
    }
  }
}
</script>

<template>
  <div>
    <div class="el-form-item__error">
      <span v-for="(error, index) of this.errors" :key="index">{{
        error
      }}</span>
    </div>
    <div class="drop" @dragover.prevent @drop="onDrop">
      <label v-if="!value" class="btn display-inline">
        PRZECIĄGNIJ I UPUŚĆ LUB
        <input type="file" name="value" @change="onChange" :accept="accept" />
      </label>
      <div class="hidden" v-else v-bind:class="{ value: true }">
        <a target="_blank" :href="fileUrl">{{ fileUrl }}</a>
        <el-button
          @click.stop="removeFile"
          class="remove-button"
          v-if="value"
          size="mini"
          icon="el-icon-delete"
          type="danger"
          title="Usuń plik"
        >
        </el-button>
      </div>
    </div>
    <br />
    <div v-if="uploadProgress !== -1">
      <progress
        :value="uploadProgress"
        max="100"
        style="width: 300px"
      ></progress>
      <h5 id="status">Wysłano {{ uploadProgress }}% pliku.</h5>
    </div>
    <br />
    <el-select
      v-model="selectedExisting"
      @change="selectExisting"
      clearable
      placeholder="lub wybierz z istniejących"
    >
      <el-option
        v-for="option in availableVideos"
        :key="option.id"
        :label="onlyFileName(option.file)"
        :value="option.id"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { HomePageVideo } from '@/api'

export default {
  props: ['value', 'accept'],
  data() {
    return {
      selectedVideo: null,
      selectedExisting: null,
      uploadProgress: -1,
      errors: [],
      availableVideos: []
    }
  },
  beforeMount() {
    this.fetchAvailableVideos()
  },
  methods: {
    onlyFileName(value) {
      return value.substring(value.lastIndexOf('/') + 1)
    },
    fetchAvailableVideos() {
      const params = { page_size: 'max' }
      HomePageVideo.fetch({}, { params }).then(response => {
        this.availableVideos = response.data.results
      })
    },
    selectExisting(e) {
      this.$emit('input', e)
    },
    uploadProgressCallback(progress) {
      this.uploadProgress = progress
    },
    sendFile(files) {
      const data = new FormData()
      const file = files[0]
      data.append('file', file, file.name)
      this.errors = []
      this.uploadProgress = -1
      return HomePageVideo.upload(data, this.uploadProgressCallback)
        .then(response => {
          this.uploadSuccess(response)
        })
        .catch(error => {
          this.uploadError(error)
          if (error.response) {
            this.errors = error.response.data.file
          } else {
            this.errors = ['Wystąpił błąd podczas wysyłania pliku.']
          }
        })
    },
    uploadSuccess(response) {
      this.$emit('input', response.data.id)
      this.fetchAvailableVideos()
    },
    uploadError(error) {
      console.error(error, 'error while file uploading')
      this.$message({
        message: 'Wystąpił problem podczas wysyłania pliku.',
        type: 'error'
      })
    },
    removeFile() {
      this.$emit('input', null)
      this.selectedExisting = null
      this.uploadProgress = -1
    },
    onDrop: function (e) {
      e.stopPropagation()
      e.preventDefault()
      let files = e.dataTransfer.files
      this.sendFile(files)
    },
    onChange(e) {
      let files = e.target.files
      this.sendFile(files)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(id) {
        this.selectedExisting = id
        HomePageVideo.get({ id }).then(response => {
          this.selectedVideo = response.data
        })
      }
    }
  },
  computed: {
    fileUrl() {
      return this.selectedVideo && this.selectedVideo.file
    }
  }
}
</script>
<style lang="scss" scoped>
.remove-button {
  position: absolute;
  right: 0;
  top: 0;
}

.drop {
  border: 2px dashed #ccc;
  padding: 40px;
  background-color: #fafafa;
  height: 100%;
  max-width: 600px;
  position: relative;
  width: 100%;
  text-align: center;
}

.drop img {
  max-width: 100%;
}
</style>

<template>
  <form-wrapper @submit="onSubmit">
    <div slot="box" v-loading="loading">
      <component-compiler
        v-for="(component, i) in getFields()"
        :content="component"
        :key="`form-${i}`"
      />
    </div>
    <form-nav
      :loading="loading"
      :hideNavbarButtons="hideNavbarButtons"
      :submitDisabled="submitDisabled"
      :submitHidden="submitHidden"
      slot="navbar"
      @submit="onSubmit"
      :redirectRoute="redirectRoute"
    >
      <component-compiler
        v-for="(component, i) in getNavButtons()"
        :content="component"
        :key="`form-${i}`"
      />
    </form-nav>
  </form-wrapper>
</template>
<script>
import FormNav from './FormNav'
import FormWrapper from './FormWrapper'
import { cloneDeep, isEqual } from 'lodash'
import ComponentCompiler from '@/components/ComponentCompiler'

export default {
  data() {
    return {
      loading: false,
      successRedirect: false,
      form: {},
      formCopy: {},
      errors: {},
      redirectRoute: null, // To extend
      singleton: false,
      hideNavbarButtons: false,
      submitDisabled: false,
      submitHidden: false
    }
  },
  props: {
    id: {
      type: [Number, String],
      required: false
    },
    slug: {
      type: String,
      required: false
    },
    inModal: {
      // used as form in dialog
      type: Boolean,
      default: false
    }
  },
  beforeDestroy() {
    window.onbeforeunload = () => {}
  },
  mounted() {
    this.fetchData()
    this.prepareFormCopyToPreventChangesLost()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.successRedirect && !isEqual(this.formCopy, this.form)) {
      this.$confirm('Czy na pewno chcesz opuścić stronę bez zapisania?')
        .then(response => {
          next()
        })
        .catch(_ => {
          next(false)
        })
    } else {
      next()
    }
  },
  computed: {
    service() {
      return null // To extend
    },
    lookupParams() {
      if (this.id) {
        return { id: this.id }
      } else if (this.slug) {
        return { slug: this.slug }
      }
      return null
    }
  },
  components: {
    ComponentCompiler,
    FormNav,
    FormWrapper
  },
  methods: {
    getNavButtons() {},
    getFields() {},
    prepareFormCopyToPreventChangesLost() {
      this.formCopy = cloneDeep(this.form)
      window.onbeforeunload = () => {
        if (isEqual(this.formCopy, this.form)) {
          return null
        } else {
          return true
        }
      }
    },
    dataHasBeenFetchedCallback(data) {
      return data
    },
    fetchData() {
      if (this.lookupParams || this.singleton) {
        this.loading = true
        this.service
          .get(this.lookupParams)
          .then(response => {
            this.form = this.dataHasBeenFetchedCallback(response.data)
            this.instanceFetchCallback()
            this.formCopy = cloneDeep(this.form)
          })
          .finally(() => {
            this.loading = false
          })
      }
      this.fetchDataCallback()
    },
    fetchDataCallback() {},
    instanceFetchCallback() {
      this.prepareFormCopyToPreventChangesLost()
    },
    onSubmit() {
      this.loading = true
      this.errors = {}
      const id = this.lookupParams
      const payload = this.getSavePayload()
      const service =
        id || this.singleton
          ? this.service.update(this.lookupParams, payload)
          : this.service.save(payload)
      service
        .then(response => {
          this.successRedirect = true
          this.successHandler(response)
        })
        .catch(this.errorHandler)
    },
    getSavePayload() {
      const payload = { ...this.form }
      return this.getPreparedPayload(payload)
    },
    getPreparedPayload(payload) {
      return payload
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  margin-bottom: 30px;
}
</style>

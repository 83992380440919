<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { Article, ArticleTag } from '@/api'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['links']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Formset({
              modelPath: [this, 'form', 'links'],
              maxItems: 3,
              addButtonLabel: 'Dodaj nowy CTA',
              items: this.form.links.map((item, i) => ({
                headerLabel: get(item, ['label']),
                fields: [
                  new fields.Link({
                    label: 'Tekst',
                    labelPlaceholder: 'Tekst CTA',
                    required: true,
                    modelPath: [this, 'form', 'links', i]
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

<script>
import { ImportSimpleCastAudio } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { get } from 'lodash'
import ElementsToDeleteForm from '@/views/SimpleCastAudio/ElementsToDeleteForm'
import SimpleCastFeedForm from '@/views/SimpleCastAudio/SimpleCastFeedForm'

export default {
  extends: FormMixin,
  data() {
    return {
      form: {},
      redirectRoute: { name: 'SimpleCastAudio List' },
      importData: null,
      legendText:
        '"SimpleCastAudio JUŻ ISTNIEJE" - oznacza, że audio zostało już wcześniej zaimportowane i jeżeli wystąpiły zmiany to zostało zaktualizowane',
      legendText2:
        '"ZAIMPORTOWANO" - oznacza, że podcast został pomyślnie zaimportowany'
    }
  },
  computed: {
    service() {
      return ImportSimpleCastAudio
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          condition: !this.importData && !this.loading,
          label: 'Adres URL do pliku RSS',
          modelPath: [this, 'form', 'url']
        }),
        new fields.ReadOnly({
          condition: !this.importData && this.loading,
          label: 'Adres URL do pliku RSS',
          modelPath: [this, 'form', 'url']
        }),
        this.getButtonComponent({
          label: 'Wróć do listy SimpleCastAudio',
          onClick: () => {
            this.$router.push({ name: 'SimpleCastAudio List' })
          },
          condition: this.importData
        }),
        new fields.Header({
          text: 'Szczegóły importu',
          type: 'h2',
          condition: !!this.importData
        }),
        {
          component: SimpleCastFeedForm,
          condition: !!this.importData && !!this.importData.simple_cast_feed,
          attrs: {
            value: (this.importData && this.importData.simple_cast_feed) || {},
            errors: []
          }
        },
        {
          component: ElementsToDeleteForm,
          condition:
            !!this.importData &&
            !!this.importData.objects_to_delete &&
            this.importData.objects_to_delete.length,
          // listeners: {
          //   input: value => this.$set(this.importData, 'elements_to_delete', value)
          // },
          attrs: {
            value: (this.importData && this.importData.objects_to_delete) || [],
            errors: []
          }
        },
        new fields.Header({
          text: 'Lista zaimportowanych podcastów:',
          type: 'h3',
          condition: !!this.importData
        }),
        new fields.ReadOnly({
          condition: !!this.importData,
          label: 'Legenda',
          modelPath: [this, 'legendText'],
          type: 'warning',
          showIcon: true
        }),
        new fields.ReadOnly({
          condition: !!this.importData,
          label: '',
          modelPath: [this, 'legendText2'],
          type: 'warning',
          showIcon: true
        }),
        new fields.Formset({
          condition: !!this.importData,
          hideItemButtons: true,
          hideAddNewButton: true,
          modelPath: [this, 'importData', 'items'],
          emptyComponentLabel: 'Brak zaimportowanych SimpleCastAudio',
          items: (this.importData ? this.importData.items : []).map(
            (item, i) => {
              return {
                headerLabel:
                  (item.created
                    ? 'ZAIMPORTOWANO'
                    : 'SimpleCastAudio JUŻ ISTNIEJE') +
                  ' - ' +
                  get(item, ['data', 'title']),
                fields: [
                  new fields.ReadOnly({
                    label: 'Zewnętrzne ID',
                    modelPath: [this, 'importData', 'items', i, 'data', 'guid']
                  }),
                  new fields.ReadOnly({
                    label: 'Tytuł',
                    modelPath: [this, 'importData', 'items', i, 'data', 'title']
                  }),
                  new fields.ReadOnly({
                    label: 'Adres URL do pliku MP3',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'enclosure_url'
                    ]
                  }),
                  new fields.AudioReadOnly({
                    label: '',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'enclosure_url'
                    ],
                    type: item.data.enclosure_type
                  }),
                  new fields.ReadOnly({
                    label: 'Opis',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'description'
                    ]
                  }),
                  new fields.ReadOnly({
                    label: 'Data publikacji',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'pub_date'
                    ]
                  }),
                  new fields.ReadOnly({
                    label: 'Czas audio',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'duration'
                    ]
                  }),
                  new fields.ReadOnly({
                    label: 'Link',
                    modelPath: [this, 'importData', 'items', i, 'data', 'link']
                  }),
                  new fields.ReadOnly({
                    label: 'Autor',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'author'
                    ]
                  }),
                  new fields.ReadOnly({
                    label: 'Rozmiar pliku w bajtach',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'enclosure_length'
                    ]
                  }),
                  new fields.ReadOnly({
                    label: 'Mime pliku',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'enclosure_type'
                    ]
                  }),
                  new fields.ReadOnly({
                    label: 'Źródło RSS',
                    modelPath: [
                      this,
                      'importData',
                      'items',
                      i,
                      'data',
                      'feed_url'
                    ]
                  })
                ]
              }
            }
          )
        })
      ]
    },
    successHandler(response) {
      this.hideNavbarButtons = true
      this.loading = false
      this.importData = response.data

      this.$set(this, 'errors', {})

      const message = 'Import zakończony sukcesem'

      this.$message({
        message: message,
        type: 'success'
      })
    }
  }
}
</script>

<template>
  <div>
    <component-compiler
      v-for="(component, i) in getFields()"
      :content="component"
      :key="`form-${i}`"
    />
    <br />
  </div>
</template>
<script>
import FieldsHelper from '@/mixins/form/FieldsHelper'
import fields from '@/components/fields/'
import ComponentCompiler from '@/components/ComponentCompiler'
import { get } from 'lodash'

export default {
  mixins: [FieldsHelper],
  components: {
    ComponentCompiler
  },
  methods: {
    getFields() {
      return [
        new fields.Header({
          text: 'Informacje ogólne:',
          type: 'h3'
        }),
        new fields.ReadOnly({
          label: 'Źródło RSS',
          modelPath: [this, 'form', 'feed_url']
        }),
        new fields.ReadOnly({
          label: 'Opis',
          modelPath: [this, 'form', 'description']
        }),
        new fields.ReadOnly({
          label: 'Copyright',
          modelPath: [this, 'form', 'copyright']
        }),
        new fields.ReadOnly({
          label: 'Język',
          modelPath: [this, 'form', 'language']
        }),
        new fields.ReadOnly({
          label: 'Data publikacji',
          modelPath: [this, 'form', 'pub_date']
        }),
        new fields.ReadOnly({
          label: 'Data ostatnich zmian',
          modelPath: [this, 'form', 'last_build_date']
        }),
        new fields.ReadOnly({
          label: 'Tytuł zdjęcia',
          modelPath: [this, 'form', 'image_title']
        }),
        new fields.ReadOnly({
          label: 'Link w zdjęciu',
          modelPath: [this, 'form', 'image_link']
        }),
        new fields.ReadOnly({
          label: 'Link do zdjęcia',
          modelPath: [this, 'form', 'image_url']
        }),
        new fields.ReadOnly({
          label: 'Link',
          modelPath: [this, 'form', 'link']
        })
      ]
    }
  }
}
</script>

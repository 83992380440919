<script>
export default {
  data() {
    return {
      types: [],
      usedTags: [],
      filters: {
        available: [
          {
            name: 'Typ',
            query_param: 'type',
            options: [
              {
                label: 'SINGLE',
                value: 'SINGLE'
              },
              {
                label: 'RECURRING',
                value: 'RECURRING'
              }
            ],
            multiple: false
          },
          {
            name: 'Status',
            query_param: 'status',
            options: [
              {
                label: 'PENDING',
                value: 'PENDING'
              },
              {
                label: 'WAITING_FOR_CONFIRMATION',
                value: 'WAITING_FOR_CONFIRMATION'
              },
              {
                label: 'COMPLETED',
                value: 'COMPLETED'
              },
              {
                label: 'CANCELED',
                value: 'CANCELED'
              },
            ],
          },
          {
            name: 'Zgoda na regulamin',
            query_param: 'tos_consent',
            options: [
              {
                label: 'Tak',
                value: true
              },
              {
                label: 'Nie',
                value: false
              }
            ],
          },
          {
            name: 'Zgoda na marketing',
            query_param: 'marketing_consent',
            options: [
              {
                label: 'Tak',
                value: true
              },
              {
                label: 'Nie',
                value: false
              }
            ],
          }
        ],
        selected: {}
      }
    }
  },
}
</script>

import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ type = 'info', showIcon = false, closable = false, ...args }) {
    super(args)
    this.children = [
      {
        component: 'el-alert',
        attrs: {
          showIcon: showIcon,
          closable: closable,
          type: type,
          title: this.formData.fieldValue
            ? this.formData.fieldValue.toString()
            : ''
        }
      }
    ]
  }
}

<script>
import { User } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {},
      redirectRoute: { name: 'User List' }
    }
  },
  computed: {
    service() {
      return User
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Email',
          type: 'email',
          required: true,
          modelPath: [this, 'form', 'email']
        }),
        new fields.Input({
          label: 'Imię',
          modelPath: [this, 'form', 'first_name']
        }),
        new fields.Input({
          label: 'Nazwisko',
          modelPath: [this, 'form', 'last_name']
        }),
        new fields.Input({
          label: 'Hasło',
          required: true,
          type: 'password',
          modelPath: [this, 'form', 'password'],
          condition: !this.form.id
        }),
        new fields.Input({
          label: 'Powtórz hasło',
          required: true,
          type: 'password',
          modelPath: [this, 'form', 're_password'],
          condition: !this.form.id
        }),
        new fields.Switch({
          label: 'Użytkownik aktywny',
          modelPath: [this, 'form', 'is_active']
        }),
        new fields.Switch({
          label: 'Użytkownik ma dostęp do panelu',
          modelPath: [this, 'form', 'is_staff']
        })
      ]
    }
  }
}
</script>

<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { Article, ArticleTag } from '@/api'

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              required: true,
              modelPath: [this, 'form', 'title']
            }),
            new fields.ForeignKey({
              service: Article,
              label: 'Podobne artykuły',
              modelPath: [this, 'form', 'articles'],
              multiple: true,
              labelField: 'title',
              required: true,
              sortable: true,
              multipleLimit: 3
            })
          ]
        }
      ]
    }
  }
}
</script>

<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['donate_amounts']
    }
  },
  methods: {
    isCustom(i) {
      return this.form.donate_amounts[i].custom
    },
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Link({
              label: 'Regulamin - plik',
              modelPath: [this, 'form', 'status_file'],
              nullable: false,
              required: true,
              labelPlaceholder: '--'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'donate_amounts'],
              items: this.form.donate_amounts.map((item, i) => ({
                headerLabel: `Kwota nr ${i + 1}`,
                fields: [
                  new fields.Select({
                    label: 'Typ',
                    modelPath: [this, 'form', 'donate_amounts', i, 'custom'],
                    options: [
                      {
                        label: 'Predefiniowana',
                        value: false
                      },
                      {
                        label: 'Custom',
                        value: true
                      }
                    ]
                  }),
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'donate_amounts', i, 'label'],
                    required: true
                  }),
                  new fields.Input({
                    label: 'Opis',
                    modelPath: [this, 'form', 'donate_amounts', i, 'description'],
                    required: false,
                    hideFormItem: this.isCustom(i)
                  }),
                  new fields.Input({
                    label: 'Kwota',
                    modelPath: [this, 'form', 'donate_amounts', i, 'amount'],
                    required: false,
                    hideFormItem: this.isCustom(i)
                  }),
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>

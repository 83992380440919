<script>
import { ZendeskTask } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import { get, keyBy } from 'lodash'
import FiltersMixin from '@/views/ZendeskTask/FiltersMixin'
import moment from 'moment'

export default {
  mixins: [ListMixin, FiltersMixin],
  data() {
    return {
      editRouteName: 'ZendeskTask Detail',
      verboseName: 'Zendesk - zadanie',
      verboseNamePlural: 'Zendesk - zadania',
      listFields: [
        { prop: 'id', label: 'id' },
        {
          prop: 'first_step',
          label: 'typ zadania',
          formatter: this.getFirstStepLabel
        },
        {
          prop: 'status',
          label: 'status zadania',
          formatter: this.getStatusLabel
        },
        {
          prop: 'current_step_label',
          label: 'Aktualny krok'
        },
        {
          prop: 'created',
          label: 'Data utworzenia',
          formatter: this.formatDate('created')
        },
        {
          prop: 'run_at',
          label: 'Uruchom po',
          formatter: this.formatRunAtDate('run_at')
        }
      ]
    }
  },
  computed: {
    service() {
      return ZendeskTask
    }
  },
  methods: {
    actions(scope) {
      return [
        {
          component: 'router-link',
          attrs: {
            to: this.getEditRoute(scope.row)
          },
          children: [
            this.getButtonComponent({ icon: 'fa fa-edit', title: 'Szczegóły' })
          ]
        },
        ...this.additionalActions(scope)
      ]
    }
  }
}
</script>

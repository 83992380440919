<script>
import {ZendeskTask} from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import {downloadBlobResponse} from "@/common/js/functions";

export default {
  mixins: [ListMixin],
  data() {
    return {
    }
  },
  methods: {
    headerActions() {
      return [
        this.getButtonComponent({
          label: 'Eksportuj wiadomości Zendesk',
          icon: 'el-icon-download',
          onClick: () => {
            this.service.exportReplyMessages()
              .then(response => {
                this.successRedirect = true
                this.successHandler(response)
              })
            .catch(this.errorHandler)
          }
        })
      ]
    },
    successHandler(response) {
      downloadBlobResponse(response)
    }
  },

  computed: {
    service() {
      return ZendeskTask
    }
  }
}
</script>

<script>
import { SiteSettings } from '@/api/'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { cloneDeep } from 'lodash'

export default {
  components: {},
  mixins: [FormMixin],
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.service.get().then(response => {
        this.form = response.data
        this.prepareFormCopyToPreventChangesLost()
      })
    },
    onSubmit() {
      this.loading = true
      const service = this.service.update(this.form)
      service
        .then(this.successHandler)
        .then(() => {
          this.formCopy = cloneDeep(this.form)
        })
        .catch(this.errorHandler)
    },
    getFields() {
      return [
        new fields.Image({
          label: 'Logo',
          modelPath: [this, 'form', 'logo'],
          required: true,
          extension: ['.svg'],
          help: 'Formularz akceptuje pliki o rozszerzeniu svg.'
        }),
        new fields.Image({
          label: 'Logo na telefony',
          modelPath: [this, 'form', 'logo_mobile'],
          required: true,
          extension: ['.svg'],
          help: 'Formularz akceptuje pliki o rozszerzeniu svg.'
        }),
        new fields.Link({
          label: 'Button',
          modelPath: [this, 'form', 'button_link'],
          help: 'Niebieski button przy rozwiń menu',
          required: true
        }),
        new fields.Input({
          label: 'Facebook',
          required: false,
          modelPath: [this, 'form', 'facebook_url']
        }),
        new fields.Input({
          label: 'Instagram',
          required: false,
          modelPath: [this, 'form', 'instagram_url']
        }),
        new fields.Input({
          label: 'Twitter',
          required: false,
          modelPath: [this, 'form', 'twitter_url']
        }),
        new fields.Input({
          label: 'LinkedIn',
          required: false,
          modelPath: [this, 'form', 'linkedin_url']
        }),
        new fields.Switch({
          label: 'Wiadomości włączone',
          modelPath: [this, 'form', 'messages_enabled'],
          help:
            'Jeśli wyłączone to pokaże się baner i nie będzie można wysłać wiadomości.'
        }),
        new fields.Input({
          label: 'Nagłówek baneru',
          required: false,
          modelPath: [this, 'form', 'messages_header']
        }),
        new fields.WYSIWYG({
          label: 'Tekst baneru',
          modelPath: [this, 'form', 'messages_text'],
          required: false
        }),
        new fields.Image({
          label: 'Zdjęcie banneru',
          modelPath: [this, 'form', 'messages_image'],
          required: false,
          help: 'Zdjęcie baneru w rozmiarze 1920x1280'
        })
      ]
    }
  },
  computed: {
    service() {
      return SiteSettings
    }
  }
}
</script>

<template>
  <el-alert
    v-if="pendingRevision"
    title="Kopia robocza"
    show-icon
    :closable="false"
    type="warning"
  >
    <span
      >Edytujesz kopie roboczą, zmiany nie zostaną opublikowane do momentu
      Zatwierdzenia formularza.</span
    >
  </el-alert>
</template>

<script>
export default {
  props: {
    pendingRevision: {
      type: Object,
      required: false
    }
  }
}
</script>

import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ hideLinkLabel, nullable, labelPlaceholder, help, ...args }) {
    super(args)
    this.attrs.error = ''
    this.children = [
      {
        ...this.getComponentWithVModel('audio-link-input'),
        props: {
          hideLinkLabel,
          nullable,
          labelPlaceholder,
          errors: this.getErrorsForPath(this.modelPath)
        }
      }
    ]
    if (help) {
      this.children.push({
        component: 'el-alert',
        attrs: {
          showIcon: true,
          closable: false,
          title: help
        }
      })
    }
  }
}

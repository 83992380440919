<template>
  <blockquote>
    <p>
      e-mail: {{ form.tasks_data['FETCH_EXTERNAL_USER_ID']['input']['email'] }}
    </p>
  </blockquote>
</template>
<script>
export default {
  props: ['form']
}
</script>

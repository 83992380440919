<template>
  <div class="container">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      label-width="0px"
      @submit.prevent.native="onSubmit"
      class="login-container"
    >
      <h3 class="title" v-if="formSubmitted" style="margin-top: 20px">
        Na podany adres e-mail została wysłana wiadomość z linkiem, służacym do
        zalogowania.
      </h3>
      <h3 class="title" v-else>Login</h3>
      <el-form-item prop="email" v-if="!formSubmitted">
        <el-input
          type="text"
          v-model="form.email"
          auto-complete="off"
          placeholder="email"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password" v-if="!formSubmitted">
        <el-input
          type="password"
          v-model="form.password"
          auto-complete="off"
          placeholder="password"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%" v-if="!formSubmitted">
        <button
          type="submit"
          class="el-button el-button--primary"
          style="width: 100%"
          :loading="loading"
        >
          <span>Login</span>
        </button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { Auth, Dashboard } from '@/api'

export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        email: [
          { required: true, message: 'To pole jest wymagane', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'To pole jest wymagane', trigger: 'blur' }
        ]
      },
      checked: true,
      formSubmitted: false
    }
  },
  methods: {
    async submitLoginForm() {
      this.loading = true
      let payload = {
        email: this.form.email,
        password: this.form.password
      }
      this.$store
        .dispatch('auth/login', payload)
        .then(async response => {
          if (response.data.auth_token) {
            // when two factor is disabled
            window.localStorage.setItem(
              'token',
              'Token ' + response.data.auth_token
            )
            const user = await Dashboard.getUser()
            if (user.status !== 200) {
              window.localStorage.removeItem('token')
              throw new Error('User not allowed to access admin panel')
            } else {
              this.redirectOnSuccess()
            }
          }
          this.formSubmitted = true
        })
        .catch(error => {
          let message = 'Wystąpił błąd podczas logowania.'

          if (error.response && error.response.data.non_field_errors) {
            message = `${message} ${error.response.data.non_field_errors}`
          }

          this.$message({
            message: message,
            type: 'error'
          })
        })
      this.loading = false
    },
    redirectOnSuccess() {
      this.$router.push(this.$route.query.next || '/')
    },
    onSubmit(e) {
      this.submitLoginForm()
    }
  },
  created() {
    const token = window.localStorage.getItem('token')
    if (token) {
      // user already logged in
      this.redirectOnSuccess()
    }
    if (this.$route.query.token) {
      // token from email
      const emailToken = this.$route.query.token
      this.$store.dispatch('auth/twoFactor', emailToken).then(() => {
        this.redirectOnSuccess()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #061927;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.login-container {
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;

  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }

  .remember {
    margin: 0px 0px 35px 0px;
  }
}
</style>

<script>
import { VimeoVideo } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit VimeoVideo',
      addRoute: { name: 'Add VimeoVideo' },
      verboseName: 'Vimeo Video',
      verboseNamePlural: 'Vimeo Videos',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'title', label: 'tytuł' },
        { prop: 'video_id', label: 'zewnętrzne id video' },
        { prop: 'url', label: 'url' }
      ]
    }
  },
  computed: {
    service() {
      return VimeoVideo
    }
  }
}
</script>

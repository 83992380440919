<script>
import { cloneDeep } from 'lodash'
import { Revision } from '@/api'

export default {
  data() {
    return {
      pendingRevision: undefined
    }
  },
  computed: {
    isRevision() {
      return true
    }
  },
  methods: {
    instanceFetchCallback() {
      this.fetchAndLoadPendingRevision()
    },
    async fetchAndLoadPendingRevision() {
      await this.fetchPendingRevision()
      if (this.pendingRevision) {
        this.loadRevision(this.pendingRevision)
      }
    },
    fetchPendingRevision() {
      this.loading = true
      return Revision.fetch(
        {},
        {
          params: {
            page: this.form.id,
            is_current: true,
            is_live: false
          }
        }
      )
        .then(res => {
          const pendingRevision = res.data[0]
          this.pendingRevision = pendingRevision
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRevisionsDropdown() {
      return {
        component: 'el-dropdown',
        listeners: {
          click: this.onSubmit,
          command: this.dropdownCommand
        },
        attrs: {
          type: 'primary',
          trigger: 'click',
          'split-button': true
        },
        directives: [
          {
            name: 'loading',
            value: this.loading
          }
        ],
        innerText: 'Zapisz zmiany',
        children: [
          {
            component: 'el-dropdown-menu',
            slot: 'dropdown',
            children: [
              {
                component: 'el-dropdown-item',
                attrs: {
                  command: 'save-revision'
                },
                innerText: 'Zapisz wersję roboczą'
              },
              {
                component: 'el-dropdown-item',
                attrs: {
                  command: 'revisions-list'
                },
                innerText: 'Przeglądaj rewizje'
              }
            ]
          }
        ]
      }
    },
    dropdownCommand(command) {
      if (command === 'revisions-list') {
        this.openRevisionsDialog()
      } else if (command === 'save-revision') {
        this.saveRevision()
      }
    },
    openRevisionsDialog() {
      this.$refs.revisionsDialog.openDialog(this.form.id)
    },
    saveRevision() {
      const revisionService = this.service.revision
      if (!revisionService) {
        throw `Revision service not available for ${this.service}`
      }
      this.loading = true
      revisionService(this.lookupParams, this.getSavePayload())
        .then(res => {
          this.$message({
            message: 'Zapisano wersję roboczą',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadRevision(revision) {
      const formCopy = cloneDeep(this.form)
      try {
        this.$set(this, 'form', revision.admin_form_payload)
        this.$message({
          message: 'Wczytano kopię roboczą.',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: 'Wystąpił błąd poczas wczytywania wersji roboczej.',
          type: 'error'
        })
        this.$set(this, 'form', formCopy)
      }
    }
  }
}
</script>

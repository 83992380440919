<template>
  <el-table-column
    :key="field.prop"
    :sortable="field.sortable ? 'custom' : false"
    :prop="field.prop"
    :label="field.label"
    :width="field.width"
    :formatter="field.formatter"
  />
</template>

<script>
export default {
  props: ['field']
}
</script>

<template>
  <div class="wrapper">
    <el-button
      v-if="!value"
      @click="initForm"
      class="fluid fill-link-button"
      type="primary"
    >
      Uzupełnij odnośnik
    </el-button>
    <div v-else>
      <div class="inline-form-group">
        <el-form-item
          v-show="!hideLinkLabel"
          :label="labelPlaceholder"
          :error="getErrors(errors, ['label'])"
        >
          <el-input v-model="value.label" />
        </el-form-item>
        <el-button
          @click="removeLink"
          v-if="nullable"
          type="warning"
          class="remove-button"
          size="mini"
        >
          Usuń odnośnik
        </el-button>
      </div>
      <div class="inline-form-group">
        <el-form-item label="Typ linku" required :error="anchorErrors">
          <el-select v-model="anchorType" placeholder="Wybierz typ linku">
            <el-option
              v-for="option in anchorOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Otwieraj w nowym oknie" label-width="200px">
          <el-switch v-model="value.target_blank"></el-switch>
        </el-form-item>
      </div>
      <el-form-item
        label="URL"
        required
        v-if="!value.content_type"
        :error="getErrors(errors, ['url'])"
      >
        <el-input v-model="value.url" />
      </el-form-item>
      <div class="inline-form-group" v-if="contentTypeOptions">
        <el-form-item
          v-if="
            (contentTypeOptions.objects &&
              contentTypeOptions.objects.length !== 1) ||
            this.isAttachmentType ||
            this.isFlatPage
          "
          :label="contentTypeOptions.model_name"
          required
          :error="getErrors(errors, ['object_id'])"
        >
          <el-select
            @change="delete value['widget']"
            filterable
            v-model="value.object_id"
            placeholder="Wybierz z listy"
          >
            <el-option
              v-for="option in contentTypeOptions.objects"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Nazwa widgetu"
          v-if="availableWidgets.length > 0"
          v-loading="loadingWidgets"
          :error="getErrors(errors, ['widget'])"
        >
          <span slot="label">
            Nazwa widgetu
            <el-tooltip
              content="Widget, do którego domyślnie przescrolluje się strona po kliknięciu w link."
              placement="bottom"
            >
              <el-button class="widget-info"
                ><i class="fa fa-question-circle"></i
              ></el-button>
            </el-tooltip>
          </span>
          <el-select
            v-model="value.widget"
            clearable
            placeholder="Wybierz widget"
          >
            <el-option
              v-for="option in availableWidgets"
              :key="option.id"
              :label="option.label"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Szczegóły widgetu contentowego"
          v-if="hasContentWidget"
        >
          <span slot="label">
            Tag artykułu
            <el-tooltip
              content="Tag artykułu, który będzie automatycznie zaznaczony po kliknięciu w link"
              placement="bottom"
            >
              <el-button class="widget-info"
                ><i class="fa fa-question-circle"></i
              ></el-button>
            </el-tooltip>
          </span>
          <menu-link-content-widget-input
            :widgets="availableWidgets"
            :value.sync="value.extra_data"
          />
        </el-form-item>
      </div>
      <div v-if="isAttachmentType">
        <el-form-item label="Wgraj nowy załącznik">
          <attachment-input @input="attachmentUploaded" />
        </el-form-item>
      </div>
    </div>
    <div class="el-form-item__error" v-if="typeof errors === 'string'">
      {{ errors }}
    </div>
  </div>
</template>
<script>
import { Link } from '@/api'
import MenuLinkContentWidgetInput from './MenuLinkContentWidgetInput'

export default {
  components: { MenuLinkContentWidgetInput },
  data() {
    return {
      contentTypes: [],
      availableWidgets: [],
      loadingWidgets: false
    }
  },
  props: {
    value: { type: Object, required: false },
    errors: {
      type: [Object, String, Function],
      default: () => {}
    },
    labelPlaceholder: {
      type: String,
      default: 'Tekst hiperłącza'
    },
    nullable: {
      type: Boolean,
      default: true
    },
    hideLinkLabel: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    attachmentUploaded(attachmentId) {
      this.$set(this.value, 'object_id', attachmentId)
      this.fetchData()
    },
    initForm() {
      this.$emit('input', { url: '' })
    },
    removeLink() {
      this.$emit('input', null)
    },
    fetchData() {
      this.$store.dispatch('fetchAvailableLinkOptions')
    },
    setAvailableWidgets(objectId) {
      if (!this.isAttachmentType && objectId) {
        this.loadingWidgets = true
        Link.availableWidgets({
          basepage_pk: objectId
        })
          .then(res => {
            this.availableWidgets = res.data
          })
          .finally(() => {
            this.loadingWidgets = false
          })
      } else {
        this.availableWidgets = []
      }
    }
  },
  computed: {
    anchorErrors() {
      return this.getErrors(this.errors, ['anchor_type'])
    },
    availableLinkOptions() {
      return this.$store.state.availableLinkOptions
    },
    contentTypeOptions() {
      if (!this.value) return
      const selectedContentType = this.value.content_type
      const matchingContentType = option =>
        option.content_type_id === selectedContentType
      return this.availableLinkOptions.find(matchingContentType)
    },
    anchorOptions() {
      const availableContentTypes = this.availableLinkOptions.map(option => ({
        value: option.content_type_id,
        label: option.model_name
      }))
      return [
        ...availableContentTypes,
        {
          label: 'URL',
          value: 'url'
        }
      ]
    },
    anchorType: {
      set(value) {
        if (value === 'url') {
          this.$set(this.value, 'object_id', null)
          this.$set(this.value, 'content_type', null)
        } else {
          this.$set(this.value, 'url', '')
          this.$set(this.value, 'content_type', value)
          this.$set(this.value, 'object_id', null)
          this.$set(this.value, 'extra_data', {})
        }
      },
      get() {
        return this.value.content_type || 'url'
      }
    },
    isAttachmentType() {
      const contentType = this.contentTypeOptions
      return contentType && contentType.model === 'attachment'
    },
    isFlatPage() {
      const contentType = this.contentTypeOptions
      return contentType && contentType.model === 'flatpage'
    },
    hasContentWidget() {
      return !!this.availableWidgets.find(
        item => item.model === 'contentwidget'
      )
    }
  },
  watch: {
    'value.object_id': {
      immediate: true,
      handler: function (value, oldValue) {
        this.setAvailableWidgets(value)
        if (value && oldValue && value !== oldValue) {
          this.value.extra_data = {}
        }
      }
    },
    contentTypeOptions: {
      handler: function (value) {
        if (value && value.objects && value.objects.length === 1) {
          this.$nextTick(() => {
            this.value.object_id = value.objects[0].value
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.widget-info {
  padding: 9px;
}

.remove-button {
  display: inline-block;
  margin: 0 0 22px 11px;
}

.fluid {
  width: 100%;
}

.wrapper {
  background: #f7f7f7;
  padding: 22px;
  border: 1px solid #e2e2e2;
  position: relative;
  margin-bottom: 22px;
}

.fill-link-button {
  margin-bottom: 22px;
}
</style>

<template>
  <div>
    <widgets-dialogs
      ref="widgetDialogs"
      :widgets="form.widgets"
      :errors="errors.widgets"
    />
    <revisions-dialog
      v-if="revisionEnabled"
      ref="revisionsDialog"
      @submit="loadRevision"
    />
    <revisions-alert
      v-if="revisionEnabled"
      :pendingRevision="pendingRevision"
    />
    <form-wrapper @submit="onSubmit">
      <div slot="box" v-loading="loading">
        <component-compiler
          v-for="(component, i) in getFields()"
          :content="component"
          :key="`form-${i}`"
        />
      </div>
      <form-nav :customLabel="customLabel" :loading="loading" slot="navbar">
        <component-compiler
          v-for="(component, i) in getNavButtons()"
          :content="component"
          :key="`nav-button-${i}`"
        />
      </form-nav>
    </form-wrapper>
  </div>
</template>
<script>
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import WidgetsDialogs from '@/components/widgets/WidgetsDialogs'
import LivePreviewMixin from '@/crudBases/form/LivePreviewMixin'
import RevisionsDialog from '@/components/revisions/RevisionsDialog'
import RevisionsMixin from '@/crudBases/form/RevisionsMixin'
import RevisionsAlert from '@/crudBases/form/RevisionsAlert'
import NewPreview from "@/components/NewPreview.vue";

export default {
  extends: FormMixin,
  mixins: [LivePreviewMixin, RevisionsMixin],
  components: {
    WidgetsDialogs,
    RevisionsDialog,
    RevisionsAlert
  },
  data() {
    return {
      selectedTab: 'general',
      form: {
        widgets: []
      },
      slugFieldDisabled: false,
      revisionEnabled: true
    }
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchPreview()
      }
    }
  },
  computed: {
    revisionsAvailable() {
      return this.lookupParams
    },
    customLabel() {
      return null
    }
  },
  methods: {
    getNavButtons() {
      const buttons = [
        {
          component: 'router-link',
          attrs: {
            to: { ...this.redirectRoute, query: this.$route.query }
          },
          condition: this.redirectRoute,
          children: [
            {
              component: 'el-button',
              innerText: 'Anuluj'
            }
          ]
        }
      ]

      if (this.revisionsAvailable) {
        buttons.push(this.getRevisionsDropdown())
      } else {
        buttons.push({
          component: 'el-button',
          attrs: {
            type: 'primary'
          },
          listeners: {
            click: this.onSubmit
          },
          innerText: 'Zatwierdź'
        })
      }

      return buttons
    },
    getFields() {
      return [
        {
          component: 'el-tabs',
          attrs: {
            stretch: true,
            value: this.selectedTab
          },
          listeners: {
            input: value => (this.selectedTab = value)
          },
          children: [
            this.getGeneralTab(),
            this.getWidgetsTab(),
            this.getNewPreviewTab(),
            this.getPreviewTab(),
            this.getSEOTab()
          ]
        }
      ]
    },
    getGeneralTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Ogólne',
          name: 'general'
        },
        children: this.getGeneralFields()
      }
    },
    getNewPreviewTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Podgląd Vue3'
        },
        children: [
          {
            component: NewPreview,
            props: {
              widgets: this.previewContent?.widgets || [],
              slug: this.form.slug,
              title: this.form.title,
              lead: this.form.lead,
              loading: this.previewContentLoading
            }
          }
        ]
      }
    },
    getWidgetsTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Widgety',
        },
        children: [
          new fields.Widgets({ modelPath: [this, 'form', 'widgets'] }),
        ]
      }
    },
    getGeneralFields() {
      return []
    },
    getSEOTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'SEO'
        },
        children: [
          new fields.Input({
            label: 'Slug',
            modelPath: [this, 'form', 'slug'],
            disabled: this.slugFieldDisabled,
            condition: !!this.form.id
          }),
          new fields.Input({
            label: 'Tytuł dla facebooka',
            modelPath: [this, 'form', 'og_title']
          }),
          new fields.Input({
            label: 'Opis dla facebooka',
            modelPath: [this, 'form', 'og_description'],
            type: 'textarea'
          }),
          new fields.Image({
            label: 'Zdjęcie dla facebooka',
            modelPath: [this, 'form', 'og_image']
          }),
          new fields.Input({
            label: 'Tytuł strony dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_title']
          }),
          new fields.Input({
            label: 'Opis dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_description'],
            type: 'textarea'
          })
        ]
      }
    }
  }
}
</script>

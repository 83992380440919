<template>
  <aside class="menu-wrapper menu-hideable">
    <el-menu :default-active="$route.path" class="sidebar" router unique-opened>
      <template v-for="(item, index) in availableRoutes">
        <el-submenu
          :index="index + ''"
          v-if="showSubmenu(item)"
          :key="`${item.path} ${index}`"
        >
          <!-- Menu Item Header -->
          <template slot="title">
            <i :class="item.iconCls"></i>
            <span class="menu__item">{{ getRouteName(item) }}</span>
          </template>
          <!-- Menu Item Children -->
          <el-menu-item
            v-for="child in visibleChildren(item)"
            :index="getChildPath(item.path, child.path)"
            :key="getChildPath(item.path, child.path)"
            class="menu__item"
          >
            <span>{{ getRouteName(child) }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-if="item.leaf && item.children.length > 0"
          :key="item.children[0].path"
          :index="item.children[0].path"
        >
          <i :class="item.iconCls"></i>{{ item.children[0].name }}
        </el-menu-item>
      </template>
      <el-menu-item index="" @click="logout">
        <i class="fa fa-unlock"></i> <span>Wyloguj</span>
      </el-menu-item>
    </el-menu>
  </aside>
</template>
<script>
export default {
  methods: {
    getChildPath(parentPath, childPath) {
      if (parentPath === undefined) {
        return childPath
      }
      if (parentPath.endsWith('/')) {
        return `${parentPath}${childPath}`
      }
      return `${parentPath}/${childPath}`
    },
    logout: function() {
      this.$confirm(this.$i18n.t('areYouSure'), this.$i18n.t('logout'), {
        //type: 'warning'
      })
        .then(() => {
          window.localStorage.removeItem('token')
          this.$router.push('/login')
        })
        .catch(() => {})
    },
    showSubmenu(item) {
      return !item.leaf && this.visibleChildren(item).length
    },
    visibleChildren(item) {
      const removeHidden = child => !child.hidden
      return item.children.filter(removeHidden)
    }
  },
  computed: {
    availableRoutes() {
      return this.$router.options.routes.filter(route => !route.hidden)
    }
  }
}
</script>
<style scoped lang="scss">
.menu-wrapper {
  z-index: 2001;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  .el-menu {
    position: absolute;
    top: 0;
    border: 0;
    bottom: -15px;
    left: 0;
    right: -15px;
    overflow: auto;
    background: #2f4050;
  }
}

.menu__item {
  font-size: 13px;
  font-weight: 600;
}

.el-submenu {
  transition: all 0.3s;
}

.el-submenu.is-opened {
  background: #293846;
  border-left: 4px solid #66b1ff;
}

.el-menu-item,
.el-submenu .el-menu-item {
  background: #293846;
  color: #a7b1c2;
}

.el-menu-item.is-active {
  background: #66b1ff;
  color: #dceaff;
}

.white {
  color: #ffffff;
}

.sidebar {
  width: 100%;
}

.menu-hideable {
  width: 52px;
  transition: 0.3s all;

  .el-menu-item {
    padding-left: 200px;
  }

  span {
    transition: 0.3s all;
    opacity: 0;
  }

  ::v-deep .el-submenu__icon-arrow {
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }

    ::v-deep .el-submenu__icon-arrow {
      opacity: 1;
    }

    width: 230px;
  }
}

::v-deep .el-submenu {
  &__title {
    &:hover {
      background-color: #252f38 !important;
    }
  }
}

::v-deep .menu {
  &__item {
    color: #f3f3f3;
  }
}
</style>

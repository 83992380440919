<template>
  <div>
    <language-switcher />
    <el-form :model="formCopy" label-width="100px">
      <component :is="widgetComponent" :form.sync="formCopy" :errors="errors" />
      <div class="buttons">
        <el-button @click="$emit('cancel')">Anuluj</el-button>
        <el-button type="primary" @click="submitForm">Zatwierdź</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import { Widget } from '@/api'
import { cloneDeep, isEqual } from 'lodash'
import LanguageSwitcher from '@/components/LanguageSwitcher'

export default {
  data() {
    return {
      formCopy: {},
      errors: {},
      originalForm: {}
    }
  },
  props: {
    widget: { type: Object, required: true },
    errorsFromForm: { type: Object, default: () => ({}) }
  },
  mounted() {
    this.errors = cloneDeep(this.errorsFromForm)
    this.formCopy = cloneDeep(this.widget)
  },
  computed: {
    widgetComponent() {
      return this.getWidgetComponent(this.formCopy.resourcetype)
    }
  },
  methods: {
    submitForm() {
      Widget.validate(this.formCopy)
        .then(response => {
          this.$emit('submit', this.formCopy)
        })
        .catch(this.errorHandler)
    }
  },
  components: {
    LanguageSwitcher,
    BaseWidget
  }
}
</script>
<style lang="scss" scoped>
.link {
  font-size: 18px;
  color: #333333;
}

.buttons {
  margin-top: 25px;
  text-align: right;
}
</style>

<script>
import fields from '@/components/fields/'
import BaseWidgetForm from "./BaseWidgetForm";

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        ...this.getCommonFields(),
        {
          label: 'Ogólne',
          fields: [
            new fields.Link({
              labelPlaceholder: 'Tekst buttona',
              label: 'Button',
              required: true,
              modelPath: [this, 'form', 'button']
            }),
          ]
        }
      ]
    }
  }
}
</script>

import FieldBase from './FieldBase'
import moment from 'moment'

export default class extends FieldBase {
  constructor({
    type = 'date',
    size,
    format = 'yyyy-MM-dd HH:mm:ss',
    clearable = false,
    valueFormat,
    ...args
  }) {
    super(args)
    let modelType = 'date'
    if (type === 'time') {
      modelType = type
    }
    const child = this.getComponentWithVModel(`el-${modelType}-picker`)
    child.attrs = {
      ...child.attrs,
      type,
      size,
      format,
      clearable,
      valueFormat
    }
    this.children = [child]
  }
}

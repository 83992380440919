<script>
import { HelpPlace } from '@/api'

export default {
  data() {
    return {
      ages: [],
      formsOfHelp: [],
      placeTypes: [],
      filters: {
        available: [
          {
            name: 'Czynna całą dobę',
            query_param: 'round_the_clock',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          // {
          //   name: 'Typ placówki',
          //   query_param: 'place_type',
          //   options: this.setPlaceTypesOptions
          // },
          {
            name: 'Formy pomocy',
            query_param: 'forms_of_help',
            options: this.setFormsOfHelpOptions,
            multiple: true
          },
          {
            name: 'Wiek',
            query_param: 'ages',
            options: this.setAgeOptions,
            multiple: true
          }
        ],
        selected: {}
      }
    }
  },
  methods: {
    setAgeOptions(options) {
      this.service
        .availableAges()
        .then(response => {
          this.ages = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    setFormsOfHelpOptions(options) {
      this.service
        .availableFormsOfHelp()
        .then(response => {
          this.formsOfHelp = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    setPlaceTypesOptions(options) {
      this.service
        .availablePlaceTypes()
        .then(response => {
          this.placeTypes = response.data
          options.push(...response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

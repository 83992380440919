<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { SiteSettings } from '@/api'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      availableColourVersions: []
    }
  },
  created() {
    this.fetchAvailableColourVersions()
  },
  methods: {
    fetchAvailableColourVersions() {
      SiteSettings.fetchBannerWidgetAvailableColourVersions()
        .then(response => {
          this.availableColourVersions = response.data
        })
        .catch(error => {
          this.$message({
            message:
              'Nie udało się pobrać dostępnych wersji kolorystycznych widgetu "Baner".',
            type: 'error'
          })
        })
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              required: true,
              modelPath: [this, 'form', 'title']
            }),
            new fields.Input({
              label: 'Subtytuł',
              required: false,
              modelPath: [this, 'form', 'subtitle'],
              help: 'to pole może mieć maksymalnie około 200 znaków'
            }),
            new fields.Select({
              label: '(Legacy) Wersja kolorystyczna',
              modelPath: [this, 'form', 'colour_version'],
              required: true,
              options: this.availableColourVersions
            }),
            new fields.Header({
              text: 'Poniższe kolory nadpisują wersje kolorystyczną. Wersja kolorystyczna będzie usuwana w następnych wersjach.'
            }),
            new fields.Input({
              label: 'Kolor tekstu (opcjonalnie, domyślnie czarny)',
              required: false,
              modelPath: [this, 'form', 'text_color'],
              type: 'color'
            }),
            new fields.Input({
              label: 'Kolor tła (opcjonalnie)',
              required: false,
              modelPath: [this, 'form', 'background_color'],
              type: 'color'
            }),
            new fields.Link({
              labelPlaceholder: 'Tekst buttona',
              label: 'Button',
              required: true,
              modelPath: [this, 'form', 'button']
            }),
            new fields.Link({
              labelPlaceholder: 'Tekst buttona 2',
              label: 'Button2',
              modelPath: [this, 'form', 'button2']
            }),
            new fields.Image({
              label: 'Zdjęcie',
              required: true,
              modelPath: [this, 'form', 'image']
            }),
            new fields.Switch({
              label: 'Zamień miejscami',
              modelPath: [this, 'form', 'swap_places']
            })
          ]
        }
      ]
    }
  }
}
</script>

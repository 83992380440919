import Vue from 'vue'
import { get, isArray, isEmpty, isObject } from 'lodash'
import availableWidgetsForms from '@/components/widgets/forms/'
import moment from 'moment'

Vue.mixin({
  methods: {
    getRouteName(route) {
      if (route.meta && route.meta.label) {
        return route.meta.label
      }
      return route.name
    },
    changeOrder(arr, index, direction) {
      const placeAfterSwap = index + direction
      if (placeAfterSwap < 0 || placeAfterSwap >= arr.length) {
        return
      }
      const tmp = arr[index]
      this.$set(arr, index, arr[placeAfterSwap])
      this.$set(arr, placeAfterSwap, tmp)
    },
    checkForErrors(errorsList, nestedFieldsArray) {
      /**
       *  Check if in nested array of errors is error for proper specific field
       *  errorsList - array of formset errors, e.g. [{}. {name: 'Incorrect name'}]
       *  nestedFieldsArray - path to specific error object, e.g. [1]
       */
      const errors = get(errorsList, nestedFieldsArray)
      return !isEmpty(errors)
    },
    addLanguageSuffix(field) {
      const language = this.$store.state.selectedLanguage
      return `${field}_${language}`
    },
    getErrors(errors, fields) {
      const errorsField = get(errors, fields) || ''
      return isArray(errorsField) ? errorsField.join(', ') : errorsField
    },
    getWidgetComponent(type) {
      return availableWidgetsForms[type]
    },
    successHandler(response) {
      if (this.loading !== undefined) {
        this.loading = false
      }

      this.$set(this, 'errors', {})

      const message =
        response.status === 201
          ? 'Element został dodany'
          : 'Zmiany zostały zapisane'

      this.$message({
        message: message,
        type: 'success'
      })

      if (this.inModal) {
        this.$emit('submit', this.form)
      }
      if (this.redirectRoute && !this.inModal) {
        this.$router.push({ ...this.redirectRoute, query: this.$route.query })
      }
    },
    errorHandler(error) {
      if (this.loading !== undefined) {
        this.loading = false
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        this.showErrorsMessage(error.response)
        this.errors = this.parseErrorsDict(error.response.data)
        this.showErrorsOnTabsHeaders()
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        let message =
          'Wystąpił problem podczas przetwarzania formularza.<br> Sprawdź czy przesłane dane nie są zbyt duże.'

        console.log('Error', error.message)
        this.$message({
          message: message,
          dangerouslyUseHTMLString: true,
          type: 'error'
        })
      }
    },
    showErrorsMessage(errorsResponse) {
      let message = 'Wystąpił problem podczas przetwarzania formularza.'

      if (errorsResponse.data.non_field_errors !== undefined) {
        message += '<br>' + errorsResponse.data.non_field_errors.join(', ')
      }

      if (errorsResponse.status === 413) {
        message += '<br> Przesłane dane (np. pliki) są zbyt duże.'
      }

      this.$message({
        message: message,
        dangerouslyUseHTMLString: true,
        duration: 6000,
        type: 'error'
      })
    },
    parseErrorsDict(errorsObj) {
      // make sure errors are strings
      let languageErrors = {}
      Object.keys(errorsObj).forEach(key => {
        if (key.endsWith('_en') || key.endsWith('_pl')) {
          let parts = key.split('_')
          const lang = parts[parts.length - 1]
          languageErrors[lang] = true
        }
        let values = errorsObj[key]
        if (Array.isArray(values)) {
          if (typeof values[0] === 'string') {
            errorsObj[key] = values.join(', ')
          } else if (typeof values[0] === 'object') {
            for (let i = 0; i < values.length; i++) {
              errorsObj[key][i] = this.parseErrorsDict(values[i])
            }
          }
        }
      })
      return errorsObj
    },
    formatDate(field) {
      return value => {
        const fieldValue = get(value, field)
        if (fieldValue) {
          return moment(String(fieldValue)).format('MM/DD/YYYY HH:mm:ss')
        }
      }
    },
    removeItemFromFormset(field, item) {
      const index = field.indexOf(item)
      if (index > -1) {
        field.splice(index, 1)
      }
    },
    duplicateAnotherField(value, target) {
      if (!this.form[target]) {
        this.form[target] = value
      }
    },
    // used as helper in custom actions
    getButtonComponent({
      label,
      icon,
      onClick,
      type,
      title,
      disabled,
      condition,
      style = ''
    }) {
      return {
        component: 'el-button',
        condition: condition,
        attrs: {
          size: 'small',
          title,
          disabled,
          type: type || 'basic',
          style: style
        },
        listeners: onClick ? { click: onClick } : {},
        innerText: label,
        children: [
          {
            component: 'i',
            attrs: {
              class: icon,
              style: 'margin-right: 4px;'
            }
          }
        ]
      }
    },
    async showErrorsOnTabsHeaders() {
      await this.$nextTick()

      // const invalidTabs = document.querySelectorAll('.el-tabs__item')
      // Array.from(invalidTabs).forEach(field => field.classList.remove('error'))

      const invalidFields = document.querySelectorAll('.el-form-item.is-error')

      Array.from(invalidFields).forEach(field => {
        const pane = field.closest('.el-tab-pane')
        const tabId = pane && pane.getAttribute('aria-labelledby')
        const tab = document.getElementById(tabId)
        tab && tab.classList.add('error')
      })
    }
  }
})

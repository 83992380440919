<template>
  <div>
    <p class="list-filter-title">{{ param.name }}</p>
    <el-date-picker
      v-model="selectedDate"
      clearable
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="Wybierz datę"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  props: ['param', 'value'],
  computed: {
    selectedDate: {
      set(newValue) {
        this.$emit('input', newValue)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>

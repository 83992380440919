<script>
import { SimpleCastAudio } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import SimpleCastFeedForm from '@/views/SimpleCastAudio/SimpleCastFeedForm'

export default {
  extends: FormMixin,
  data() {
    return {
      form: {},
      redirectRoute: { name: 'SimpleCastAudio List' },
      submitHidden: true
    }
  },
  computed: {
    service() {
      return SimpleCastAudio
    }
  },
  methods: {
    getFields() {
      return [
        new fields.ReadOnly({
          label: 'Zewnętrzne ID',
          modelPath: [this, 'form', 'guid']
        }),
        new fields.AudioReadOnly({
          label: '',
          modelPath: [this, 'form', 'enclosure_url'],
          type: 'audio/mpeg'
        }),
        new fields.ReadOnly({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title']
        }),
        new fields.ReadOnly({
          label: 'Opis',
          modelPath: [this, 'form', 'description']
        }),
        new fields.ReadOnly({
          label: 'Autor',
          modelPath: [this, 'form', 'author']
        }),
        new fields.ReadOnly({
          label: 'Data publikacji',
          modelPath: [this, 'form', 'pub_date']
        }),
        new fields.ReadOnly({
          label: 'Czas audio',
          modelPath: [this, 'form', 'duration']
        }),
        new fields.ReadOnly({
          label: 'Link',
          modelPath: [this, 'form', 'link']
        }),
        new fields.ReadOnly({
          label: 'Adres URL do pliku MP3',
          modelPath: [this, 'form', 'enclosure_url']
        }),

        new fields.ReadOnly({
          label: 'Rozmiar pliku w bajtach',
          modelPath: [this, 'form', 'enclosure_length']
        }),
        new fields.ReadOnly({
          label: 'Mime pliku',
          modelPath: [this, 'form', 'enclosure_type']
        }),
        new fields.ReadOnly({
          label: 'Źródło RSS',
          modelPath: [this, 'form', 'feed_url']
        }),
        {
          component: SimpleCastFeedForm,
          attrs: {
            value: (this.form && this.form.feed) || {},
            errors: []
          }
        }
      ]
    }
  }
}
</script>

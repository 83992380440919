<template>
  <div class="wrapper">
    <el-button
      v-if="!value"
      @click="initForm"
      class="fluid fill-link-button"
      type="primary"
    >
      Uzupełnij
    </el-button>
    <div v-else>
      <div class="inline-form-group">
        <el-form-item
          v-show="!hideLinkLabel"
          :label="labelPlaceholder"
          :error="getErrors(errors, ['label'])"
        >
          <el-input v-model="value.label" />
        </el-form-item>
        <el-button
          @click="removeLink"
          v-if="nullable"
          type="warning"
          class="remove-button"
          size="mini"
        >
          Usuń
        </el-button>
      </div>
      <div class="inline-form-group">
        <el-form-item
          label="Typ video"
          required
          :error="anchorErrors"
          :style="this.anchorOptions.length > 1 ? '' : 'display:none'"
        >
          <el-select v-model="anchorType" placeholder="Wybierz typ video">
            <el-option
              v-for="option in anchorOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="Otwieraj w nowym oknie" label-width="200px">-->
        <!--          <el-switch v-model="value.target_blank"></el-switch>-->
        <!--        </el-form-item>-->
      </div>
      <el-form-item
        style="display: none"
        label="URL"
        required
        v-if="!value.content_type"
        :error="getErrors(errors, ['url'])"
      >
        <el-input v-model="value.url" />
      </el-form-item>
      <div class="inline-form-group" v-if="contentTypeOptions">
        <el-form-item
          v-if="contentTypeOptions.objects"
          :label="contentTypeOptions.model_name"
          required
          :error="getErrors(errors, ['object_id'])"
        >
          <el-select
            filterable
            v-model="value.object_id"
            placeholder="Wybierz z listy"
          >
            <el-option
              v-for="option in contentTypeOptions.objects"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="el-form-item__error" v-if="typeof errors === 'string'">
      {{ errors }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentTypes: []
    }
  },
  props: {
    value: { type: Object, required: false },
    errors: {
      type: [Object, String, Function],
      default: () => {}
    },
    labelPlaceholder: {
      type: String,
      default: 'Label video'
    },
    nullable: {
      type: Boolean,
      default: true
    },
    hideLinkLabel: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    initForm() {
      this.$emit('input', {})
    },
    removeLink() {
      this.$emit('input', null)
    },
    fetchData() {
      this.$store.dispatch('fetchAvailableVideoLinkOptions')
    }
  },
  computed: {
    anchorErrors() {
      return this.getErrors(this.errors, ['anchor_type'])
    },
    availableVideoLinkOptions() {
      return this.$store.state.availableVideoLinkOptions
    },
    contentTypeOptions() {
      if (!this.value) return
      const selectedContentType = this.value.content_type
      const matchingContentType = option =>
        option.content_type_id === selectedContentType
      return this.availableVideoLinkOptions.find(matchingContentType)
    },
    anchorOptions() {
      const availableContentTypes = this.availableVideoLinkOptions.map(
        option => ({
          value: option.content_type_id,
          label: option.model_name
        })
      )
      return [
        ...availableContentTypes
        // {
        //   label: 'URL',
        //   value: 'url'
        // }
      ]
    },
    anchorType: {
      set(value) {
        if (value === 'url') {
          this.$set(this.value, 'object_id', null)
          this.$set(this.value, 'content_type', null)
        } else {
          this.$set(this.value, 'url', '')
          this.$set(this.value, 'content_type', value)
          this.$set(this.value, 'object_id', null)
        }
      },
      get() {
        return (
          this.value.content_type ||
          (this.anchorOptions &&
            this.anchorOptions.length &&
            this.$set(this, 'anchorType', this.anchorOptions[0].value))
        )
      }
    },
    isAttachmentType() {
      const contentType = this.contentTypeOptions
      return contentType && contentType.model === 'attachment'
    },
    isFlatPage() {
      const contentType = this.contentTypeOptions
      return contentType && contentType.model === 'flatpage'
    }
  },
  watch: {
    contentTypeOptions: {
      handler: function (value) {
        if (value && value.objects && value.objects.length === 1) {
          this.$nextTick(() => {
            this.value.object_id = value.objects[0].value
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.widget-info {
  padding: 9px;
}

.remove-button {
  display: inline-block;
  margin: 0 0 22px 11px;
}

.fluid {
  width: 100%;
}

.wrapper {
  background: #f7f7f7;
  padding: 22px;
  border: 1px solid #e2e2e2;
  position: relative;
  margin-bottom: 22px;
}

.fill-link-button {
  margin-bottom: 22px;
}
</style>

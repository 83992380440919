<script>
import { Page } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import FiltersMixin from '@/views/Pages/FiltersMixin'

export default {
  mixins: [ListMixin, FiltersMixin],
  data() {
    return {
      editRouteName: 'Edit Page',
      addRoute: { name: 'Add Page' },
      verboseName: 'Podstrona',
      verboseNamePlural: 'Podstrony',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'title', label: 'tytuł' },
        { prop: 'slug', label: 'slug' },
        { prop: 'is_hidden', label: 'ukryty', type: 'boolean' },
        {
          prop: 'id',
          label: 'kolejność',
          type: 'order',
          service: Page,
          fetchDataCallback: this.fetchData,
          isListLoading: this.isListLoading,
          setListAsLoading: this.setListAsLoading
        }
      ]
    }
  },
  computed: {
    service() {
      return Page
    }
  }
}
</script>
